import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import Rating from '../Rating'
import { htmlHandleChars, isToday, isYesterday, truncate } from '../helper/utils'
import { addLike } from '../../actions/postActions'
import axios from 'axios'



// props from PostDetailsPage
const PostItem = ({ post, showAction, index }) => {
  const [commentLength, setCommentLength] = useState('');

  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building } = buildingDetails;



  // USER CLICK LIKE BUTTON
  const userLikePost = () => {
    const button = document.querySelector(`.btnLike-${index}`);
    if (button.classList.contains('text-muted')) {
      button.classList.remove('text-muted');
      button.classList.add('text-primary');
    } else if (button.classList.contains('text-primary')) {
      button.classList.add('text-muted');
      button.classList.remove('text-primary');
    }
    dispatch(addLike(post._id));
  }



  useEffect(() => {
    // FIND NEW RECENT COMMENT
    const findNewComments = async () => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
          }
        }

        const { data } = await axios.get(`/api/posts/${post._id}/comments`, config);
        setCommentLength(data.comments.length);


      } catch (error) {
        console.log(error)
      }
    }

    // MARK POST AS LIKED
    const markAsLiked = (post) => {
      const button = document.querySelector(`.btnLike-${index}`);
      post && post.likes.map(item => {
        // button value is id of current user
        if (item.user === button.value) {
          button.classList.remove('text-muted');
          button.classList.add('text-primary');
        }
      })

    }


    findNewComments();
    markAsLiked(post);
  }, [userInfo, post, index])



  return (
    <>
      {post && (
        <div className="py-3">
          <div className="mb-4">
            <div style={{ float: 'left' }} className="mb-3">
              <h5>
                {post.title} <span className="ml-3">{post.isComplain && <i className="far fa-angry text-danger fa-lg"></i>}</span>
              </h5>
            </div>

            {/* show last published */}
            {/* <div className=" float-right">
              {showAction && (
                dispatch(lastPublished(post.createdAt)) === 'Today' && (
                  <small className="text-success font-weight-bold">{dispatch(lastPublished(post.createdAt))}</small>
                )
              )}
            </div> */}

            {/* EDIT & DELETE */}
            <div className="clearfix">
              {userInfo && showAction && !post.isCancelVote && ( // cancel post can't be edited
                userInfo._id === post.user._id && (
                  <button className="btn btn-light float-right">
                    <Link to={`/posts/${post._id}/edit`}>
                      <i className="fas fa-edit"></i>
                    </Link>
                  </button>
                )
              )}

            </div>
            <div dangerouslySetInnerHTML={{
              __html:
                showAction
                  ? htmlHandleChars(truncate(post.description, 8000))
                  : htmlHandleChars(truncate(post.description, 400))
            }}>
            </div>
          </div>
          {/* <div>
            {post.description.length > 400 && (
              <a href="#!" type="button" className="card-link float-right text-primary" data-toggle="modal"
                data-target="#showInfoModal">More</a>
            )}
          </div> */}

          <div className="clearfix">
            <>
              {/* ADD LIKE*/}
              {!showAction && (
                <Link to={`/posts/${post._id}/comments`} className="btn-sm btn btn-primary float-right ml-3">More</Link>
              )}

              {/* LIKE BUTTON */}
              <button
                value={userInfo && userInfo._id}
                onClick={userLikePost}
                className={`btn float-right mx-1 text-muted btnLike-${index}`}
              >
                <i className={`fas fa-thumbs-up ${!post.likes.length && 'mr-2'}`} />{' '}
                <span className="comment-count">{post.likes.length > 0 && post.likes.length}</span>
              </button>

              {/* NUM REVIEWS */}
              <div className="float-right mt-2">
                {!showAction && (
                  // length of new comments
                  <span className='text-muted'>
                    {commentLength} {' '}
                    <i className="fas fa-comment-dots"></i>
                  </span>
                )}
              </div>

              <div className="float-right mt-2 mr-2">
                {isToday(post.createdAt) && <img src="https://res.cloudinary.com/imgtocloud/image/upload/v1650555075/Baanbalina3%27s%20Village/new_a7fotf.png" alt="new" style={{ width: '30px' }} />}
                {isYesterday(post.createdAt) && <img src="https://res.cloudinary.com/imgtocloud/image/upload/v1650777396/Baanbalina3%27s%20Village/yesterday_cswtlg.png" alt="new" style={{ width: '25px' }} />}
              </div>

              {/* UNLIKE BUTTON */}
              {/* <button className="btn btn-light btn-sm float-right mx-1" onClick={() => dispatch(removeLike(post._id))}>
                <i className='fas fa-thumbs-down text-primary' />
              </button> */}

              {/* {post.likes.length > 0 ? post.likes.map((like, index) => (
                <Fragment key={index}>
                  <button className="btn btn-light float-right mx-2" onClick={() => dispatch(removeLike(post._id))}>
                    <i className='fas fa-thumbs-up text-primary' />
                    <span className="comment-count">{post.likes.length > 0 && post.likes.length}</span>
                  </button>
                </Fragment>
              )) : (
                <button className="btn btn-light btn float-right mx-2" onClick={() => dispatch(addLike(post._id))}>
                  <i className='far fa-thumbs-up text-primary' />
                  <span className="comment-count">{post.likes.length > 0 && post.likes.length}</span>
                </button>
              )} */}
            </>
            <div>
              {/* The stars */}
              <Rating value={post.ratingsAverage} />
            </div>

            {/* Author */}
            <small>
              by <a href={`/building/${building._id}/committee`}>{post.user && post.user.lastName}</a> - <Moment format='DD/MM/YYYY'>{post.createdAt}</Moment> {' '}
            </small>
          </div>
          {!showAction && <hr />}

        </div >
      )}
    </>
  )
}

export default PostItem
