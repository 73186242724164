import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listVideos } from '../actions/videoActions';
import VideoPageItem from '../components/videos/VideoPageItem'
import VideoPageDetails from '../components/videos/VideoPageDetails';

// updated

const GuidePage = ({ history }) => {
  const dispatch = useDispatch();

  const videoList = useSelector(state => state.videoList)
  const { videos, loading, error } = videoList;

  const videoDetails = useSelector(state => state.videoDetails)
  const { video } = videoDetails


  useEffect(() => {
    dispatch(listVideos())
  }, [dispatch, history, error])


  return (
    <div className='content'>
      <div className="ui grid">
        <div className="ui row">
          {/* column1 */}
          {!loading && videos && (
            <VideoPageDetails videos={videos} video={video} />
          )}
          {/* column 2 */}
          <div className="five wide column">
            <div className="ui relaxed divided list">
              {videos && videos.map(video => (
                <VideoPageItem key={video._id} video={video} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GuidePage
