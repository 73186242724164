import React from 'react'
import { useDispatch } from 'react-redux'
import { hideExpensePage } from '../../actions/buildingActions';


const SettingPage = ({ building }) => {
  const dispatch = useDispatch();

  return (
    <div className='row'>
      <div className="col-md-6">
        <ul className="list-group">
          <li className="list-group-item">
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" id="expensePage" checked={building && building.expensePage} onClick={() => dispatch(hideExpensePage(building._id))} />
              <label className="custom-control-label" for="expensePage">Expenses Page</label>
            </div>
          </li>
          <li className="list-group-item">
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" id="userList" />
              <label className="custom-control-label" for="userList">User List Menu</label>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SettingPage