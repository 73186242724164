import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminlistEmails } from '../../actions/adminAction'
import EmailItem from '../../components/admin/EmailItem'
import EmailForm from '../../components/admin/EmailForm'

const AdminEmailList = ({ userInfo, email, isAdminPage, pageNumber }) => {
  const dispatch = useDispatch();

  const adminEmailList = useSelector(state => state.adminEmailList);
  const { emails } = adminEmailList;

  useEffect(() => {
    // admin route to list all emails
    dispatch(adminlistEmails());
  }, [dispatch])
  return (
    <div className="row" style={{ padding: '0 20px' }}>
      <div className="col-md-8">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th></th>
                <th>Date</th>
                <th>Description</th>
                <th>Ticket</th>
                <th>Community</th>
                <th>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {emails && emails.map((email, index) => (
                <EmailItem
                  key={email._id}
                  email={email}
                  index={index}
                  userInfo={userInfo}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-md-4">
        <EmailForm email={email} />
      </div>

      {/* Pagination */}
      {/* <Paginate page={page} pages={pages} isDahsPostList={true} /> */}
    </div>

  )
}

export default AdminEmailList
