import {
  ADMIN_AUTH_REQUEST,
  ADMIN_AUTH_SUCCESS,
  ADMIN_AUTH_FAIL,
  ADMIN_AUTH_RESET,
  ADMIN_FULL_GRANTED_REQUEST,
  ADMIN_FULL_GRANTED_SUCCESS,
  ADMIN_FULL_GRANTED_FAIL,
  ADMIN_FULL_GRANTED_RESET,
  ADMIN_EMAIL_LIST_REQUEST,
  ADMIN_EMAIL_LIST_SUCCESS,
  ADMIN_EMAIL_LIST_FAIL,
  ADMIN_EMAIL_LIST_RESET,
  ADMIN_EMAIL_DETAILS_REQUEST,
  ADMIN_EMAIL_DETAILS_SUCCESS,
  ADMIN_EMAIL_DETAILS_FAIL,
  ADMIN_EMAIL_DETAILS_RESET,
  ADMIN_EMAIL_RESPONSE_REQUEST,
  ADMIN_EMAIL_RESPONSE_SUCCESS,
  ADMIN_EMAIL_RESPONSE_FAIL,
  ADMIN_EMAIL_RESPONSE_RESET,
  ADMIN_EMAIL_READ_SUCCESS,
  ADMIN_EMAIL_READ_FAIL,
  ADMIN_EMAIL_READ_RESET,

  ADMIN_EMAIL_TICKET_LIST_REQUEST,
  ADMIN_EMAIL_TICKET_LIST_SUCCESS,
  ADMIN_EMAIL_TICKET_LIST_FAIL,
  ADMIN_EMAIL_TICKET_LIST_RESET,
} from '../constants/adminConstants'


export const adminAuthenticateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_AUTH_REQUEST:
      return {
        loading: true
      }
    case ADMIN_AUTH_SUCCESS:
      return {
        loading: false,
        token: action.payload,
      }
    case ADMIN_AUTH_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case ADMIN_AUTH_RESET:
      return {}
    default:
      return state;
  }
}

export const adminFullGrantedReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_FULL_GRANTED_REQUEST:
      return {
        loading: true
      }
    case ADMIN_FULL_GRANTED_SUCCESS:
      return {
        loading: false,
        token: action.payload,
      }
    case ADMIN_FULL_GRANTED_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case ADMIN_FULL_GRANTED_RESET:
      return {}
    default:
      return state;
  }
}

export const adminEmailListReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_EMAIL_LIST_REQUEST:
      return {
        loading: true
      }
    case ADMIN_EMAIL_LIST_SUCCESS:
      return {
        loading: false,
        emails: action.payload
      }
    case ADMIN_EMAIL_LIST_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case ADMIN_EMAIL_LIST_RESET:
      return {}
    default:
      return state;
  }
}

export const adminEmailDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_EMAIL_DETAILS_REQUEST:
      return {
        loading: true
      }
    case ADMIN_EMAIL_DETAILS_SUCCESS:
      return {
        loading: false,
        email: action.payload
      }
    case ADMIN_EMAIL_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case ADMIN_EMAIL_DETAILS_RESET:
      return {}
    default:
      return state;
  }
}

export const adminEmailResponseReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_EMAIL_RESPONSE_REQUEST:
      return {
        loading: true
      }
    case ADMIN_EMAIL_RESPONSE_SUCCESS:
      return {
        loading: false,
        success: true
      }
    case ADMIN_EMAIL_RESPONSE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case ADMIN_EMAIL_RESPONSE_RESET:
      return {}
    default:
      return state;
  }
}

export const adminEmailReadReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_EMAIL_READ_SUCCESS:
      return {
        loading: false,
        success: true
      }
    case ADMIN_EMAIL_READ_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case ADMIN_EMAIL_READ_RESET:
      return {}
    default:
      return state;
  }
}

export const adminEmailTicketListReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_EMAIL_TICKET_LIST_REQUEST:
      return {
        loading: true
      }
    case ADMIN_EMAIL_TICKET_LIST_SUCCESS:
      return {
        loading: false,
        emails: action.payload
      }
    case ADMIN_EMAIL_TICKET_LIST_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case ADMIN_EMAIL_TICKET_LIST_RESET:
      return {}
    default:
      return state;
  }
}

