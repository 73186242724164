import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../components/Spinner'
import verifyBox from '../components/helper/verifyBox'
import Alert from '../components/Alert'
import { verifyNumber, getBuildingDetails, deniedBannedUser } from '../actions/buildingActions'
import { BUILDING_VERIFY_RESET } from '../constants/buildingConstants'
import { setAlert } from '../actions/alertActions'



const NewGroupPage = ({ history }) => {
  const dispatch = useDispatch();

  // const [groupNumber, setGroupNumber] = useState('');
  const [num1, setNum1] = useState('');
  const [num2, setNum2] = useState('');
  const [num3, setNum3] = useState('');
  const [num4, setNum4] = useState('');


  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building } = buildingDetails;

  const buildingVerify = useSelector(state => state.buildingVerify);
  const { success: successVerify, error, loading } = buildingVerify;

  const alertAction = useSelector(state => state.alertAction);
  const { alert } = alertAction;

  useEffect(() => {
    dispatch(deniedBannedUser(building, userInfo, history))

    if (successVerify) {
      history.push('/posts');
      dispatch({ type: BUILDING_VERIFY_RESET })
    }

    // if (userInfo.community !== 'no-group') {
    //   return history.push('/posts')
    // }

    // javascript
    verifyBox();
  }, [dispatch, history, building, userInfo, successVerify])



  const verifyNumberHandler = () => {
    let groupNumber = num1 + '' + num2 + '' + num3 + '' + num4;
    // setGroupNumber(groupNumber);
    if (!num1 || !num2 || !num3 || !num4) {
      dispatch(setAlert('Please enter your code !', 'danger'))
    } else {
      // verify code to join another channel
      dispatch(verifyNumber(groupNumber));
      // building details information must change after verify 
      dispatch(getBuildingDetails());

    }
  }

  return (
    loading ? < Spinner /> :
      <>
        <div className="container-fluid h-100">
          <div className="row justify-content-center align-items-center h-100" style={{ minHeight: '80vh' }}>
            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-6" >
              <div className="verify-box">
                <h2>Verify your account</h2>
                <p>Please input your code number you get from your management office.</p>


                {error && <h5 className="text-danger lead">{error}</h5>}
                {alert && <Alert msg={alert.msg} type={alert.type} />}

                <div className="code-container">
                  <input type="number" min="1" max="9" name={num1} placeholder="0" className="code" onChange={e => setNum1(e.target.value)} />
                  <input type="number" min="1" max="9" name={num2} placeholder="0" className="code" onChange={e => setNum2(e.target.value)} />
                  <input type="number" min="1" max="9" name={num3} placeholder="0" className="code" onChange={e => setNum3(e.target.value)} />
                  <input type="number" min="1" max="9" name={num4} placeholder="0" className="code" onChange={e => setNum4(e.target.value)} />
                </div>

                <div>
                  <button className="btn btn-primary btn-block btn-lg" onClick={verifyNumberHandler}>Verify</button>
                </div>
              </div>
              <p className="lead mt-4">&nbsp;Previous page
                <Link to="/new-community" className="btn btn-sm btn-info float-left">{`<< Back`}</Link>
              </p>
            </div>
          </div>
        </div>
      </>
  )
}


export default NewGroupPage
