import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  adminAuthenticateReducer,
  adminFullGrantedReducer,
  adminEmailListReducer,
  adminEmailDetailsReducer,
  adminEmailReadReducer,
  adminEmailResponseReducer,
  adminEmailTicketListReducer
} from './reducers/adminReducer'
import {
  userLoginReducer,
  userUpdateMeReducer,
  userUpdateReducer,
  userImageUploadReducer,
  userPasswordUpdateReducer,
  userRegisterReducer,
  userListReducer,
  userContactSupportReducer,
  userEmailListReducer,
  userEmailDetailsReducer,
  userDetailsReducer,
  userNewAuthorReducer,
  userActivateBuildingReducer,
  userSecurityCreateReducer,
  userForgotPasswordReducer,
  userSecurityCheckReducer,
  userPasswordResetReducer,
  userNewBuildingListReducer,
  userSwitchBuildingReducer,
  userBanReducer,
} from './reducers/userReducer'
import {
  postListReducer,
  postDetailsReducer,
  postCreateReducer,
  postUpdateReducer,
  postRemoveReducer,
  postDeleteAdminReducer,
  postVoteListReducer,
  postVoteCreateReducer,
  postCountdownUpdateReducer,
  postImageUploadReducer,
  postsListFilterReducer,
  postListReportReducer
} from './reducers/postReducers'
import {
  commentListReducer,
  commentCreateReducer,
  commentDetailsReducer,
  commentUpdateReducer,
  commentDeleteReducer,
  commentRecentListReducer,
  commentRecentReadReducer
} from './reducers/commentReducers'
import {
  expenseListReducer,
  expenseDetailsReducer,
  expenseUpdateReducer,
  expenseCreateReducer,
  expenseDeleteReducer,
  expenseMarkReducer
} from './reducers/expenseReducers'
import { alertReducer } from './reducers/alertReducers'
import {
  documentUploadReducer,
  documentListReducer,
  documentDetailsReducer,
  documentDownloadReducer
} from './reducers/documentReducers'
import {
  buildingCreateReducer,
  buildingListReducer,
  buildingUpdateReducer,
  buildingDetailsReducer,
  buildingVerifyReducer,
  buildingCheckPendingReducer,
  buildingUsersLimitReducer,
  buildingRuleUpdateReducer,
  buildingLocationReducer,
  buildingLocationServiceReducer
} from './reducers/buildingReducers'

import {
  videoCreateReducer,
  videoListReducer,
  videoDetailsReducer,
  videoUpdateReducer
} from './reducers/videoReducers'

const reducer = combineReducers({
  adminAuth: adminAuthenticateReducer,
  adminFullGranted: adminFullGrantedReducer,
  adminEmailList: adminEmailListReducer,
  adminEmailDetails: adminEmailDetailsReducer,
  adminEmailRead: adminEmailReadReducer,
  adminEmailResponse: adminEmailResponseReducer,
  adminEmailTicketList: adminEmailTicketListReducer,
  alertAction: alertReducer,
  documentUpload: documentUploadReducer,
  documentDownload: documentDownloadReducer,
  documentList: documentListReducer,
  documentDetails: documentDetailsReducer,

  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userUpdateMe: userUpdateMeReducer,
  userUpdate: userUpdateReducer,
  userImageUpload: userImageUploadReducer,
  userPasswordUpdate: userPasswordUpdateReducer,
  userList: userListReducer,
  userDetails: userDetailsReducer,
  userNewAuthor: userNewAuthorReducer,
  userActivateBuilding: userActivateBuildingReducer,
  userNewBuildingList: userNewBuildingListReducer,
  userSwitchBuilding: userSwitchBuildingReducer,
  userBan: userBanReducer,
  userContactSupport: userContactSupportReducer,
  userEmailList: userEmailListReducer,
  userEmailDetails: userEmailDetailsReducer,
  userSecurityCreate: userSecurityCreateReducer,
  userForgotPassword: userForgotPasswordReducer,
  userSecurityCheck: userSecurityCheckReducer,
  userPasswordReset: userPasswordResetReducer,

  postList: postListReducer,
  postDetails: postDetailsReducer,
  postListFilter: postsListFilterReducer,
  postListReport: postListReportReducer,
  postCreate: postCreateReducer,
  postRemove: postRemoveReducer,
  postDelete: postDeleteAdminReducer,
  postImageUpload: postImageUploadReducer,
  postUpdate: postUpdateReducer,
  postVoteList: postVoteListReducer,
  postVoteCreate: postVoteCreateReducer,
  postCountdownUpdate: postCountdownUpdateReducer,

  commentCreate: commentCreateReducer,
  commentDetails: commentDetailsReducer,
  commentUpdate: commentUpdateReducer,
  commentDelete: commentDeleteReducer,
  commentList: commentListReducer,
  commentRecentList: commentRecentListReducer,
  commentRecentRead: commentRecentReadReducer,

  expenseList: expenseListReducer,
  expenseDetails: expenseDetailsReducer,
  expenseCreate: expenseCreateReducer,
  expenseUpdate: expenseUpdateReducer,
  expenseDelete: expenseDeleteReducer,
  expenseMarked: expenseMarkReducer,

  buildingCreate: buildingCreateReducer,
  buildingUpdate: buildingUpdateReducer,
  buildingRuleUpdate: buildingRuleUpdateReducer,
  buildingDetails: buildingDetailsReducer,
  buildingList: buildingListReducer,
  buildingVerify: buildingVerifyReducer,
  buildingCheckPending: buildingCheckPendingReducer,
  buildingUsersLimit: buildingUsersLimitReducer,
  buildingLocation: buildingLocationReducer,
  buildingServiceDetails: buildingLocationServiceReducer,

  videoCreate: videoCreateReducer,
  videoList: videoListReducer,
  videoDetails: videoDetailsReducer,
  videoUpdate: videoUpdateReducer
});



// makes userInfo always ready to get
const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

// make buildingDetails always ready to get
const buildingDetailsFromStorage = localStorage.getItem('buildingDetails') ? JSON.parse(localStorage.getItem('buildingDetails')) : null

const buildingPendingFromStorage = localStorage.getItem('buildingPending') ? JSON.parse(localStorage.getItem('buildingPending')) : null;

// make buildingList always ready to get
const buildingListFromStorage = localStorage.getItem('buildings') ? JSON.parse(localStorage.getItem('buildings')) : null

// make userTokenRequest always ready to get (helps that the question won't dissapear after reloading page)
const userForgotPasswordFromStorage = localStorage.getItem('userForgotPassword') ? JSON.parse(localStorage.getItem('userForgotPassword')) : {}

// helps that we are not allow to go back to security check after submit
const userSecurityCheckFromStorage = localStorage.getItem('userSecurityCheck') ? JSON.parse(localStorage.getItem('userSecurityCheck')) : {}

// Full  granted token
const adminFullGrantedTokenFromStorage = localStorage.getItem('grantedToken') ? JSON.parse(localStorage.getItem('grantedToken')) : null;


const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
  buildingDetails: { building: buildingDetailsFromStorage },
  userForgotPassword: userForgotPasswordFromStorage,
  userSecurityCheck: userSecurityCheckFromStorage,
  buildingCheckPending: { building: buildingPendingFromStorage },
  buildingList: { buildings: buildingListFromStorage },
  adminFullGranted: { token: adminFullGrantedTokenFromStorage }
}

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)


export default store;


