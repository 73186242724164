import {
  UPDATE_COUNTDOWN
} from '../constants/postConstants'

const calculateCountdown = (finalVote) => {
  const endVote = new Date(finalVote);
  const currentTime = new Date();

  // different time h
  const diff = endVote - currentTime;
  const d = Math.floor(diff / 1000 / 60 / 60 / 24);
  const h = Math.floor(diff / 1000 / 60 / 60) % 24;
  const m = Math.floor(diff / 1000 / 60) % 60;
  const s = Math.floor(diff / 1000) % 60;

  let days;
  let hours;
  let minutes;
  let seconds;

  //add value to DOM
  if (endVote > new Date()) {
    days = d + ` day${d > 1 ? 's, ' : ' '}`;
    hours = h < 10 ? '0' + h + ' :' : h + ' :';
    minutes = m < 10 ? '0' + m + ' :' : m + ' :';
    seconds = s < 10 ? '0' + s : s;
  }

  setInterval(diff, 1000);
  const timeLeft = { days, hours, minutes, seconds }
  return timeLeft;
}

export const updateCountdown = (finalVote) => async dispatch => {
  const timeLeft = calculateCountdown(finalVote);

  dispatch({
    type: UPDATE_COUNTDOWN,
    payload: timeLeft
  })
}
