// export const COMMENT_CREATE_REQUEST = 'COMMENT_CREATE_REQUEST';
export const COMMENT_CREATE_SUCCESS = 'COMMENT_CREATE_SUCCESS';
export const COMMENT_CREATE_FAIL = 'COMMENT_CREATE_FAIL';
export const COMMENT_CREATE_RESET = 'COMMENT_CREATE_RESET';

export const COMMENT_UPDATE_REQUEST = 'COMMENT_UPDATE_REQUEST';
export const COMMENT_UPDATE_SUCCESS = 'COMMENT_UPDATE_SUCCESS';
export const COMMENT_UPDATE_FAIL = 'COMMENT_UPDATE_FAIL';

export const COMMENT_DELETE_REQUEST = 'COMMENT_DELETE_REQUEST';
export const COMMENT_DELETE_SUCCESS = 'COMMENT_DELETE_SUCCESS';
export const COMMENT_DELETE_FAIL = 'COMMENT_DELETE_FAIL';

export const COMMENT_LIST_REQUEST = 'COMMENT_LIST_REQUEST';
export const COMMENT_LIST_SUCCESS = 'COMMENT_LIST_SUCCESS';
export const COMMENT_LIST_FAIL = 'COMMENT_LIST_FAIL';
export const COMMENT_LIST_RESET = 'COMMENT_LIST_RESET';

export const COMMENT_RECENT_LIST_REQUEST = 'COMMENT_RECENT_LIST_REQUEST';
export const COMMENT_RECENT_LIST_SUCCESS = 'COMMENT_RECENT_LIST_SUCCESS';
export const COMMENT_RECENT_LIST_FAIL = 'COMMENT_RECENT_LIST_FAIL';
export const COMMENT_RECENT_LIST_RESET = 'COMMENT_RECENT_LIST_RESET';

export const COMMENT_DETAILS_REQUEST = 'COMMENT_DETAILS_REQUEST';
export const COMMENT_DETAILS_SUCCESS = 'COMMENT_DETAILS_SUCCESS';
export const COMMENT_DETAILS_FAIL = 'COMMENT_DETAILS_FAIL';
export const COMMENT_DETAILS_RESET = 'COMMENT_DETAILS_RESET';

export const COMMENT_MARK_READ_SUCCESS = 'COMMENT_MARK_READ_SUCCESS';
export const COMMENT_MARK_READ_FAIL = 'COMMENT_MARK_READ_FAIL';
export const COMMENT_MARK_READ_RESET = 'COMMENT_MARK_READ_RESET';
