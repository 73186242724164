import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import Spinner from '../../components/Spinner'
import Alert from '../../components/Alert'
import { logout, processSecurityCheck } from '../../actions/userActions'


const SecurityCreatePage = ({ history, location }) => {
  const [recaptcha, setRecaptcha] = useState(false)
  const [formData, setFormData] = useState({
    answer: '',
    secretResetNumber: ''
  });

  const { answer, secretResetNumber } = formData


  const userForgotPassword = useSelector(state => state.userForgotPassword);
  const { resetQuestion, token: tokenForgotPassword, _id: userId, error } = userForgotPassword;

  const userSecurityCheck = useSelector(state => state.userSecurityCheck);
  const { token: tokenSecurityCheck, loading, error: errorSecurityCheck } = userSecurityCheck;

  const dispatch = useDispatch();

  const recaptchaHandler = () => {
    setRecaptcha(true)
  }


  useEffect(() => {
    if (errorSecurityCheck === 'Authorization token is invalid !') {
      setTimeout(() => {
        history.push('/login');
        dispatch(logout());
      }, 3000)
    }

    // if state forgotPassword no token, than no entry to security check. Even with copy paste url
    // state is filled with woken if user's email found
    if (!tokenForgotPassword) {
      history.push('/login');
    }

    // answers correct, user willbe push to reset password page
    if (tokenSecurityCheck) {
      history.push(`/reset-password/${tokenSecurityCheck}/NGQ4YTNkYWYwIiwiaWF0Ij`)
    }


  }, [dispatch, history, errorSecurityCheck, tokenForgotPassword, tokenSecurityCheck])

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const submitHandler = e => {
    e.preventDefault();
    dispatch(processSecurityCheck(userId, tokenForgotPassword, formData));
  }

  return (
    loading || !tokenForgotPassword ? <Spinner /> :
      <div className="container">
        <div className="row justify-content-center align-items-center h-100" style={{ minHeight: '80vh' }}>
          <div className="col-md-6 col-sm-6 col-lg-4 col-xl-4">
            <p className="lead">Please provide the information, that you have saved.</p>

            {error && <Alert msg={error} />}
            {errorSecurityCheck && <Alert msg={errorSecurityCheck} />}

            <form onSubmit={submitHandler}>
              <div className="form-group">
                <label>{resetQuestion}</label>
                <input type="text" name="answer" maxLength="50" className="form-control" value={answer} onChange={onChange} />
              </div>
              <div className="form-group">
                <label>Enter your secret's number</label>
                <input type="text" name="secretResetNumber" value={secretResetNumber} minLength="3" maxLength="8" className="form-control mb-5" onChange={onChange} />
              </div>

              <ReCAPTCHA
                sitekey="6Le9PwwaAAAAAGEIbNl1dlPplzYhZ9JfshYJo8WG"
                onChange={recaptchaHandler}
              />,

              <input type="submit" value="Submit" className="btn btn-primary btn-block mt-4" disabled={!recaptcha} />
            </form>
          </div>
        </div>
      </div>
  )
}

export default SecurityCreatePage
