import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import Rating from '../Rating'
import { htmlHandleChars } from '../helper/utils.js'
import { getDetailsComment, deleteComment, clearCommentDetails } from '../../actions/commentActions'

const CommentItem = ({ comment, userInfo, postId }) => {
  const dispatch = useDispatch();

  const commentDeleteHandler = () => {
    dispatch(deleteComment(postId, comment._id));
    dispatch(clearCommentDetails());
  }


  const showEditDeleteBtn = (
    <Fragment>
      <button className="delete-review-btn btn btn-sm btn-danger float-right ml-2" onClick={commentDeleteHandler}>
        <i className="fas fa-trash"></i>
      </button>

      <Link
        to="#!"
        type="button"
        data-toggle="modal"
        data-target="#commentForm"
        className="btn btn-sm btn-primary float-right"
        onClick={() => dispatch(getDetailsComment(comment._id))}
      >
        <i className="fas fa-edit"></i>
      </Link>
    </Fragment>
  )



  return (
    <div className="mb-3 mr-3">
      <div className="float-left">
        {/* user.avatar.url  help to let avatar appear first by adding comment before page refreshed */}
        <img src={comment.user && comment.user.avatar ? comment.user.avatar.url : userInfo.avatar.url} alt="me" className="rounded-circle" style={{ height: '60px', width: '60px', marginRight: '10px' }} />
      </div>

      <h5>
        {(comment && comment.title)}
      </h5>

      <div className="clearfix">
        <div dangerouslySetInnerHTML={{ __html: htmlHandleChars(comment.text) }}></div>
        <p>
          {/* {truncate(comment.comment, 200)} {' '}
                {comment.comment.length > 200 && (
                  <small><a href="#!" className="card-link float-right text-primary" data-toggle="modal"
                    data-target="#showCommentModal" onClick={onClickLink}>read more</a></small>
                )} */}
        </p>
        {/* Modal action Comment Link */}
        {/* <CommentLink /> */}
        <div>
          <small>
            <Rating value={comment.ratings} />
          </small>
        </div>
        <small>{comment.user.firstName || userInfo.firstName} - <Moment format='DD/MM/YYYY, h:mm a'>{comment.createdAt}</Moment></small>

        {/* helps to solve the edit and delete btn */}
        {userInfo._id !== comment.user._id ? (
          userInfo._id === comment.user && (
            showEditDeleteBtn
          )
        ) : (
          showEditDeleteBtn
        )}

      </div>
      <hr />
    </div>
  )
}

export default CommentItem
