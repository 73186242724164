import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Moment from 'react-moment'
import { filterPosts } from '../../actions/postActions'
import Paginate from '../../components/Paginate'
import { removePost } from '../../actions/postActions'



const PostListPage = ({ userInfo, pageNumber, building }) => {
  const dispatch = useDispatch();

  const [search, SetSearch] = useState();

  const postListFilter = useSelector(state => state.postListFilter);
  const { posts: postsFiltered, page, pages } = postListFilter;


  useEffect(() => {

    // default, still not selected yet. Need this to show first time as soon user got to the Posts navbar
    dispatch(filterPosts('allPosts', pageNumber));

  }, [dispatch, pageNumber])


  const onChange = e => {
    SetSearch(e.target.value)
    dispatch(filterPosts(e.target.value));
  }

  return (
    <div className="tab-content" id="nav-tabContent">
      <div className="tab-pane fade show active" id="list-posts" role="tabpanel" aria-labelledby="list-posts-list">
        {/* // <!-- POSTS FILTER FORM --> */}
        <form className="form-group form-inline float-right mb-3">
          <select className="form-control mr-2" value={search} onChange={onChange}>
            <option value="allPosts">All posts</option>
            <option value={userInfo._id}>My posts</option>
          </select>
        </form>

        {/* // <!-- ALL POSTS TABLE LISTS --> */}
        <div className="table-responsive">
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th>Date</th>
                <th>Title</th>
                <th>Category</th>
                <th>Images</th>
                <th><i className="fas fa-star" style={{ color: '#ffd27d' }}></i></th>
                <th><i className="fas fa-map-pin fa-lg"></i></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {postsFiltered && postsFiltered.length > 0 && postsFiltered.map(post => (
                <tr key={post._id}>
                  <td><Moment format="DD/MM/YYYY">{post.createdAt}</Moment></td>
                  <td style={{ minWidth: '300px' }}>{post.title}</td>
                  <td>{post.category}</td>
                  <td>
                    {post.images && post.images.length > 0 && <i className="fa-solid fa-images fa-lg mr-2"></i>}
                    {post.files && post.files.length > 0 && <i className="fa-solid fa-file-pdf fa-lg"></i>}
                  </td>
                  <td>{post.ratingsAverage}</td>
                  <td>
                    {post.toVote && new Date(post.finalVote) > new Date() ? (
                      <i className="fas fa-calendar-check text-success"></i>
                    ) : !post.toVote && post.finalVote ? (
                      <i className="fas fa-ban text-danger"></i>
                    ) : post.toVote && new Date(post.finalVote) < new Date().getTime() ? (
                      <i className="fas fa-calendar-times text-danger"></i>
                    ) : (
                      <span className="text-muted">No vote</span>
                    )}
                  </td>
                  <td>
                    <Link to={`/posts/${post.id}/comments`}>
                      <i className="fas fa-eye text-primary"></i>
                    </Link>
                  </td>
                  <td></td>
                  <td>
                    {userInfo.role !== 'admin' ? (
                      post.user._id === userInfo._id &&
                      <button className=" btn btn-light btn-sm" onClick={() => dispatch(removePost(building._id, post._id))}>
                        <i className="fas fa-trash-alt text-danger"></i>
                      </button>
                    ) : (
                      <button className=" btn btn-light btn-sm" onClick={() => dispatch(removePost(building._id, post._id))}>
                        <i className="fas fa-trash-alt text-danger"></i>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Pagination */}
          <Paginate page={page} pages={pages} isDashPostList={true} />
        </div>
      </div >
    </div >
  )
}



export default PostListPage
