import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createVideo, clearVideoDetails, updateVideo } from '../../actions/videoActions'

const VideoForm = () => {
  const [formData, setFormData] = useState({
    title: '',
    image_url: '',
    video_url: '',
    description: ''
  })

  const dispatch = useDispatch();

  const videoDetails = useSelector(state => state.videoDetails)
  const { video, loading } = videoDetails

  useEffect(() => {
    if (video) {
      setFormData({
        title: loading || !video.title ? '' : video.title,
        image_url: loading || !video.image_url ? '' : video.image_url,
        video_url: loading || !video.video_url ? '' : video.video_url,
        description: loading || !video.description ? '' : video.description,
      })
    } else {
      setFormData({
        title: '',
        image_url: '',
        video_url: '',
        description: ''
      })
    }
  }, [video, loading])

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onSubmitHandler = (e) => {
    e.preventDefault()

    if (video === undefined) {
      dispatch(createVideo(formData))
    } else {
      dispatch(updateVideo(formData, video._id))
      dispatch(clearVideoDetails())
    }
  }

  const { title, image_url, video_url, description } = formData

  return (
    <form className='form-group mr-4' onSubmit={onSubmitHandler}>
      <div className='mb-3'>
        <input type="text" name="title" value={title} className='form-control' onChange={onChange} />
        <small>Title</small>
      </div>
      <div className="mb-3">
        <input type="text" name="image_url" value={image_url} className='form-control' onChange={onChange} />
        <small>Image url</small>
      </div>
      <div className='mb-3'>
        <input type="text" name="video_url" value={video_url} className='form-control' onChange={onChange} />
        <small>Video url</small>
      </div>
      <div className="mb-3">
        <small></small>
        <textarea name="description" value={description} cols="30" rows="3" className='form-control' onChange={onChange}></textarea>
      </div>

      <input type="submit" value="Submit" className='btn btn-primary' /> &nbsp;
      {video && (
        <input type="button" value="Cancel" className='btn btn-secondary' onClick={() => dispatch(clearVideoDetails())} />
      )}
    </form>
  )
}

export default VideoForm