import axios from 'axios'
import { setAlert } from '../actions/alertActions'
import { ADMIN_FULL_GRANTED_RESET } from '../constants/adminConstants';
import { BUILDING_CHECK_PENDING_RESET, BUILDING_CREATE_RESET, BUILDING_DETAILS_RESET, BUILDING_DETAILS_SUCCESS, BUILDING_LIST_RESET, BUILDING_VERIFY_RESET } from '../constants/buildingConstants';
import { COMMENT_LIST_RESET, COMMENT_RECENT_LIST_RESET } from '../constants/commentConstants';
import { EXPENSE_LIST_RESET } from '../constants/expenseConstants';
import { POST_DETAILS_RESET, POST_LIST_RESET, POST_VOTE_LIST_RESET, UPDATE_COUNTDOWN_RESET } from '../constants/postConstants';
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,

  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,

  USER_UPDATE_ME_REQUEST,
  USER_UPDATE_ME_SUCCESS,
  USER_UPDATE_ME_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,

  USER_IMAGE_UPLOAD_REQUEST,
  USER_IMAGE_UPLOAD_SUCCESS,
  USER_IMAGE_UPLOAD_FAIL,

  USER_PASSWORD_UPDATE_REQUEST,
  USER_PASSWORD_UPDATE_SUCCESS,
  USER_PASSWORD_UPDATE_FAIL,

  USER_LIST_REQUEST,
  USER_LIST_FAIL,
  USER_LIST_SUCCESS,
  USER_LIST_RESET,

  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,

  USER_CONTACT_SUPPORT_REQUEST,
  USER_CONTACT_SUPPORT_SUCCESS,
  USER_CONTACT_SUPPORT_FAIL,

  USER_EMAIL_LIST_REQUEST,
  USER_EMAIL_LIST_SUCCESS,
  USER_EMAIL_LIST_FAIL,

  USER_EMAIL_DETAILS_REQUEST,
  USER_EMAIL_DETAILS_SUCCESS,
  USER_EMAIL_DETAILS_FAIL,

  USER_EMAIL_REMOVE_REQUEST,
  USER_EMAIL_REMOVE_SUCCESS,
  USER_EMAIL_REMOVE_FAIL,

  USER_MARK_EMAIL_READ_SUCCESS,
  USER_MARK_EMAIL_READ_FAIL,

  USER_BAN_REQUEST,
  USER_BAN_SUCCESS,
  USER_BAN_FAIL,

  USER_ACTIVATE_REQUEST,
  USER_ACTIVATE_SUCCESS,
  USER_ACTIVATE_FAIL,

  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAIL,
  USER_FORGOT_PASSWORD_RESET,

  USER_SECURITY_CHECK_REQUEST,
  USER_SECURITY_CHECK_SUCCESS,
  USER_SECURITY_CHECK_FAIL,
  USER_SECURITY_CHECK_RESET,

  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_RESET_PASSWORD_RESET,

  USER_SECURITY_CREATE_REQUEST,
  USER_SECURITY_CREATE_SUCCESS,
  USER_SECURITY_CREATE_FAIL,
  USER_SECURITY_CREATE_RESET,

  USER_NEW_AUTHOR_REQUEST,
  USER_NEW_AUTHOR_SUCCESS,
  USER_NEW_AUTHOR_FAIL,

  USER_NEW_BUILDING_LIST_REQUEST,
  USER_NEW_BUILDING_LIST_SUCCESS,
  USER_NEW_BUILDING_LIST_FAIL,

  USER_SWITCH_BUILDING_REQUEST,
  USER_SWITCH_BUILDING_SUCCESS,
  USER_SWITCH_BUILDING_FAIL,
  USER_EMAIL_LIST_RESET,

} from '../constants/userConstants'

import { getBuildingDetails } from './buildingActions';



export const login = formData => async (dispatch) => {
  dispatch({ type: USER_LOGIN_REQUEST });

  try {
    const { data } = await axios.post(`/api/users/login`, formData);
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data
    })

    localStorage.setItem('userInfo', JSON.stringify(data));

  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

export const logout = () => dispatch => {
  localStorage.removeItem('userInfo');
  localStorage.removeItem('buildingDetails');
  localStorage.removeItem('userTokenRequest');
  localStorage.removeItem('userForgotPassword');
  localStorage.removeItem('userSecurityCheck');
  localStorage.removeItem('buildingPending');
  localStorage.removeItem('buildings');
  localStorage.removeItem('adminFullGranted');

  dispatch({ type: USER_LOGOUT });
  dispatch({ type: POST_LIST_RESET });
  dispatch({ type: POST_DETAILS_RESET });
  dispatch({ type: POST_VOTE_LIST_RESET });
  dispatch({ type: UPDATE_COUNTDOWN_RESET })
  dispatch({ type: COMMENT_LIST_RESET })
  dispatch({ type: COMMENT_RECENT_LIST_RESET })
  dispatch({ type: EXPENSE_LIST_RESET })
  dispatch({ type: USER_LIST_RESET })
  dispatch({ type: USER_EMAIL_LIST_RESET })
  dispatch({ type: BUILDING_CREATE_RESET })
  dispatch({ type: BUILDING_DETAILS_RESET })
  dispatch({ type: BUILDING_VERIFY_RESET })
  dispatch({ type: BUILDING_LIST_RESET })
  dispatch({ type: BUILDING_CHECK_PENDING_RESET })
  dispatch({ type: USER_SECURITY_CREATE_RESET })
  dispatch({ type: USER_FORGOT_PASSWORD_RESET })
  dispatch({ type: USER_SECURITY_CHECK_RESET })
  dispatch({ type: USER_RESET_PASSWORD_RESET })
  dispatch({ type: ADMIN_FULL_GRANTED_RESET })

}

export const updateMe = formData => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_ME_REQUEST })

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    const { data } = await axios.put(`/api/users/me`, formData, config);
    dispatch({
      type: USER_UPDATE_ME_SUCCESS,
      payload: data
    })

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data
    })

    localStorage.setItem('userInfo', JSON.stringify(data));

    dispatch(setAlert('User updated!', 'success'));

  } catch (error) {
    dispatch({
      type: USER_UPDATE_ME_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

export const updateUser = (id, formData) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_REQUEST })

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    const { data } = await axios.put(`/api/users/${id}`, formData, config);
    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data
    })

    dispatch(setAlert('User updated!', 'success'));

  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

export const updatePassword = formData => async (dispatch, getState) => {
  dispatch({ type: USER_PASSWORD_UPDATE_REQUEST })

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/users/password`, formData, config);

    dispatch({
      type: USER_PASSWORD_UPDATE_SUCCESS,
      payload: data
    })

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data
    })

    localStorage.setItem('userInfo', JSON.stringify(data));


  } catch (error) {
    if (`${error.response.data.message}`.includes('Path `password`')) {
      return dispatch({
        type: USER_PASSWORD_UPDATE_FAIL,
        payload: 'Give the new password you want to update.'
      })
    }

    dispatch({
      type: USER_PASSWORD_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

export const registerUser = formData => async dispatch => {
  dispatch({ type: USER_REGISTER_REQUEST });

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const { data } = await axios.post(`/api/users/register`, formData, config);
    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data
    })

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data
    })

    localStorage.setItem('userInfo', JSON.stringify(data));

  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

export const createSecurityQuestion = formData => async (dispatch, getState) => {
  dispatch({ type: USER_SECURITY_CREATE_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    const { data } = await axios.post(`/api/users/security-questions`, formData, config);
    dispatch({
      type: USER_SECURITY_CREATE_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: USER_SECURITY_CREATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

export const listUsers = (pageNumber, buildingId) => async (dispatch, getState) => {

  dispatch({ type: USER_LIST_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    const { data } = await axios.get(`/api/users/?&pageNumber=${pageNumber}&buildingId=${buildingId}`, config);

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data
    })


  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

export const getUserDetails = id => async (dispatch, getState) => {
  dispatch({ type: USER_EMAIL_DETAILS_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    const { data } = await axios.get(`/api/users/${id}`, config);

    dispatch({
      type: USER_EMAIL_DETAILS_SUCCESS,
      payload: data
    })


  } catch (error) {
    dispatch({
      type: USER_EMAIL_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

export const deleteUser = id => async (dispatch, getState) => {
  dispatch({ type: USER_DELETE_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    await axios.delete(`/api/users/${id}`, config);
    dispatch({
      type: USER_DELETE_SUCCESS
    })


  } catch (error) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

export const addUserasAuthor = (buildingId, userId,) => async (dispatch, getState) => {
  dispatch({ type: USER_NEW_AUTHOR_REQUEST })

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/users/${userId}/building/${buildingId}/author/new`, {}, config);

    dispatch({
      type: USER_NEW_AUTHOR_SUCCESS,
      payload: data
    })

    // dispatch({ type: BUILDING_DETAILS_RESET })

    dispatch({
      type: BUILDING_DETAILS_SUCCESS,
      payload: data
    })

    // cannot just localstorage.setItem because of populated problem. user muss directly populated to mark user as new author
    dispatch(getBuildingDetails(buildingId))


  } catch (error) {
    dispatch({
      type: USER_NEW_AUTHOR_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const uploadProfileImage = (formData) => async (dispatch, getState) => {
  dispatch({ type: USER_IMAGE_UPLOAD_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.post('/api/upload', formData, config);

    dispatch({
      type: USER_IMAGE_UPLOAD_SUCCESS
    })

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data
    })

    localStorage.setItem('userInfo', JSON.stringify(data));

  } catch (error) {
    dispatch({
      type: USER_IMAGE_UPLOAD_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const sendEmail = (formData, ticket = 0) => async (dispatch, getState) => {
  dispatch({ type: USER_CONTACT_SUPPORT_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    const { data } = await axios.post(`/api/emails?ticket=${ticket}`, formData, config);

    dispatch({
      type: USER_CONTACT_SUPPORT_SUCCESS,
      payload: data
    })


  } catch (error) {
    dispatch({
      type: USER_CONTACT_SUPPORT_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

export const listMyEmails = () => async (dispatch, getState) => {
  dispatch({ type: USER_EMAIL_LIST_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    const { data } = await axios.get(`/api/emails`, config);

    dispatch({
      type: USER_EMAIL_LIST_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: USER_EMAIL_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

export const markEmailRead = (id, userId) => async (dispatch, getState) => {

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    await axios.put(`/api/emails/${id}/read`, {}, config);
    dispatch({
      type: USER_MARK_EMAIL_READ_SUCCESS,
    })

    dispatch(listMyEmails());


  } catch (error) {
    dispatch({
      type: USER_MARK_EMAIL_READ_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

export const getEmailDetails = (id) => async (dispatch, getState) => {
  dispatch({ type: USER_EMAIL_DETAILS_REQUEST })

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`/api/emails/${id}`, config);
    dispatch({
      type: USER_EMAIL_DETAILS_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: USER_EMAIL_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

export const removeEmail = (id) => async (dispatch, getState) => {
  dispatch({ type: USER_EMAIL_REMOVE_REQUEST })

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    await axios.put(`/api/emails/${id}/remove`, {}, config);
    dispatch({
      type: USER_EMAIL_REMOVE_SUCCESS,
    })

    dispatch(listMyEmails());


  } catch (error) {
    dispatch({
      type: USER_EMAIL_REMOVE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

export const banUser = (buildingId, id) => async (dispatch, getState) => {
  dispatch({ type: USER_BAN_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/users/${id}/building/${buildingId}/ban`, {}, config);
    dispatch({
      type: USER_BAN_SUCCESS
    })

    dispatch({
      type: BUILDING_DETAILS_SUCCESS,
      payload: data
    })


    localStorage.setItem('buildingDetails', JSON.stringify(data));


  } catch (error) {
    dispatch({
      type: USER_BAN_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

export const updateUserBuildingList = (id) => async (dispatch, getState) => {
  dispatch({ type: USER_NEW_BUILDING_LIST_REQUEST })

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/users/building/${id}/new/list`, {}, config);

    dispatch({
      type: USER_NEW_BUILDING_LIST_SUCCESS,
      payload: data
    })

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data
    })

    localStorage.setItem('userInfo', JSON.stringify(data));


  } catch (error) {
    dispatch({
      type: USER_NEW_BUILDING_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

// after channel created and got a code to share. WelcomePage
export const activateBuilding = (id) => async (dispatch, getState) => {
  dispatch({ type: USER_ACTIVATE_REQUEST })

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/users/building/${id}/activate`, {}, config);

    dispatch({
      type: USER_ACTIVATE_SUCCESS,
      payload: data
    })

    localStorage.setItem('buildingDetails', JSON.stringify(data));


  } catch (error) {
    dispatch({
      type: USER_ACTIVATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

export const switchBuilding = (buildingGroup, history) => async (dispatch, getState) => {
  dispatch({ type: USER_SWITCH_BUILDING_REQUEST })

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/users/switch/${buildingGroup}`, {}, config);

    if (data.message === 'You are banned from entering this channel') {
      return history.push('/');
    }

    dispatch({
      type: USER_SWITCH_BUILDING_SUCCESS,
      payload: data
    })

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data
    })


    // need this to solve problem of userlist admin page (cannot find user._id) while selecting channel
    dispatch(listUsers(1, data._id));

    localStorage.setItem('userInfo', JSON.stringify(data));

    // need this to solve error in PostDetailsPage.js when changing room
    window.location.assign('/')



  } catch (error) {
    dispatch({
      type: USER_SWITCH_BUILDING_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}


// 1. Email verification

export const requestForgotPassword = (formData) => async (dispatch) => {
  dispatch({ type: USER_FORGOT_PASSWORD_REQUEST })

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const { data } = await axios.post(`/api/users/forgot-password`, formData, config);

    dispatch({
      type: USER_FORGOT_PASSWORD_SUCCESS,
      payload: data
    })

    localStorage.setItem('userForgotPassword', JSON.stringify(data));

  } catch (error) {
    dispatch({
      type: USER_FORGOT_PASSWORD_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

// 2. Security check with question and token
export const processSecurityCheck = (id, token, formData) => async (dispatch) => {

  dispatch({ type: USER_SECURITY_CHECK_REQUEST })


  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const { data } = await axios.post(`/api/users/${id}/security-check/${token}`, formData, config);

    dispatch({
      type: USER_SECURITY_CHECK_SUCCESS,
      payload: data
    })

    localStorage.setItem('userSecurityCheck', JSON.stringify(data));

  } catch (error) {
    dispatch({
      type: USER_SECURITY_CHECK_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

// 3. CHange the password
export const resetPassword = (id, token, formData) => async (dispatch) => {
  dispatch({ type: USER_RESET_PASSWORD_REQUEST });

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const { data } = await axios.put(`/api/users/${id}/reset-password/${token}`, formData, config);

    dispatch({
      type: USER_RESET_PASSWORD_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: USER_RESET_PASSWORD_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}





