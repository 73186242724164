import axios from 'axios'
import {
  COMMENT_CREATE_SUCCESS,
  COMMENT_CREATE_FAIL,
  COMMENT_LIST_REQUEST,
  COMMENT_LIST_SUCCESS,
  COMMENT_LIST_FAIL,
  COMMENT_DETAILS_REQUEST,
  COMMENT_DETAILS_SUCCESS,
  COMMENT_DETAILS_FAIL,
  COMMENT_DETAILS_RESET,
  COMMENT_RECENT_LIST_REQUEST,
  COMMENT_RECENT_LIST_SUCCESS,
  COMMENT_RECENT_LIST_FAIL,
  COMMENT_UPDATE_SUCCESS,
  COMMENT_UPDATE_FAIL,
  COMMENT_DELETE_SUCCESS,
  COMMENT_DELETE_FAIL,
  COMMENT_MARK_READ_SUCCESS,
  COMMENT_MARK_READ_FAIL,
} from '../constants/commentConstants'


export const listComments = (id, pageNumber = '') => async (dispatch, getState) => {
  dispatch({ type: COMMENT_LIST_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`/api/posts/${id}/comments?pageNumber=${pageNumber}`, config);
    dispatch({
      type: COMMENT_LIST_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: COMMENT_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const addComment = (id, formData) => async (dispatch, getState) => {
  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.post(`/api/posts/${id}/comments`, formData, config);

    dispatch({
      type: COMMENT_CREATE_SUCCESS,
      payload: data
    })


  } catch (error) {
    dispatch({
      type: COMMENT_CREATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const getDetailsComment = id => async (dispatch, getState) => {
  dispatch({ type: COMMENT_DETAILS_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`/api/posts/${id}/comments/${id}`, config);

    dispatch({
      type: COMMENT_DETAILS_SUCCESS,
      payload: data
    })


  } catch (error) {
    dispatch({
      type: COMMENT_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const clearCommentDetails = () => dispatch => {
  dispatch({ type: COMMENT_DETAILS_RESET })
}

export const updateComment = (id, commentId, formData) => async (dispatch, getState) => {
  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/posts/${id}/comments/${commentId}`, formData, config);

    dispatch({
      type: COMMENT_UPDATE_SUCCESS,
      payload: { data, commentId }
    })

    // Edit/delete btn disapear after update withouth this the author comment rule in commentItem
    dispatch(listComments(id));


  } catch (error) {
    dispatch({
      type: COMMENT_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const deleteComment = (id, commentId) => async (dispatch, getState) => {
  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    await axios.delete(`/api/posts/${id}/comments/${commentId}`, config);

    dispatch({
      type: COMMENT_DELETE_SUCCESS,
      payload: commentId
    })


  } catch (error) {
    dispatch({
      type: COMMENT_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const listRecentComments = () => async (dispatch, getState) => {
  dispatch({ type: COMMENT_RECENT_LIST_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`/api/posts/comments/recent`, config);
    dispatch({
      type: COMMENT_RECENT_LIST_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: COMMENT_RECENT_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const markRecentCommentRead = (id) => async (dispatch, getState) => {
  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/posts/comments/${id}/read`, {}, config);
    dispatch({
      type: COMMENT_MARK_READ_SUCCESS,
      payload: data
    })


    dispatch(listRecentComments());


  } catch (error) {
    dispatch({
      type: COMMENT_MARK_READ_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

