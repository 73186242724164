import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Spinner from '../components/Spinner'
import { login, logout } from '../actions/userActions'
import { setAlert } from '../actions/alertActions'
import Alert from '../components/Alert'
import Recaptcha from '../components/Recaptcha'

// updated


const Login = ({ history }) => {
  const [recaptcha, setRecaptcha] = useState(false)
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const alertAction = useSelector(state => state.alertAction);
  const { alert } = alertAction;

  const recaptchaHandler = () => {
    setRecaptcha(true)
  }

  useEffect(() => {
    if (userInfo) {  // check firstname helps also to get rid the flash login form
      history.push(`/posts/search/control`)
    }

    if (error) {
      dispatch(setAlert(error, 'danger')); // 'danger' optional
      setRecaptcha(false)
    }

  }, [dispatch, history, userInfo, loading, error])

  const onSubmit = e => {
    e.preventDefault();
    if (!email || !password) {
      dispatch(setAlert('Please enter you credential !', 'danger'));
    } else {

      dispatch(login(formData));
    }
  }

  return loading || userInfo ? <Spinner /> :
    <div className="container" style={{ maxWidth: '600px' }}>
      <div className="content">
        <h3 className="text-info text-center mb-5">Login</h3>
        {alert && <Alert msg={alert.msg} />}

        {/* code from https://mdbootstrap.com/docs/standard/extended/login/ */}

        {/* <ul className="nav nav-pills nav-justified mb-3" id="ex1" role="tablist">
            <li className="nav-item" role="presentation">
              <a className="nav-link active" id="tab-login" data-mdb-toggle="pill" href="#pills-login" role="tab"
                aria-controls="pills-login" aria-selected="true">Login</a>
            </li>
            <li className="nav-item" role="presentation">
              <a className="nav-link" id="tab-register" data-mdb-toggle="pill" href="#pills-register" role="tab"
                aria-controls="pills-register" aria-selected="false">Register</a>
            </li>
          </ul> */}

        <div className="tab-content">
          <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="tab-login">
            <form onSubmit={onSubmit}>
              {/* <div className="text-center mb-3">
                <p>Sign in with:</p>
                <button type="button" className="btn btn-link btn-floating mx-1">
                  <i className="fab fa-facebook-f"></i>
                </button>

                <button type="button" className="btn btn-link btn-floating mx-1">
                  <i className="fab fa-google"></i>
                </button>

                <button type="button" className="btn btn-link btn-floating mx-1">
                  <i className="fab fa-twitter"></i>
                </button>

                <button type="button" className="btn btn-link btn-floating mx-1">
                  <i className="fab fa-github"></i>
                </button>
              </div> */}

              {/* <p className="text-center">or:</p> */}

              <div className="form-outline mb-4">
                <input
                  type="email"
                  className="form-control form-control-lg"
                  value={email} name="email"
                  onChange={e => setFormData({ ...formData, email: e.target.value })}
                />
                <small className="form-label" htmlFor="loginName">Email</small>
              </div>


              <div className="form-outline mb-4">
                <input
                  type="password"
                  className="form-control form-control-lg"
                  value={password} name="password"
                  onChange={e => setFormData({ ...formData, password: e.target.value })}
                />
                <small className="form-label" htmlFor="loginPassword">Password</small>
              </div>

              {/* <div className="row mb-4">
                  <div className="col-md-6 d-flex justify-content-center">
                    <div className="form-check mb-3 mb-md-0">
                      <input className="form-check-input" type="checkbox" value="" id="loginCheck" checked />
                      <label className="form-check-label" htmlFor="loginCheck"> Remember me </label>
                    </div>
                  </div>

                  <div className="col-md-6 d-flex justify-content-center">
                    <a href="#!">Forgot password?</a>
                  </div>
                </div> */}

              <button type="submit" className="btn btn-primary btn-block mb-4" disabled={!recaptcha} >Sign in</button>

              {/* <div className="text-center">
                  <p>Not a member? <a href="#!">Register</a></p>
                </div> */}

              {/* Recaptcha */}
              <Recaptcha recaptchaHandler={recaptchaHandler} />

            </form>
          </div>
          {/* <div className="tab-pane fade" id="pills-register" role="tabpanel" aria-labelledby="tab-register">
            <form>
              <div className="text-center mb-3">
                <p>Sign up with:</p>
                <button type="button" className="btn btn-link btn-floating mx-1">
                  <i className="fab fa-facebook-f"></i>
                </button>

                <button type="button" className="btn btn-link btn-floating mx-1">
                  <i className="fab fa-google"></i>
                </button>

                <button type="button" className="btn btn-link btn-floating mx-1">
                  <i className="fab fa-twitter"></i>
                </button>

                <button type="button" className="btn btn-link btn-floating mx-1">
                  <i className="fab fa-github"></i>
                </button>
              </div>

              <p className="text-center">or:</p>


              <div className="form-outline mb-4">
                <input type="text" id="registerName" className="form-control" />
                <label className="form-label" htmlFor="registerName">Name</label>
              </div>


              <div className="form-outline mb-4">
                <input type="text" id="registerUsername" className="form-control" />
                <label className="form-label" htmlFor="registerUsername">Username</label>
              </div>

              <div className="form-outline mb-4">
                <input type="email" id="registerEmail" className="form-control" />
                <label className="form-label" htmlFor="registerEmail">Email</label>
              </div>


              <div className="form-outline mb-4">
                <input type="password" id="registerPassword" className="form-control" />
                <label className="form-label" htmlFor="registerPassword">Password</label>
              </div>

              <div className="form-outline mb-4">
                <input type="password" id="registerRepeatPassword" className="form-control" />
                <label className="form-label" htmlFor="registerRepeatPassword">Repeat password</label>
              </div>

              <div className="form-check d-flex justify-content-center mb-4">
                <input className="form-check-input me-2" type="checkbox" value="" id="registerCheck" checked
                  aria-describedby="registerCheckHelpText" />
                <label className="form-check-label" htmlFor="registerCheck">
                  I have read and agree to the terms
                </label>
              </div>
              <button type="submit" className="btn btn-primary btn-block mb-3">Sign in</button>
            </form>
          </div> */}
        </div>

        {/* MY OWN CODE*/}
        {/* <form className="form-group" onSubmit={onSubmit}>
            <div className="form-group">
              <small>Email</small>
              <input
                type="email"
                className="form-control form-control-lg"
                value={email} name="email"
                onChange={e => setFormData({ ...formData, email: e.target.value })}
              />
            </div>
            <div className="form-group">
              <small>Password</small>
              <input
                type="password"
                className="form-control form-control-lg mb-4"
                value={password} name="password"
                onChange={e => setFormData({ ...formData, password: e.target.value })}
              />
            </div>

            <ReCAPTCHA
              sitekey="6Le9PwwaAAAAAGEIbNl1dlPplzYhZ9JfshYJo8WG"
              onChange={recaptchaHandler}
            />

            <input type="submit" value="Login" className="btn btn-primary mt-4" disabled={!recaptcha} />
          </form> */}

        <div className="mt-3">
          <p>Don't have account ?
            <Link to="/register">
              <span className="text-primary">{' '}Register</span>
            </Link>
          </p>
          <p>Forgot your password ?
            <Link to="/forgot-password">
              <span className="text-primary" onClick={() => dispatch(logout())}>{' '}Reset password</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
}



export default Login;
