import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,

  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_RESET,

  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,

  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,

  USER_UPDATE_ME_REQUEST,
  USER_UPDATE_ME_SUCCESS,
  USER_UPDATE_ME_FAIL,
  USER_UPDATE_ME_RESET,

  USER_IMAGE_UPLOAD_REQUEST,
  USER_IMAGE_UPLOAD_SUCCESS,
  USER_IMAGE_UPLOAD_FAIL,
  USER_IMAGE_UPLOAD_RESET,

  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_RESET,

  USER_CONTACT_SUPPORT_REQUEST,
  USER_CONTACT_SUPPORT_SUCCESS,
  USER_CONTACT_SUPPORT_FAIL,
  USER_CONTACT_SUPPORT_RESET,

  USER_EMAIL_LIST_REQUEST,
  USER_EMAIL_LIST_SUCCESS,
  USER_EMAIL_LIST_FAIL,
  USER_EMAIL_LIST_RESET,

  USER_EMAIL_DETAILS_REQUEST,
  USER_EMAIL_DETAILS_SUCCESS,
  USER_EMAIL_DETAILS_FAIL,
  USER_EMAIL_DETAILS_RESET,

  USER_EMAIL_REMOVE_REQUEST,
  USER_EMAIL_REMOVE_SUCCESS,
  USER_EMAIL_REMOVE_FAIL,
  USER_EMAIL_REMOVE_RESET,

  USER_BAN_REQUEST,
  USER_BAN_SUCCESS,
  USER_BAN_FAIL,
  USER_BAN_RESET,

  USER_PASSWORD_UPDATE_REQUEST,
  USER_PASSWORD_UPDATE_SUCCESS,
  USER_PASSWORD_UPDATE_FAIL,
  USER_PASSWORD_UPDATE_RESET,

  USER_ACTIVATE_REQUEST,
  USER_ACTIVATE_SUCCESS,
  USER_ACTIVATE_FAIL,
  USER_ACTIVATE_RESET,

  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAIL,
  USER_FORGOT_PASSWORD_RESET,

  USER_SECURITY_CHECK_REQUEST,
  USER_SECURITY_CHECK_SUCCESS,
  USER_SECURITY_CHECK_FAIL,
  USER_SECURITY_CHECK_RESET,


  USER_SECURITY_CREATE_REQUEST,
  USER_SECURITY_CREATE_SUCCESS,
  USER_SECURITY_CREATE_FAIL,
  USER_SECURITY_CREATE_RESET,

  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_RESET_PASSWORD_RESET,

  USER_NEW_AUTHOR_REQUEST,
  USER_NEW_AUTHOR_SUCCESS,
  USER_NEW_AUTHOR_FAIL,
  USER_NEW_AUTHOR_RESET,

  USER_NEW_BUILDING_LIST_REQUEST,
  USER_NEW_BUILDING_LIST_SUCCESS,
  USER_NEW_BUILDING_LIST_FAIL,
  USER_NEW_BUILDING_LIST_RESET,

  USER_SWITCH_BUILDING_REQUEST,
  USER_SWITCH_BUILDING_SUCCESS,
  USER_SWITCH_BUILDING_FAIL,
  USER_SWITCH_BUILDING_RESET,
} from '../constants/userConstants'




export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return {
        loading: true,
        userInfo: null
      }
    case USER_REGISTER_SUCCESS:
      return {
        loading: false,
        success: true,
        userInfo: action.payload,
      }
    case USER_REGISTER_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_REGISTER_RESET:
      return {}
    default:
      return state;
  }
}

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
        userInfo: null
      }
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      }
    case USER_LOGIN_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    // case USER_UPDATE_SUCCESS:
    //   return {
    //     loading: false,
    //     userInfo: action.payload
    //   }
    case USER_LOGOUT:
      return {}
    default:
      return state;
  }
}

export const userUpdateMeReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_ME_REQUEST:
      return {
        loading: false
      }
    case USER_UPDATE_ME_SUCCESS:
      return {
        loading: false,
        success: true,
        userInfo: action.payload
      }
    case USER_UPDATE_ME_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_UPDATE_ME_RESET:
      return {}
    default:
      return state;
  }
}

export const userPasswordUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_UPDATE_REQUEST:
      return {
        loading: true
      }
    case USER_PASSWORD_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true
      }
    case USER_PASSWORD_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_PASSWORD_UPDATE_RESET:
      return {}
    default:
      return state;
  }
}

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return {
        loading: true,
        users: []
      }
    case USER_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        page: action.payload.page,
        pages: action.payload.pages,
        users: action.payload.users
      }
    case USER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_LIST_RESET:
      return {}
    default:
      return state;
  }
}

export const userDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    // case USER_DETAILS_REQUEST:
    //   return {
    //     loading: true
    //   }
    case USER_DETAILS_SUCCESS:
      return {
        loading: false,
        user: action.payload
      }
    case USER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_DETAILS_RESET:
      return {}
    default:
      return state;
  }
}

export const userUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return {
        loading: true
      }
    case USER_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        userInfo: action.payload
      }
    case USER_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_UPDATE_RESET:
      return {}
    default:
      return state;
  }
}

export const userImageUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_IMAGE_UPLOAD_REQUEST:
      return {
        loading: true
      }
    case USER_IMAGE_UPLOAD_SUCCESS:
      return {
        loading: false,
        success: true
      }
    case USER_IMAGE_UPLOAD_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_IMAGE_UPLOAD_RESET:
      return {}
    default:
      return state;
  }
}

export const userContactSupportReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CONTACT_SUPPORT_REQUEST:
      return {
        loading: true
      }
    case USER_CONTACT_SUPPORT_SUCCESS:
      return {
        loading: false,
        success: true
      }
    case USER_CONTACT_SUPPORT_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_CONTACT_SUPPORT_RESET:
      return {}
    default:
      return state;
  }
}

export const userEmailListReducer = (state = { emails: [] }, action) => {
  switch (action.type) {
    case USER_EMAIL_LIST_REQUEST:
      return {
        loading: true
      }
    case USER_EMAIL_LIST_SUCCESS:
      return {
        loading: false,
        unreadEmails: action.payload.unreadEmails,
        sentEmails: action.payload.sentEmails.filter(email => email.isRemoved === false),
        inboxEmails: action.payload.inboxEmails.filter(email => email.isRemoved === false)
      }
    case USER_EMAIL_LIST_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_EMAIL_LIST_RESET:
      return {}
    default:
      return state;
  }
}

export const userEmailDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_EMAIL_DETAILS_REQUEST:
      return {
        loading: true
      }
    case USER_EMAIL_DETAILS_SUCCESS:
      return {
        loading: false,
        email: action.payload
      }
    case USER_EMAIL_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_EMAIL_DETAILS_RESET:
      return {}
    default:
      return state;
  }
}

export const userBanReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_BAN_REQUEST:
      return {
        loading: true
      }
    case USER_BAN_SUCCESS:
      return {
        loading: false,
        success: true
      }
    case USER_BAN_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_BAN_RESET:
      return {}
    default:
      return state;
  }
}

export const userEmailRemoveReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_EMAIL_REMOVE_REQUEST:
      return {
        loading: true
      }
    case USER_EMAIL_REMOVE_SUCCESS:
      return {
        loading: false,
        email: action.payload
      }
    case USER_EMAIL_REMOVE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_EMAIL_REMOVE_RESET:
      return {}
    default:
      return state;
  }
}

export const userActivateBuildingReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ACTIVATE_REQUEST:
      return {
        loading: false
      }
    case USER_ACTIVATE_SUCCESS:
      return {
        loading: false,
        success: true
      }
    case USER_ACTIVATE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_ACTIVATE_RESET:
      return {}
    default:
      return state;
  }
}

export const userNewAuthorReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_NEW_AUTHOR_REQUEST:
      return {
        loading: true
      }
    case USER_NEW_AUTHOR_SUCCESS:
      return {
        loading: false,
        user: action.payload
      }
    case USER_NEW_AUTHOR_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_NEW_AUTHOR_RESET:
      return {}
    default:
      return state;
  }
}

export const userNewBuildingListReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_NEW_BUILDING_LIST_REQUEST:
      return {
        loading: true
      }
    case USER_NEW_BUILDING_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        // user: action.payload
      }
    case USER_NEW_BUILDING_LIST_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_NEW_BUILDING_LIST_RESET:
      return {}
    default:
      return state;
  }
}

export const userSwitchBuildingReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SWITCH_BUILDING_REQUEST:
      return {
        loading: true
      }
    case USER_SWITCH_BUILDING_SUCCESS:
      return {
        loading: false,
        success: true
      }
    case USER_SWITCH_BUILDING_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_SWITCH_BUILDING_RESET:
      return {}
    default:
      return state;
  }
}


// PASSWORD FORGOT################################################################################

// PUT after registration
export const userSecurityCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SECURITY_CREATE_REQUEST:
      return {
        loading: false
      }
    case USER_SECURITY_CREATE_SUCCESS:
      return {
        loading: false,
        success: true
      }
    case USER_SECURITY_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_SECURITY_CREATE_RESET:
      return {}
    default:
      return state;
  }
}

// POST Email verify
export const userForgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_FORGOT_PASSWORD_REQUEST:
      return {
        loading: false
      }
    case USER_FORGOT_PASSWORD_SUCCESS:
      return {
        loading: false,
        _id: action.payload._id,
        token: action.payload.token,
        resetQuestion: action.payload.resetQuestion
      }
    case USER_FORGOT_PASSWORD_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_FORGOT_PASSWORD_RESET:
      return {}
    default:
      return state;
  }
}

// PUT Security check
export const userSecurityCheckReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SECURITY_CHECK_REQUEST:
      return {
        loading: false
      }
    case USER_SECURITY_CHECK_SUCCESS:
      return {
        loading: false,
        _id: action.payload._id,
        token: action.payload.token
      }
    case USER_SECURITY_CHECK_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_SECURITY_CHECK_RESET:
      return {}
    default:
      return state;
  }
}

// PUT Security check
export const userPasswordResetReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    // CANNNOT USE THIS REQUEST (STATE ALWAYS SHOWS LOADING TRUE )
    // case USER_RESET_PASSWORD_REQUEST:
    //   return {
    //     loading: true
    //   }
    case USER_RESET_PASSWORD_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case USER_RESET_PASSWORD_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_RESET_PASSWORD_RESET:
      return {}
    default:
      return state;
  }
}



