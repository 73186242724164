import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEmailsSameTicket } from '../../actions/adminAction'


const AdminTicketPage = () => {
  const dispatch = useDispatch();

  const adminEmailDetails = useSelector(state => state.adminEmailDetails);
  const { email } = adminEmailDetails;

  const adminEmailTicketList = useSelector(state => state.adminEmailTicketList);
  const { emails } = adminEmailTicketList;

  useEffect(() => {
    if (email) {
      dispatch(getEmailsSameTicket(email._id, email.ticket))
    }
  }, [dispatch, email])

  return (
    <div className="row">
      <div className="col-md">
        {emails && emails.map(item => (
          <p key={item._id} className={`ticket ${item.user.role === 'admin' && 'bg-danger text-light'}`}>
            <img src={item.user.avatar.url} alt={item.user.firstName} className="rounded-circle" style={{ width: '50px', height: '50px' }} />&nbsp; {item.text}
            <span className="float-right">{item.isRemoved && <i className="fas fa-minus-circle fa-lg"></i>}</span>
          </p>
        ))}
      </div>
    </div>
  )
}

export default AdminTicketPage
