import {
  VIDEO_CREATE_REQUEST,
  VIDEO_CREATE_SUCCESS,
  VIDEO_CREATE_FAIL,
  VIDEO_LIST_REQUEST,
  VIDEO_LIST_SUCCESS,
  VIDEO_LIST_FAIL,
  VIDEO_DETAILS_SUCCESS,
  VIDEO_DETAILS_REQUEST,
  VIDEO_DETAILS_FAIL,
  VIDEO_DETAILS_RESET,
  VIDEO_SELECTED_REQUEST,
  VIDEO_SELECTED_SUCCESS,
  VIDEO_UPDATE_REQUEST,
  VIDEO_UPDATE_FAIL,
  VIDEO_UPDATE_SUCCESS,
  VIDEO_REMOVE_SUCCESS,
} from '../constants/videoConstants'


export const videoCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case VIDEO_CREATE_REQUEST:
      return {
        loading: true
      }
    case VIDEO_CREATE_SUCCESS:
      return {
        loading: false,
        video: action.payload
      }
    case VIDEO_CREATE_FAIL:
      return {
        error: action.payload,
        loading: false
      }
    default:
      return state
  }
}

export const videoListReducer = (state = {}, action) => {
  switch (action.type) {
    case VIDEO_LIST_REQUEST:
      return {
        loading: true
      }
    case VIDEO_CREATE_SUCCESS:
      return {
        loading: false,
        videos: [action.payload, ...state.videos]
      }
    case VIDEO_LIST_SUCCESS:
      return {
        loading: false,
        videos: action.payload
      }
    case VIDEO_UPDATE_SUCCESS:
    case VIDEO_REMOVE_SUCCESS:        // for immediate hide/unhide btn Videoitem.js
      return {
        loading: false,
        videos: state.videos.map(video => video._id === action.payload._id ? action.payload : video)
      }
    case VIDEO_LIST_FAIL:
      return {
        error: action.payload,
        loading: false
      }
    default:
      return state
  }
}

export const videoDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case VIDEO_DETAILS_REQUEST:
    case VIDEO_SELECTED_REQUEST:
      return {
        loading: true
      }
    case VIDEO_DETAILS_SUCCESS:
    case VIDEO_SELECTED_SUCCESS:
      return {
        loading: false,
        video: action.payload
      }
    case VIDEO_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case VIDEO_DETAILS_RESET:
      return {
        video: null
      }
    default:
      return state;
  }
}

export const videoUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case VIDEO_UPDATE_REQUEST:
      return {
        loading: true
      }
    case VIDEO_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

