import React from 'react'
import spinner from './spinner.gif'

const Spinner = () => {
  return (
    <div className="content">
      <img src={spinner} style={{ width: '70px', margin: 'auto', display: 'block' }} alt="Loading..." />
    </div>
  )
}

export default Spinner
