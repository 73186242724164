import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { switchBuilding } from '../../actions/userActions';
import { getBuildingDetails } from '../../actions/buildingActions'

const ChannelSelection = ({ buildings, history, }) => {
  const [community, setCommunity] = useState('');

  const dispatch = useDispatch();

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building, loading } = buildingDetails;

  const selectBuilding = async e => {
    setCommunity(e.target.value);
    const buildingGroup = e.target.value;
    await dispatch(switchBuilding(buildingGroup, history));
    await dispatch(getBuildingDetails());
    window.location.reload(1);
  }


  useEffect(() => {
    if (building && !loading) {
      setCommunity(building.group);
    }

  }, [loading, building])


  return (
    <>
      <select
        name="building"
        value={community}
        className="form-control form-control-sm bg-secondary text-light"
        onChange={selectBuilding}
      >

        {buildings && buildings.map(item => (
          <option key={item._id} value={item.group}>{item.group}</option>
        ))}
      </select>
    </>
  )
}

export default ChannelSelection
