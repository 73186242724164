import React from 'react'

const Footer = () => {
  return (
    <div className="footer text-center py-2 bg-dark text-light">
      <span>Copyright &copy; Balina - {new Date().getFullYear()}</span> &nbsp;
      <span>
        <a href="/term-and-conditions">Term and conditions</a>
      </span>
    </div>
  )
}

export default Footer
