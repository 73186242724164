import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getBuildingDetails, listBuildings } from '../actions/buildingActions'
import { logout, switchBuilding } from '../actions/userActions'
import { adminAuthenticate } from '../actions/adminAction'
import ChannelSelection from '../components/admin/ChannelSelection'
import logo from '../images/balina.png'
// import ReportForm from '../components/support/ReportForm'
import SupportInfo from '../components/support/SupportInfo'



const Header = ({ history }) => {
  const [community, setCommunity] = useState('');

  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const adminAuth = useSelector(state => state.adminAuth);
  const { token } = adminAuth;

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building, loading } = buildingDetails;

  const buildingList = useSelector(state => state.buildingList);
  const { buildings, loading: loadingBuildingList } = buildingList;



  const logoutHandler = () => {
    dispatch(logout());
  }

  const switchBuildingHandler = async (e) => {
    setCommunity(e.target.value);
    const buildingGroup = e.target.value;

    await dispatch(switchBuilding(buildingGroup, history));
    await dispatch(getBuildingDetails());

  }



  useEffect(() => {
    // must happen if user logged in
    if (userInfo) {
      if (!buildings || !buildings.length === 0) {
        dispatch(listBuildings());
      }
    }

    if (building && !loading) {
      setCommunity(building.group);
    }


  }, [dispatch, userInfo, loading, building, buildings, token])


  return (
    <>
      <nav className="navbar fixed-top clearfix navbar-expand-sm navbar-dark bg-dark mb-3">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/" >
            <img src={logo} alt="logo" style={{ height: '40px' }} />
          </Link>
          <button className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* <!-- MENU --> */}
          <div id="navbarCollapse" className="collapse navbar-collapse">
            <ul className="navbar-nav ml-auto">
              {userInfo && !loading ? (
                <>
                  {userInfo.community !== 'no-group' && (
                    <>
                      <li className="nav-item">
                        <Link to="/profile/me" className="nav-link">
                          <img src={`${userInfo.avatar && userInfo.avatar.url}`} alt="img" style={{ width: '25px', height: '25px' }} className="rounded-circle mr-2" /> {userInfo.firstName}!
                        </Link>
                      </li>
                      <li className="nav-item dropdown px-2">
                        <a href="#!" className="nav-link dropdown-toggle" data-toggle="dropdown">Options</a>
                        <div className="dropdown-menu">
                          <Link className="dropdown-item" to="/posts"><i className="fas fa-scroll  text-secondary mr-1"></i>Posts</Link>
                          <Link className="dropdown-item" to={`/building/${building && building._id}`}><i className="fas fa-building  text-info mr-2"></i>Building</Link>
                          {!userInfo.isGuest && (
                            <>
                              <Link className="dropdown-item" to="/profile/me"><i className="fas fa-user  text-primary mr-2"></i>Profile</Link>
                              <Link className="dropdown-item" to="/dashboard"><i className="fas fa-cog  text-danger mr-2"></i>Dashboard</Link>
                            </>
                          )}
                          <Link className='dropdown-item' to="/report"><i className="fa-solid fa-sack-dollar" style={{ color: 'orange' }}></i> Report & others</Link>
                          {userInfo.role !== 'admin' ? (
                            building && building.expensePage && (
                              <Link className="dropdown-item" to={`/expenses`}><i className="fas fa-wallet  text-success mr-2"></i>Expenses</Link>
                            )
                          ) : (
                            <Link className="dropdown-item" to={`/expenses`}><i className="fas fa-wallet  text-success mr-2"></i>Expenses</Link>
                          )}
                          {userInfo && userInfo.role === 'admin' && (
                            <a
                              href='#!'
                              className="dropdown-item"
                              onClick={() => dispatch(adminAuthenticate(history))} //this redirect to admin login form. look admin action
                            >
                              Settings
                            </a>
                          )}
                        </div>
                      </li>
                    </>
                  )}
                  <li className="nav-item">
                    <Link className="nav-link" to="#!" onClick={logoutHandler}>
                      <i className="fas fa-sign-out-alt"></i> Logout
                    </Link>
                  </li>
                  <li className="ml-3 mt-2">
                    {userInfo.role !== 'admin' ? (
                      userInfo.buildings && userInfo.buildings.length > 0 && (
                        <>
                          {/* only buildings where user joined */}
                          <select
                            name="building"
                            value={community}
                            className="form-control form-control-sm bg-secondary text-light"
                            onChange={switchBuildingHandler}>
                            {userInfo.buildings.map((building) => (
                              <option
                                key={building._id}
                                value={building.group}
                              >
                                {building.group}
                              </option> // value is group because find by name group (group's name is individual)
                            ))}
                          </select>
                        </>
                      )
                    ) : (
                      // all buildings
                      <ChannelSelection
                        buildings={buildings}
                        loadingBuildingList={loadingBuildingList}
                      />
                    )}
                  </li>

                  {/* Technical support */}
                  <li className="nav-item">
                    <a href="#!" data-toggle="modal" data-target="#supportForm" className="ml-4">
                      {/* <i className="fas fa-headset fa-lg mt-3"></i> */}
                      <i className="fa-brands fa-line fa-2x mt-2 text-success"></i>
                    </a>
                  </li>
                  <li className="nav-item">
                    {/* APP GUIDE*/}
                    <Link to="/video-guide">
                      <i className="fab fa-youtube fa-2x mt-2 ml-4 text-danger"></i>
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/">Home</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/login" className="nav-link">Login</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/register" className="nav-link">Sign Up</Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav >

      {/* Technical support form */}
      {/* <ReportForm userInfo={userInfo} /> */}
      <SupportInfo />
    </>
  )
}

export default Header
