import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Moment from 'react-moment'
import { markEmailRead } from '../../actions/userActions';
import EmailButton from '../../components/buttons/EmailButton'
import { truncate } from '../../components/helper/utils';


const EmailListPage = ({ userInfo, adminEmails }) => {
  const [emailSelected, setEmailSelected] = useState([]);


  const dispatch = useDispatch();

  const userEmailList = useSelector(state => state.userEmailList);
  const { inboxEmails, sentEmails } = userEmailList;


  const toggleSelectEmailHandler = (emailId) => {
    let isSelected = { ...emailSelected };

    if (!isSelected[emailId]) {
      isSelected[emailId] = true;
      setEmailSelected(isSelected);
    } else {
      isSelected[emailId] = false;
      setEmailSelected(isSelected);
    }
  }

  const isEmailRead = (email, userInfo) => dispatch => {
    if (email) {
      return email.isRead.some(item => item.user === userInfo._id);
    }
  }

  const emailRemoved = email => {
    return {
      color: email.isRemoved && '#ddd'
    }
  }

  // console.log(emailSelected)

  return (
    <div className="row">
      <div className="col-md">
        {/* TAB MENU */}
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" id="inbox-tab" data-toggle="tab" href="#inbox" role="tab" aria-controls="inbox" aria-selected="true">Inbox</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="sent-tab" data-toggle="tab" href="#sent" role="tab" aria-controls="sent" aria-selected="false">Sent</a>
          </li>
        </ul>


        {/* TAB CONTENT */}
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="inbox" role="tabpanel" aria-labelledby="inbox-tab">

            <EmailButton emailSelected={emailSelected} />

            <div className="table-responsive">
              <table className="table table-stripped">
                <thead>
                  <tr>
                    <th style={{ width: '3%' }}></th>
                    <th style={{ width: '3%' }}></th>
                    <th style={{ width: '10%' }}>Date</th>
                    <th style={{ width: '10%' }}>From</th>
                    <th>
                      {userInfo.role === 'admin' && 'Is removed ?'}
                    </th>
                    <th>Message</th>
                  </tr>
                </thead>
                <tbody>
                  {userInfo.role !== 'admin' ? (
                    inboxEmails && inboxEmails.map(email => (
                      <tr key={email._id}>
                        <td>
                          <form>
                            <input type="checkbox" value={email._id} className="format-control" onClick={e => toggleSelectEmailHandler(e.target.value)} />
                          </form>
                        </td>
                        <td>
                          <i className={isEmailRead(email)
                            ? 'fas fa-envelope-open text-muted'
                            : 'fas fa-envelope text-primary'
                          }></i>
                        </td>
                        <td><Moment format="DD/MM/YYYY">{email.createdAt}</Moment></td>
                        <td>{email.user.firstName}</td>
                        <td>
                          <Link
                            to={`/dashboard/ticket/${email.ticket}/emails/${email._id}`}
                            className={isEmailRead(email) && 'text-muted'}
                            onClick={() => dispatch(markEmailRead(email._id))}
                          >
                            {truncate(email.text, 200)}
                          </Link>
                        </td>
                      </tr>
                    ))) : (
                    adminEmails && adminEmails.map(email => (
                      email.user && email.user.role !== 'admin' &&
                      <tr key={email._id} style={emailRemoved(email)}>
                        <td>
                          <form>
                            <input type="checkbox" value={email._id} className="format-control" onClick={e => toggleSelectEmailHandler(e.target.value)} />
                          </form>
                        </td>
                        <td>
                          <i className={() => dispatch(isEmailRead(email, userInfo))
                            ? 'fas fa-envelope-open text-muted'
                            : 'fas fa-envelope text-primary'
                          }></i>
                        </td>
                        <td><Moment format="DD/MM/YYYY">{email.createdAt}</Moment></td>
                        <td>{email.user.firstName}</td>
                        <td>{email.isRemoved ? 'yes' : 'no'}</td>
                        <td>
                          {truncate(email.text, 200)}
                        </td>
                      </tr>

                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {/* sent Email */}
          <div className="tab-pane fade" id="sent" role="tabpanel" aria-labelledby="sent-tab">
            <div className="table-responsive">

              <EmailButton emailSelected={emailSelected} sentEmail={true} />

              <table className="table table-stripped">
                <thead>
                  <tr>
                    <th style={{ width: '3%' }}></th>
                    {/* <th style={{ width: '3%' }}></th> */}
                    <th style={{ width: '10%' }}>Date</th>
                    <th style={{ width: '20%' }}>To</th>
                    <th>Message</th>
                  </tr>
                </thead>
                <tbody>
                  {sentEmails && sentEmails.map(email => (
                    <tr key={email._id}>
                      <td>
                        <form>
                          <input type="checkbox" value={email._id} className="format-control" onClick={e => toggleSelectEmailHandler(e.target.value)} />
                        </form>
                      </td>
                      <td><Moment format="DD/MM/YYYY">{email.createdAt}</Moment></td>
                      <td>{email.toUser && email.toUser.firstName}</td>
                      <td>{truncate(email.text, 300)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmailListPage
