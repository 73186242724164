export const isOperator = (building, userInfo) => dispatch => {
  if (building) {
    // building.user._id because operator is populatedalready
    const operator = building.operators && building.operators.some(operator => operator.user._id === userInfo._id)
    const owner = building.user === userInfo._id;

    if (userInfo.role === 'admin' || operator || owner) {
      return true;
    } else {
      return false;
    }
  }
}

export const isUserBanned = (building, user) => dispatch => {
  return building && building.bannedList && building.bannedList.some(item => item.user === user._id);
}

