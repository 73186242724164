import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../components/Spinner'
import { deniedBannedUser, getBuildingDetails } from '../actions/buildingActions'
import { activateBuilding, updateUserBuildingList } from '../actions/userActions'
import { USER_NEW_BUILDING_LIST_RESET, USER_ACTIVATE_RESET } from '../constants/userConstants'
import { BUILDING_CHECK_PENDING_RESET, BUILDING_CREATE_RESET } from '../constants/buildingConstants'



const WelcomePage = ({ history, match }) => {
  const dispatch = useDispatch();

  const buildingId = match.params.id;

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const userActivateBuilding = useSelector(state => state.userActivateBuilding);
  const { loading: loadingActivateBuilding } = userActivateBuilding;

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building, loading: loadingBuildingDetails } = buildingDetails;

  const userNewBuildingList = useSelector(state => state.userNewBuildingList);
  const { success: successNewBuildingList, loadingNewBuildingList } = userNewBuildingList;



  const activateBuildingHandler = async () => {
    await dispatch(updateUserBuildingList(buildingId));
    await dispatch(activateBuilding(buildingId));
  }


  useEffect(() => {
    dispatch(deniedBannedUser(building, userInfo, history));

    // user klick the activate button
    if (successNewBuildingList) {
      history.push('/posts');
      dispatch({ type: BUILDING_CREATE_RESET });
      dispatch({ type: BUILDING_CHECK_PENDING_RESET });
      dispatch({ type: USER_NEW_BUILDING_LIST_RESET });
      dispatch({ type: USER_ACTIVATE_RESET });
    }

    // the code comes from buildingDetails and if user reload the page but also if current building !== req.params
    if (!building || building._id !== buildingId) {
      dispatch(getBuildingDetails(buildingId));
    }

  }, [dispatch, building, userInfo, history, buildingId, successNewBuildingList])


  return (
    loadingActivateBuilding || loadingBuildingDetails || loadingNewBuildingList ? <Spinner /> :
      <div className="row text-center justify-content-center align-items-center" style={{ minHeight: '80vh', fontWeight: '600px' }}>
        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-6">
          <div>
            <h3 className="mb-3 text-primary">Hello {userInfo.firstName}. Welcome !</h3>
            <p className="lead text-success">
              You have successfully created a new channel for your group. To join your channel a new user will need this below code for the verification. You could find this code in your dashboard as well. Have a nice day !
            </p>
            <h2>{building && building.groupNumber}</h2>
            <p>
              <Link
                to="/posts"
                type="button"
                className="btn btn-primary text-light mt-4"
                onClick={activateBuildingHandler}
              >
                {`Activate >>`}
              </Link>
            </p>
          </div>
        </div>
      </div>
  )
}

export default WelcomePage
