import axios from 'axios';
import {
  BUILDING_CREATE_REQUEST,
  BUILDING_CREATE_SUCCESS,
  BUILDING_CREATE_FAIL,
  BUILDING_LIST_REQUEST,
  BUILDING_LIST_SUCCESS,
  BUILDING_LIST_FAIL,
  BUILDING_UPDATE_REQUEST,
  BUILDING_UPDATE_SUCCESS,
  BUILDING_UPDATE_FAIL,
  BUILDING_DETAILS_REQUEST,
  BUILDING_DETAILS_SUCCESS,
  BUILDING_DETAILS_FAIL,
  BUILDING_VERIFY_REQUEST,
  BUILDING_VERIFY_SUCCESS,
  BUILDING_VERIFY_FAIL,
  BUILDING_CHECK_PENDING_REQUEST,
  BUILDING_CHECK_PENDING_SUCCESS,
  BUILDING_CHECK_PENDING_FAIL,
  BUILDING_USERS_LIMIT_REQUEST,
  BUILDING_USERS_LIMIT_SUCCESS,
  BUILDING_USERS_LIMIT_FAIL,
  BUILDING_RULE_UPDATE_REQUEST,
  BUILDING_RULE_UPDATE_SUCCESS,
  BUILDING_RULE_UPDATE_FAIL,
  BUILDING_LOCATION_REQUEST,
  BUILDING_LOCATION_SUCCESS,
  BUILDING_LOCATION_FAIL,
  BUILDING_LOCATION_SERVICE_DETAILS_REQUEST,
  BUILDING_LOCATION_SERVICE_DETAILS_SUCCESS,
  BUILDING_LOCATION_SERVICE_DETAILS_FAIL,

} from '../constants/buildingConstants'
import { USER_LOGIN_SUCCESS } from '../constants/userConstants'


// export const leadIfUserBanned = (building, userInfo, history) => dispatch => {
//   const isBanned = building.bannedList.some(item => item.user === userInfo._id);
//   if (isBanned) {
//     history.push('/banned')
//   }
//   // if (building) {
//   //   return building.bannedList.some(item => item.user === userInfo._id);
//   // }
// }

export const deniedBannedUser = (building, userInfo, history) => dispatch => {
  const isBanned = building && building.bannedList && building.bannedList.some(item => item.user === userInfo._id);
  if (isBanned) {
    history.push('/access-denied');
  }
}

export const listBuildings = () => async (dispatch, getState) => {
  dispatch({ type: BUILDING_LIST_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get('/api/building', config);

    dispatch({
      type: BUILDING_LIST_SUCCESS,
      payload: data
    })

    localStorage.setItem('buildings', JSON.stringify(data));

  } catch (error) {
    dispatch({
      type: BUILDING_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const checkPendingBuilding = (history) => async (dispatch, getState) => {
  dispatch({ type: BUILDING_CHECK_PENDING_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`/api/building/pending`, config);

    dispatch({
      type: BUILDING_CHECK_PENDING_SUCCESS,
      payload: data
    })

    // localStorage.setItem('buildingPending', JSON.stringify(data));

    if (data !== null) {
      history.push(`/welcome/building/${data[0]._id}/activation`);
    }
  } catch (error) {
    dispatch({
      type: BUILDING_CHECK_PENDING_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const getPendingBuilding = (userId) => async (dispatch, getState) => {
  dispatch({ type: BUILDING_CHECK_PENDING_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`/api/building/pending/users/${userId}`, config);

    dispatch({
      type: BUILDING_CHECK_PENDING_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: BUILDING_CHECK_PENDING_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const getBuildingDetails = (id) => async (dispatch, getState) => {
  dispatch({ type: BUILDING_DETAILS_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const url = id ? `/api/building/${id}` : `/api/building/me`;

    const { data } = await axios.get(url, config);
    dispatch({
      type: BUILDING_DETAILS_SUCCESS,
      payload: data
    })

    localStorage.setItem('buildingDetails', JSON.stringify(data));


  } catch (error) {
    dispatch({
      type: BUILDING_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const createBuilding = (formData) => async (dispatch, getState) => {
  dispatch({ type: BUILDING_CREATE_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.post(`/api/building`, formData, config);

    dispatch({
      type: BUILDING_CREATE_SUCCESS,
      payload: data
    })


    // this helps if user reload the page, number won't gone
    localStorage.setItem('buildingDetails', JSON.stringify(data));


  } catch (error) {
    dispatch({
      type: BUILDING_CREATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

// add service and update building
export const updateBuilding = (id, formData, service = false) => async (dispatch, getState) => {
  dispatch({ type: BUILDING_UPDATE_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = service ? await axios.put(`/api/building/${id}/position/services`, formData, config) : await axios.put(`/api/building/${id}`, formData, config);

    dispatch({
      type: BUILDING_UPDATE_SUCCESS,
      payload: data
    })

    dispatch({
      type: BUILDING_DETAILS_SUCCESS,
      payload: data
    })

    localStorage.setItem('buildingDetails', JSON.stringify(data));


  } catch (error) {
    dispatch({
      type: BUILDING_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const updateBuildingService = (id, formData, serviceId) => async (dispatch, getState) => {
  dispatch({ type: BUILDING_UPDATE_REQUEST })

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/building/${id}/position/services/${serviceId}/update`, formData, config)

    dispatch({
      type: BUILDING_UPDATE_SUCCESS,
      payload: data
    })

    localStorage.setItem('buildingDetails', JSON.stringify(data));

    dispatch({
      type: BUILDING_DETAILS_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: BUILDING_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const deleteService = (id, serviceId) => async (dispatch, getState) => {
  dispatch({ type: BUILDING_UPDATE_REQUEST })

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/building/${id}/position/services/${serviceId}/delete`, {}, config);

    dispatch({
      type: BUILDING_UPDATE_SUCCESS,
      payload: data
    })

    localStorage.setItem('buildingDetails', JSON.stringify(data));

    dispatch({
      type: BUILDING_DETAILS_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: BUILDING_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}


export const verifyNumber = (number) => async (dispatch, getState) => {
  dispatch({ type: BUILDING_VERIFY_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/building/${number}/verify`, {}, config);

    dispatch({
      type: BUILDING_VERIFY_SUCCESS,
      payload: data
    })

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data
    })

    localStorage.setItem('userInfo', JSON.stringify(data));

    // Must update building details information after join another channel
    dispatch(getBuildingDetails());

  } catch (error) {
    dispatch({
      type: BUILDING_VERIFY_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
    // if (`${error.response.data.message}`.includes('to ObjectId failed')) {
    //   // in case no number entered
    //   dispatch({
    //     type: BUILDING_VERIFY_FAIL,
    //     payload: 'Please enter your code !'
    //   })
    // } else {
    //   dispatch({
    //     type: BUILDING_VERIFY_FAIL,
    //     payload: error.response && error.response.data.message ? error.response.data.message : error.message
    //   })

    // }

    document.querySelector('.code-container').addEventListener('click', e => {
      window.location.reload(1);
    })
  }
}

export const setMaximumUsers = (buildingId, formData) => async (dispatch, getState) => {
  dispatch({ type: BUILDING_USERS_LIMIT_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/building/${buildingId}/users/limit`, formData, config);

    dispatch({
      type: BUILDING_USERS_LIMIT_SUCCESS,
      payload: data
    });

    dispatch({
      type: BUILDING_DETAILS_SUCCESS,
      payload: data
    });

    localStorage.setItem('buildingDetails', JSON.stringify(data));

  } catch (error) {
    dispatch({
      type: BUILDING_USERS_LIMIT_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const updateGeneralRule = (id, formData) => async (dispatch, getState) => {
  dispatch({ type: BUILDING_RULE_UPDATE_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    const { data } = await axios.put(`/api/building/${id}/regulation`, formData, config);
    dispatch({
      type: BUILDING_RULE_UPDATE_SUCCESS,
      payload: data
    })

    dispatch({
      type: BUILDING_DETAILS_SUCCESS,
      payload: data
    })

    localStorage.setItem('buildingDetails', JSON.stringify(data));

  } catch (error) {
    dispatch({
      type: BUILDING_RULE_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const findMyLocation = (lng, lat) => async (dispatch, getState) => {
  dispatch({ type: BUILDING_LOCATION_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`/api/building/${lng}/${lat}`, config);

    dispatch({
      type: BUILDING_LOCATION_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: BUILDING_LOCATION_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const hideExpensePage = (id) => async (dispatch, getState) => {
  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/building/${id}/expense-page`, {}, config)

    dispatch({
      type: BUILDING_UPDATE_SUCCESS,
      payload: data
    })

    dispatch(getBuildingDetails(id))
  } catch (error) {
    dispatch({
      type: BUILDING_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const getServiceDetails = (id, serviceId) => async (dispatch, getState) => {
  dispatch({ type: BUILDING_LOCATION_SERVICE_DETAILS_REQUEST })

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`/api/building/${id}/position/services/${serviceId}`, config);

    dispatch({
      type: BUILDING_LOCATION_SERVICE_DETAILS_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: BUILDING_LOCATION_SERVICE_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}


