import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from '../../components/Spinner'
import { setMaximumUsers } from '../../actions/buildingActions'


const MainDashboard = ({ history }) => {
  const [maxUser, setMaxUser] = useState('');

  const dispatch = useDispatch();

  // userInfo must come from here and not from props because cannot read the groupNumber below
  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building, loading: loadingBuildingDetails } = buildingDetails;

  const buildingCheckPending = useSelector(state => state.buildingCheckPending);
  const { building: buildingPending } = buildingCheckPending;

  const buildingUsersLimit = useSelector(state => state.buildingUsersLimit);
  const { loading: loadingBuildingUsersLimit } = buildingUsersLimit;



  useEffect(() => {

    // if (userInfo) {
    //   dispatch(getPendingBuilding(userInfo._id));
    // }

    if (userInfo && userInfo.isGuest) {
      history.push('/')
    }

    if (building) {
      setMaxUser(building.maxUsers)
    }

  }, [dispatch, userInfo, building, history])

  const submitHandler = e => {
    e.preventDefault();

    dispatch(setMaximumUsers(building._id, { maxUser }));
  }


  return (
    loadingBuildingDetails ? <Spinner /> :
      <>
        <div className="jumbotron">
          <h1 className="display-5">General</h1>
          <div className="my-5">

            <Link to="/verify-number" className="btn btn-info mr-3">
              <i className="fas fa-users"></i>&nbsp;
              Join Group
            </Link>



            <Link to="new-community" className={`btn btn-info mr-3 my-3 ${userInfo.role !== 'admin' ? 'disabled' : ''}`}>
              <i className="fas fa-building"></i>&nbsp;
              Create Group</Link>


            {/* Form to limit users amount */}
            <form onSubmit={submitHandler} className="form-inline float-right my-3" >
              <label>Max.&nbsp; <i className="fas fa-users text-info"></i></label>&nbsp;
              <input
                type="number"
                name="maxUser"
                value={maxUser}
                disabled={userInfo.role !== 'admin' && building.user !== userInfo._id && true}
                className="form-control w-25 mr-2"
                onChange={(e) => setMaxUser(e.target.value)} />

              <input
                type="submit"
                value={loadingBuildingUsersLimit ? 'Saving...' : 'Save'}
                className="btn btn-primary"
                disabled={userInfo.role !== 'admin' && building.user !== userInfo._id && true}
              />
            </form>
          </div>

          <div className="clearfix"></div>



          {buildingPending && (
            <>
              {buildingPending.length > 0 && buildingPending.map(building => (
                <>
                  <p className="lead">You have a Pending channel:</p>
                  <Link to={`/welcome/building/${building._id}/activation`}>
                    {building.group}
                  </Link>
                </>
              ))}
            </>
          )}
          <hr className="my-4" />
          <p className="lead">
            {userInfo._id === building.user ? (
              <>
                <span>Code of this channel :</span>
                <h4>{building.groupNumber}</h4>
                <small className="text-info">To invite a user to join your channel, you need to inform him this code</small>
              </>
            ) : (
              <small className="text-danger">*No code means either no own created room or you are now active in other room.</small>
            )}
          </p>
        </div>
      </>
  )
}

export default withRouter(MainDashboard)
