import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import Paginate from '../../components/Paginate'
import { getAllPosts } from '../../actions/adminAction'
import PostItem from '../../components/admin/PostItem.js'




const AdminPostList = ({ userInfo, pageNumber, building }) => {
  const dispatch = useDispatch();

  const postList = useSelector(state => state.postList);
  const { posts } = postList;

  useEffect(() => {
    dispatch(getAllPosts(pageNumber));
  }, [dispatch, pageNumber])


  return (
    <div className="content">
      {/* // <!-- ALL POSTS TABLE LISTS --> */}
      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>Date</th>
              <th>Title</th>
              <th>Category</th>
              <th>Group</th>
              <th>CreatedAt</th>
              <th>Images</th>
              <th><i className="fas fa-star" style={{ color: '#ffd27d' }}></i></th>
              <th><i className="fas fa-map-pin fa-lg"></i></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {posts && posts.map(post => (
              <PostItem key={post._id} post={post} />
            ))}
          </tbody>
        </table>
        {/* Pagination */}
        {/* <Paginate page={page} pages={pages} isDahsPostList={true} /> */}
      </div>
    </div>
  )
}



export default AdminPostList
