import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import Alert from '../../components/Alert'
import Spinner from '../../components/Alert'
import { requestForgotPassword } from '../../actions/userActions'
import { setAlert } from '../../actions/alertActions'



const ForgotPasswordPage = ({ history }) => {
  const [recaptcha, setRecaptcha] = useState(false)
  const [email, setEmail] = useState('');

  const dispatch = useDispatch();

  const userForgotPassword = useSelector(state => state.userForgotPassword);
  const { token, error, loading } = userForgotPassword;

  const alertAction = useSelector(state => state.alertAction);
  const { alert } = alertAction;

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, 'danger'));
    }

    if (token) {
      history.push(`/security-check/${token}/iOjE2MjQ5Njk0NDB9`);
    }

  }, [dispatch, history, token, error])

  const recaptchaHandler = () => {
    setRecaptcha(!recaptcha)
  }


  const submitHandler = e => {
    e.preventDefault();
    dispatch(requestForgotPassword({ email }));
  }


  return (
    loading ? <Spinner /> :
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">

            {/* {error && <Alert msg={error} />} */}
            {alert && <Alert msg={error} />}

            <form onSubmit={submitHandler}>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Enter your login email"
                  className="form-control mb-4"
                  required
                  onChange={e => setEmail(e.target.value)} />
              </div>

              <ReCAPTCHA
                sitekey="6Le9PwwaAAAAAGEIbNl1dlPplzYhZ9JfshYJo8WG"
                onChange={recaptchaHandler}
              />

              <input type="submit" value="Submit" className="btn btn-primary btn-block mt-4" disabled={!recaptcha} />
            </form>
          </div>
        </div>
      </div>

  )
}

export default ForgotPasswordPage
