import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../components/Spinner'
import { registerUser } from '../actions/userActions'
import { setAlert } from '../actions/alertActions'
import Alert from '../components/Alert'
import { USER_REGISTER_RESET } from '../constants/userConstants'
import Recaptcha from '../components/Recaptcha'

// updated


const RegisterPage = ({ history }) => {
  const [recaptcha, setRecaptcha] = useState(false)
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  })

  const { firstName, lastName, email, password, confirmPassword } = formData;

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const userRegister = useSelector(state => state.userRegister);
  const { success: successRegister, error, loading } = userRegister;

  const alertAction = useSelector(state => state.alertAction);
  const { alert } = alertAction;

  const recaptchaHandler = () => {
    setRecaptcha(true)
  }


  useEffect(() => {
    if (userInfo && successRegister) {
      history.push('/security-password');
      dispatch({ type: USER_REGISTER_RESET })
    }

    if (error) {
      dispatch(setAlert(error))
      setRecaptcha(false)
      dispatch({ type: USER_REGISTER_RESET })
    }

  }, [dispatch, userInfo, history, successRegister, error])

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });


  const onSubmit = e => {
    e.preventDefault();

    if (!firstName || !lastName || !email) {
      return dispatch(setAlert('Please complete the form!', 'danger'));
    } else if (password !== confirmPassword) {
      return dispatch(setAlert('Password does not match', 'danger'));
    }

    // dispatch(registerUser({ firstName, lastName, email, password }));
    dispatch(registerUser(formData));
  }


  return loading ? <Spinner /> :
    <div className="row">
      <div className="col-md">
        <div className="w-75" style={{ position: "relative", margin: 'auto', marginTop: '120px' }}>
        </div>
        <div className="container" style={{ maxWidth: '600px' }}>
          <h3 className="text-info text-center mb-5">Sign Up</h3>
          {/* {error && <Alert msg={error} />} */}
          {alert && <Alert msg={alert.msg} />}
          <form className="form-group" onSubmit={onSubmit}>
            <div className="form-group">
              <small>Firstname</small>
              <input type="text" className="form-control form-control-lg" value={firstName} name="firstName" onChange={onChange} />
            </div>
            <div className="form-group">
              <small>Lastname</small>
              <input type="text" className="form-control form-control-lg" value={lastName} name="lastName" onChange={onChange} />
            </div>
            <div className="form-group">
              <small>Email</small>
              <input type="text" className="form-control form-control-lg" value={email} name="email" onChange={onChange} />
            </div>
            <div className="form-group">
              <small>Password</small>
              <input type="password" className="form-control form-control-lg" value={password} name="password" onChange={onChange} />
            </div>
            <div className="form-group">
              <small>Confirm Password</small>
              <input type="password" className="form-control form-control-lg mb-4" value={confirmPassword} name="confirmPassword" onChange={onChange} />
            </div>

            {/* Recaptcha */}
            <Recaptcha recaptchaHandler={recaptchaHandler} />

            <input type="submit" value="Register" className="btn btn-primary mt-4" disabled={!recaptcha} />
          </form>
          <div className="mt-3">
            <p>Already have account ?
              <Link to="/login">
                <span className="text-primary">{' '}Login</span>
              </Link>
            </p>
            <p>Forgot your password ?
              <Link to="/forgot-password">
                <span className="text-primary">{' '}Reset password</span>
              </Link>
            </p>
          </div>
        </div >
      </div>

    </div>
}

export default RegisterPage
