import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import Alert from '../../components/Alert'
import Spinner from '../../components/Spinner'
import { logout, resetPassword } from '../../actions/userActions'

const ResetPasswordPage = ({ history, match }) => {
  const [recaptcha, setRecaptcha] = useState(false)
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: ''
  })

  const { newPassword, confirmPassword } = formData;

  const dispatch = useDispatch();

  const userSecurityCheck = useSelector(state => state.userSecurityCheck);
  const { _id: userId, token: tokenSecurityCheck } = userSecurityCheck;


  const userPasswordReset = useSelector(state => state.userPasswordReset);
  const { success, error, loading } = userPasswordReset;

  const recaptchaHandler = () => {
    setRecaptcha(true)
  }


  useEffect(() => {
    if (error === 'Authorization token is invalid !') {
      setTimeout(() => {
        history.push('/login');
        dispatch(logout());
      }, 3000)
    }

    // no token in state of userPasswordReset, than no entry. Even with copy paste url to reset password page
    // state can only be filled token if answer correct than will be redirect
    if (!tokenSecurityCheck) {
      history.push('/login');
    }


    if (success) {
      setTimeout(() => {
        history.push('/login')
        dispatch(logout());
      }, 4000)
    }
  }, [dispatch, tokenSecurityCheck, error, history, success])


  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const submitHandler = e => {
    e.preventDefault();
    dispatch(resetPassword(userId, tokenSecurityCheck, formData));
  }
  return (
    loading || !tokenSecurityCheck ? <Spinner /> :
      <div className="container">
        <div className="row justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
          <div className="col-sm-6 col-md-6 col-lg-5 col-xl-5">
            {error && <Alert msg={error} />}
            {success ? (
              <>
                <div className="text-center">
                  <h3 className="lead">Congratulation, password has been updated !</h3>
                  <p className="lead">Please use this new password for your future login.</p>
                  <p className="lead">Redirecting....</p>
                  <i className="fas fa-check-circle fa-4x"></i>
                </div>
              </>
            ) : (
              <form className="form-group" onSubmit={submitHandler}>
                <div className="form-group">
                  <small>New password</small>
                  <input type="password" className="form-control form-control-lg" value={newPassword} name="newPassword" onChange={onChange} />
                </div>
                <div className="form-group">
                  <small>Confirm password</small>
                  <input type="password" className="form-control form-control-lg mb-5" value={confirmPassword} name="confirmPassword" onChange={onChange} />
                </div>

                <ReCAPTCHA
                  sitekey="6Le9PwwaAAAAAGEIbNl1dlPplzYhZ9JfshYJo8WG"
                  onChange={recaptchaHandler}
                />,

                <input type="submit" value="Update password" className="btn btn-primary mt-4" disabled={!recaptcha} />
              </form>
            )}
          </div>
        </div>
      </div>
  )
}

export default ResetPasswordPage
