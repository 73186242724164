import React from 'react'
import { useDispatch } from 'react-redux'
import Moment from 'react-moment'
import { truncate } from '../../helper/utils'
import { getVideoDetails, removeVideo } from '../../actions/videoActions'

const VideoItem = ({ video }) => {
  const dispatch = useDispatch()

  return (
    <>
      <tr>
        {/* <th scope="row">1</th> */}
        <td><Moment format="DD-MM-YYYY">{video.createdAt}</Moment></td>
        <td>{truncate(video.image_url, 20)}</td>
        <td>{truncate(video.video_url, 20)}</td>
        <td>
          <button className='btn btn-primary btn-sm' onClick={() => dispatch(getVideoDetails(video._id))}>
            <i className="fas fa-edit"></i>
          </button> &nbsp;
          <button className={`btn btn-danger btn-sm ${video.isHide ? 'bg-secondary' : ''}`} onClick={() => dispatch(removeVideo(video._id))}>
            {video.isHide ? (
              <i className="fa-solid fa-eye-slash"></i>
            ) : (
              <i className="fa-solid fa-eye"></i>
            )}
          </button>
        </td>
      </tr>
    </>
  )
}

export default VideoItem