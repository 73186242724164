import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../components/Spinner'
import UserItem from '../../components/users/UserItem'
import { listUsers } from '../../actions/userActions'
import Paginate from '../../components/Paginate'


// pages/dashboard/DashboardPage.js
const UserListPage = ({ isAdminPage, building, pageNumber, history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);
  const { loading } = userLogin;

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { loading: loadingBuildingDetails } = buildingDetails;

  // not from DashboardPage because the list comes by klicking the users navbar
  const userList = useSelector(state => state.userList);
  const { users, page, pages, loading: loadingUserList } = userList;


  useEffect(() => {
    if (building) {
      dispatch(listUsers(pageNumber, building._id));
    }
  }, [dispatch, pageNumber, building])  // if building include in dependency, the page userlist refreshed while adding new author

  return (
    // need loadingBuildingDetails -> cannot read property user._id (switch building)
    loading || loadingUserList || loadingBuildingDetails ? <Spinner /> :
      <>
        <div className="mb-3">
          <h5>
            {/* minus 1 because not included admin */}
            Users: {users && users.length > 0 ? users.length - 1 : 0} <i className="fas fa-users text-info"></i>&nbsp;
          </h5>
        </div>

        <Link to="/dashboard">
          <small>{building.maxUsers} users allowed</small>
        </Link>
        <div className="table-responsive">
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th></th>
                <th>Firstname</th>
                <th>Lastname</th>
                <th>Haus</th>
                <th>Email</th>
                <th></th>
                <th>Last Login</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users && users.map((user, index) => (
                <UserItem
                  key={user._id}
                  user={user}
                  building={building}
                  isAdminPage={isAdminPage}
                  index={index}
                  loading={loading}
                />
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div>
            <Paginate page={page} pages={pages} isDashUserList={true} />
          </div>
        </div>
      </>
  )
}

export default UserListPage
