import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import { isOperator, isUserBanned } from '../../actions/authActions'
import { addUserasAuthor, banUser } from '../../actions/userActions'




// pages/dashboard/UserListPage.js
// pages/admin/UserListPage.js
const UserItem = ({ user, building, isAdminPage, index, loading }) => {

  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;


  useEffect(() => {
    const markUserAsBanned = () => {
      let button = document.querySelector(`.btnBanUser-${index}`);
      // if (building.banneList && building.bannedList.length) {
      // item comes from [] building details & user._id from props map
      building && building.bannedList.map(item => {
        if (item.user === user._id) {
          button && button.classList.add('text-danger');
        }
      })
      // }
    }


    const markUserAsOperator = () => {
      const button = document.querySelector(`.btnNewAuthor-${index}`);
      if (building && building.operators && building.operators.length) {
        // item comes from [] building.operators & user._id from props map
        building && building.operators.map(item => {
          if (item.user._id === user._id) {  // item.user._id because populated already
            button && button.classList.add('text-success');
          }
        })
      }
    }


    markUserAsBanned();
    markUserAsOperator();
  }, [building, index, user]);


  const addNewAuthorHandler = (index, userId) => {
    const button = document.querySelector(`.btnNewAuthor-${index}`);
    if (button.classList.contains('text-success')) {
      // console.log('disable')
      button.classList.remove('text-success');
    } else {
      // console.log('enable')
      button.classList.add('text-success');
    }

    dispatch(addUserasAuthor(building._id, userId));

  }

  const banUserHandler = (index, userId) => {
    const button = document.querySelector(`.btnBanUser-${index}`);
    if (button.classList.contains('text-danger')) {
      button.classList.remove('text-danger');
    } else {
      button.classList.add('text-danger')
    }

    dispatch(banUser(building._id, userId))
  }

  const hideButAdminHandler = () => {
    if (userInfo.role === 'admin' || userInfo._id === building.user) {
      return false
    } else {
      // hide button
      return true
    }
  }

  return (
    // to hide admin appear in user's list
    user && user.role !== 'admin' &&
    <>
      <tr key={user._id}>
        <td>
          <img src={`${user.avatar.url}`} alt={user.firstName} className="rounded-circle" style={{ width: '40px', height: '40px' }} />
        </td>
        <td>{user.firstName}</td>
        <td>{user.lastName}</td>
        <td>{user.houseNumber}</td>
        <td>
          {isAdminPage ? (
            user.email
          ) : (
            user.email.replace(/(.{2})(.*)(?=@)/, '$1***')
          )}
        </td>
        {isAdminPage && (
          <>
            <td>{user.username}</td>
            <td>{user.community}</td>
            <td><Moment format="DD/MM/YYYY">{user.createdAt}</Moment></td>
            <td><Moment format="DD/MM/YYYY">{user.lastLogin}</Moment></td>
            <td>
              {user.social && user.social.facebook &&
                <a href={user.social.facebook}>
                  <i className="fab fa-facebook"></i>
                </a>
              }
              {user.social && user.social.twitter &&
                <a href={user.social.twitter}>
                  <i className="fab fa-twitter"></i>
                </a>
              }
            </td>
          </>
        )}
        <td style={{ minWidth: '50px' }}>
          {dispatch(isOperator(building, user)) && <i className="fas fa-user-tie text-muted"></i>}
          {dispatch(isUserBanned(building, user)) && <i className="fas fa-ban text-muted"></i>}
          {/**the user could come from dashboardPage or adminPage   */}
          {/* {user._id === building.user && <i className="fas fa-building"></i>} */}
        </td>
        {isAdminPage && (
          <td>
            <Link to={`/admin/users/${user._id}/${user.firstName}/edit`}>
              <button className="btn btn-light btn-sm">
                <i className="fas fa-cog text-danger"></i>
              </button>
            </Link>
          </td>
        )}
        {/* admin shouldn't have these both button */}
        {/* {userInfo.role !== 'admin' && ( */}
        <td>
          <small>{<Moment format='DD/MM/YYYY'>{user.lastLogin}</Moment>}</small>
        </td>
        <td>

          {/* should not block myself or get rid myself from operator list*/}
          {/* {user._id !== building.user && ( */}
          <div>
            <button
              className={`btn btn-sm btn-light btnNewAuthor-${index}`}
              onClick={() => addNewAuthorHandler(index, user._id)}
              // hidden={userInfo._id !== building.user && true}  // hide btn if not owner
              hidden={hideButAdminHandler()}  // hide btn if not owner
            >
              <i className="fas fa-user-plus"></i>
            </button> {' '}
            <button
              onClick={() => banUserHandler(index, user._id)}
              className={`btn btn-light btn-sm btnBanUser-${index}`}
              // hidden={userInfo._id !== building.user && true} // hide btn if not owner
              hidden={hideButAdminHandler()} // hide btn if not owner
            >
              <i className="fas fa-ban"></i>
            </button>
          </div>
          {/* )} */}
        </td>
        {/* )} */}
      </tr>
    </>
  )
}


export default UserItem
