export const ADMIN_AUTH_REQUEST = 'ADMIN_AUTH_REQUEST';
export const ADMIN_AUTH_SUCCESS = 'ADMIN_AUTH_SUCCESS';
export const ADMIN_AUTH_FAIL = 'ADMIN_AUTH_FAIL';
export const ADMIN_AUTH_RESET = 'ADMIN_AUTH_RESET';

export const ADMIN_FULL_GRANTED_REQUEST = 'ADMIN_FULL_GRANTED_REQUEST';
export const ADMIN_FULL_GRANTED_SUCCESS = 'ADMIN_FULL_GRANTED_SUCCESS';
export const ADMIN_FULL_GRANTED_FAIL = 'ADMIN_FULL_GRANTED_FAIL';
export const ADMIN_FULL_GRANTED_RESET = 'ADMIN_FULL_GRANTED_RESET';

export const ADMIN_POST_DELETE_REQUEST = 'ADMIN_POST_DELETE_REQUEST';
export const ADMIN_POST_DELETE_SUCCESS = 'ADMIN_POST_DELETE_SUCCESS';
export const ADMIN_POST_DELETE_FAIL = 'ADMIN_POST_DELETE_FAIL';

export const ADMIN_EMAIL_LIST_REQUEST = 'ADMIN_EMAIL_LIST_REQUEST';
export const ADMIN_EMAIL_LIST_SUCCESS = 'ADMIN_EMAIL_LIST_SUCCESS';
export const ADMIN_EMAIL_LIST_FAIL = 'ADMIN_EMAIL_LIST_FAIL';
export const ADMIN_EMAIL_LIST_RESET = 'ADMIN_EMAIL_LIST_RESET';

export const ADMIN_EMAIL_DETAILS_REQUEST = 'ADMIN_EMAIL_DETAILS_REQUEST';
export const ADMIN_EMAIL_DETAILS_SUCCESS = 'ADMIN_EMAIL_DETAILS_SUCCESS';
export const ADMIN_EMAIL_DETAILS_FAIL = 'ADMIN_EMAIL_DETAILS_FAIL';
export const ADMIN_EMAIL_DETAILS_RESET = 'ADMIN_EMAIL_DETAILS_RESET';

export const ADMIN_EMAIL_RESPONSE_REQUEST = 'ADMIN_EMAIL_RESPONSE_REQUEST';
export const ADMIN_EMAIL_RESPONSE_SUCCESS = 'ADMIN_EMAIL_RESPONSE_SUCCESS';
export const ADMIN_EMAIL_RESPONSE_FAIL = 'ADMIN_EMAIL_RESPONSE_FAIL';
export const ADMIN_EMAIL_RESPONSE_RESET = 'ADMIN_EMAIL_RESPONSE_RESET';

export const ADMIN_EMAIL_READ_SUCCESS = 'ADMIN_EMAIL_READ_SUCCESS';
export const ADMIN_EMAIL_READ_FAIL = 'ADMIN_EMAIL_READ_FAIL';
export const ADMIN_EMAIL_READ_RESET = 'ADMIN_EMAIL_READ_RESET';

export const ADMIN_EMAIL_TICKET_LIST_REQUEST = 'ADMIN_EMAIL_TICKET_LIST_REQUEST';
export const ADMIN_EMAIL_TICKET_LIST_SUCCESS = 'ADMIN_EMAIL_TICKET_LIST_SUCCESS';
export const ADMIN_EMAIL_TICKET_LIST_FAIL = 'ADMIN_EMAIL_TICKET_LIST_FAIL';
export const ADMIN_EMAIL_TICKET_LIST_RESET = 'ADMIN_EMAIL_TICKET_LIST_RESET';
