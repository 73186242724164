export const BUILDING_CREATE_REQUEST = 'BUILDING_CREATE_REQUEST';
export const BUILDING_CREATE_SUCCESS = 'BUILDING_CREATE_SUCCESS';
export const BUILDING_CREATE_FAIL = 'BUILDING_CREATE_FAIL';
export const BUILDING_CREATE_RESET = 'BUILDING_CREATE_RESET';

export const BUILDING_DETAILS_REQUEST = 'BUILDING_DETAILS_REQUEST';
export const BUILDING_DETAILS_SUCCESS = 'BUILDING_DETAILS_SUCCESS';
export const BUILDING_DETAILS_FAIL = 'BUILDING_DETAILS_FAIL';
export const BUILDING_DETAILS_RESET = 'BUILDING_DETAILS_RESET';

export const BUILDING_UPDATE_REQUEST = 'BUILDING_UPDATE_REQUEST';
export const BUILDING_UPDATE_SUCCESS = 'BUILDING_UPDATE_SUCCESS';
export const BUILDING_UPDATE_FAIL = 'BUILDING_UPDATE_FAIL';
export const BUILDING_UPDATE_RESET = 'BUILDING_UPDATE_RESET';

export const BUILDING_RULE_UPDATE_REQUEST = 'BUILDING_RULE_UPDATE_REQUEST';
export const BUILDING_RULE_UPDATE_SUCCESS = 'BUILDING_RULE_UPDATE_SUCCESS';
export const BUILDING_RULE_UPDATE_FAIL = 'BUILDING_RULE_UPDATE_FAIL';
export const BUILDING_RULE_UPDATE_RESET = 'BUILDING_RULE_UPDATE_RESET';

export const BUILDING_VERIFY_REQUEST = 'BUILDING_VERIFY_REQUEST';
export const BUILDING_VERIFY_SUCCESS = 'BUILDING_VERIFY_SUCCESS';
export const BUILDING_VERIFY_FAIL = 'BUILDING_VERIFY_FAIL';
export const BUILDING_VERIFY_RESET = 'BUILDING_VERIFY_RESET';

export const BUILDING_LIST_REQUEST = 'BUILDING_LIST_REQUEST';
export const BUILDING_LIST_SUCCESS = 'BUILDING_LIST_SUCCESS';
export const BUILDING_LIST_FAIL = 'BUILDING_LIST_FAIL';
export const BUILDING_LIST_RESET = 'BUILDING_LIST_RESET';

export const BUILDING_CHECK_PENDING_REQUEST = 'BUILDING_CHECK_PENDING_REQUEST';
export const BUILDING_CHECK_PENDING_SUCCESS = 'BUILDING_CHECK_PENDING_SUCCESS';
export const BUILDING_CHECK_PENDING_FAIL = 'BUILDING_CHECK_PENDING_FAIL';
export const BUILDING_CHECK_PENDING_RESET = 'BUILDING_CHECK_PENDING_RESET';

export const BUILDING_USERS_LIMIT_REQUEST = 'BUILDING_USERS_LIMIT_REQUEST';
export const BUILDING_USERS_LIMIT_SUCCESS = 'BUILDING_USERS_LIMIT_SUCCESS';
export const BUILDING_USERS_LIMIT_FAIL = 'BUILDING_USERS_LIMIT_FAIL';
export const BUILDING_USERS_LIMIT_RESET = 'BUILDING_USERS_LIMIT_RESET';

export const BUILDING_LOCATION_REQUEST = 'BUILDING_LOCATION_REQUEST';
export const BUILDING_LOCATION_SUCCESS = 'BUILDING_LOCATION_SUCCESS';
export const BUILDING_LOCATION_FAIL = 'BUILDING_LOCATION_FAIL';
export const BUILDING_LOCATION_RESET = 'BUILDING_LOCATION_RESET';

export const BUILDING_LOCATION_SERVICE_REQUEST = 'BUILDING_LOCATION_SERVICE_REQUEST';
export const BUILDING_LOCATION_SERVICE_SUCCESS = 'BUILDING_LOCATION_SERVICE_SUCCESS';
export const BUILDING_LOCATION_SERVICE_FAIL = 'BUILDING_LOCATION_SERVICE_FAIL';

export const BUILDING_LOCATION_SERVICE_DETAILS_REQUEST = 'BUILDING_LOCATION_SERVICE_DETAILS_REQUEST';
export const BUILDING_LOCATION_SERVICE_DETAILS_SUCCESS = 'BUILDING_LOCATION_SERVICE_DETAILS_SUCCESS';
export const BUILDING_LOCATION_SERVICE_DETAILS_FAIL = 'BUILDING_LOCATION_SERVICE_DETAILS_FAIL';
export const BUILDING_LOCATION_SERVICE_DETAILS_RESET = 'BUILDING_LOCATION_SERVICE_DETAILS_RESET';
