import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Alert from '../../components/Alert'
import Spinner from '../../components/Spinner'
import { updateBuilding, updateBuildingService } from '../../actions/buildingActions'
import { setAlert } from '../../actions/alertActions'
import ServiceItem from '../../components/services/ServiceItem'
import { BUILDING_LOCATION_SERVICE_DETAILS_RESET } from '../../constants/buildingConstants'


const ServiceForm = ({ building, userInfo, loading, history }) => {
  const [formData, setFormData] = useState({
    title: '',
    call1: '',
    call2: '',
    call3: '',
    lineId: ''
  });

  const { title, call1, call2, call3, lineId } = formData;

  const dispatch = useDispatch();

  const buildingUpdate = useSelector(state => state.buildingUpdate);
  const { success } = buildingUpdate;

  const buildingServiceDetails = useSelector(state => state.buildingServiceDetails);
  const { service, loading: loadingBuildingServiceDetails } = buildingServiceDetails;

  const alertAction = useSelector(state => state.alertAction);
  const { alert } = alertAction;

  useEffect(() => {
    if (service) {
      setFormData({
        title: loadingBuildingServiceDetails || !service.title ? '' : service.title,
        call1: loadingBuildingServiceDetails || !service.call1 ? '' : service.call1,
        call2: loadingBuildingServiceDetails || !service.call2 ? '' : service.call2,
        call3: loadingBuildingServiceDetails || !service.call3 ? '' : service.call3,
        lineId: loadingBuildingServiceDetails || !service.lineId ? '' : service.lineId,
      })
    }

    if (success) {
      setFormData({
        title: '',
        call1: '',
        call2: '',
        call3: '',
        lineId: ''
      })
    }
  }, [success, service, loadingBuildingServiceDetails])


  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });


  const onSubmitHandler = e => {
    e.preventDefault();

    if (title === '') {
      dispatch(setAlert('Please select title!', 'danger'))
    } else {
      if (service) {
        dispatch(updateBuildingService(building._id, formData, service._id))
      } else {
        dispatch(updateBuilding(building._id, formData, true))
      }

      dispatch({ type: BUILDING_LOCATION_SERVICE_DETAILS_RESET })
    }
  }

  return (
    loading ? <Spinner /> :
      <>
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-10 col-xl-10">
            <div className='text-center mt-3'>
              {alert && <Alert msg={alert.msg} type={alert.type} />}
            </div>

            <form className="mt-3" onSubmit={onSubmitHandler}>
              <small>Title</small>
              <select className="form-control mb-3" name="title" value={title} onChange={onChange}>
                <option className='text-muted'>Select Service</option>
                <option value="Fire Brigade">Fire Brigade</option>
                <option value="Police Station">Police Station</option>
                <option value="Water (PWA)">Water (PWA)</option>
                <option value="Electricity">Electricity</option>
                <option value="Rescue">Rescue</option>
              </select>
              <div className="form-row mt-4">
                <div className="col-md-6">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fa-solid fa-square-phone-flip fa-2x text-primary"></i>
                      </div>
                    </div>
                    <input type="text" name="call1" value={call1} className="form-control form-control-lg" id="inlineFormInputGroup" placeholder="Call1" onChange={onChange} />
                  </div>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fa-solid fa-square-phone-flip fa-2x text-primary"></i>
                      </div>
                    </div>
                    <input type="text" name="call2" value={call2} className="form-control form-control-lg" id="inlineFormInputGroup" placeholder="Call2" onChange={onChange} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fa-solid fa-square-phone-flip fa-2x text-primary"></i>
                      </div>
                    </div>
                    <input type="text" name="call3" value={call3} className="form-control form-control-lg" id="inlineFormInputGroup" placeholder="Call3" onChange={onChange} />
                  </div>

                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fa-brands fa-line fa-2x text-success"></i>
                      </div>
                    </div>
                    <input type="text" name="lineId" value={lineId} className="form-control form-control-lg" id="inlineFormInputGroup" placeholder="LineID" onChange={onChange} />
                  </div>
                </div>
              </div>
              <input type="submit" value={service ? 'Update' : 'Save'} className="btn btn-primary mb-4 float-right" />
            </form>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-10 col-xl-10">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Services</th>
                  <th scope="col">Telephone</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {building !== undefined && building.position.services.map(item => (
                  <ServiceItem
                    key={item._id}
                    item={item}
                    building={building}
                    userInfo={userInfo}
                    dashboardPage={true}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
  )
}

export default ServiceForm
