import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../components/Spinner'
import Moment from 'react-moment'
import { capitalizeFirst, truncate } from '../helper/utils'
import { getPostDetails, listVotesPost } from '../actions/postActions'
import { deniedBannedUser } from '../actions/buildingActions'

const VoteListPage = ({ match, history }) => {
  const dispatch = useDispatch();

  const postId = match.params.id;

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building } = buildingDetails;

  const postDetails = useSelector(state => state.postDetails);
  const { post, loading } = postDetails;

  const postVoteList = useSelector(state => state.postVoteList);
  const { votes } = postVoteList;

  useEffect(() => {
    dispatch(deniedBannedUser(building, userInfo, history));

    dispatch(getPostDetails(postId, history));  // history push user from diffenren channel
    dispatch(listVotesPost(postId));
  }, [dispatch, building, userInfo, history, postId])

  const calculateNumOptions = (votes) => {
    let option1 = 0;
    let option2 = 0;
    let option3 = 0;
    let option4 = 0;

    const options = [];

    if (votes) {
      votes.map(vote => {
        if (vote.option === 1) {
          option1 += 1;
        } else if (vote.option === 2) {
          option2 += 1;
        } else if (vote.option === 3) {
          option3 += 1;
        } else if (vote.option === 4) {
          option4 += 1;
        }
      })
    }

    options.push(option1);
    options.push(option2);
    options.push(option3);
    options.push(option4);

    return options;

  }


  return (
    loading || !post ? <Spinner /> :  // !post helps to solve not found numAgree problem  
      <div className="Row content">
        <div className="col-md">
          <button className="btn btn-primary mb-4">
            <Link to={`/posts/${post._id}/comments`} className="text-light" style={{ textDecoration: 'none' }}>
              <i className="fas fa-angle-double-left"></i> Go Back
            </Link>
          </button>

        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="my-3 text-muted">
              <p className="mr-4">Option 1 = <span className="fa-lg">{calculateNumOptions(votes)[0]}</span> <i className="fas fa-street-view fa-lg"></i></p>
              <p className="mr-4">Option 2 = <span className="fa-lg">{calculateNumOptions(votes)[1]}</span> <i className="fas fa-street-view fa-lg"></i></p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="my-3 text-muted">
              <p className="mr-4">Option 3 = <span className="fa-lg">{calculateNumOptions(votes)[2]}</span> <i className="fas fa-street-view fa-lg"></i></p>
              <p className="mr-4">Option 4 = <span className="fa-lg">{calculateNumOptions(votes)[3]}</span> <i className="fas fa-street-view fa-lg"></i></p>
            </div>
          </div>
        </div>
        <div className="my-4">
          <span className="text-muted">
            Agree:{' '} <span className="font-weight-bold text-success">{post.numAgree}</span>
          </span>
          <span className="text-muted mx-3">
            Disagree:{' '} <span className="font-weight-bold text-danger">{post.numVotes - post.numAgree}</span>
          </span>
          <span className="text-muted">
            Voters:{' '} <span className="font-weight-bold">{post.numVotes}</span>
          </span>
        </div>
        <div className="mt-4">
          <small className="mr-3 font-weight-bold">Start: <Moment format="DD/MM/YYYY">{post && post.startVote}</Moment> </small>
          <small className="font-weight-bold">Final: <Moment format="DD/MM/YYYY">{post && post.finalVote}</Moment></small>
        </div>
        <div className="table-responsive mt-2">
          <table className="table table-striped table-sm border">
            <thead>
              <tr>
                <th></th>
                <th>Date</th>
                <th>House Number</th>
                <th>Option</th>
                <th>Vote</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {votes.map(vote => (
                <tr key={vote._id} className={vote.user._id === userInfo._id && 'table-info'}>
                  <td>
                    <img src={vote.user && vote.user.avatar && vote.user.avatar.url} alt={vote.user.name} className="rounded-circle" style={{ width: '40px', height: '40px' }} />
                  </td>
                  <td className="text-truncate"><Moment format="DD/MM/YYYY">{vote.createdAt}</Moment></td>
                  <td>{vote.user.houseNumber || '-'}</td>
                  <td>{!vote.option ? <span className="text-muted">None</span> : vote.option}</td>
                  {vote.isAgree === 1 ? (
                    <td><span className="text-success">Agree</span></td>
                  ) : (
                    <td><span className="text-danger">Not agree</span></td>
                  )}
                  <td className="text-truncate">{capitalizeFirst(truncate(vote.note, 100))}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
  )
}

export default VoteListPage
