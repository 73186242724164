import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { deniedBannedUser } from '../actions/buildingActions'



const BuildingPage = ({ history }) => {

  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building } = buildingDetails;


  useEffect(() => {
    dispatch(deniedBannedUser(building, userInfo, history));  // lead if banned

  }, [dispatch, history, userInfo, building])


  return (
    <div className="content">
      <div className="row">
        <div className="col-md-4">
          <>
            <div className="card regulation">
              <Link to={`/building/${building && building._id}/general`}>
                <img className="card-img-top" src="https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="general-rules" />
              </Link>
              <div className="card-body">
                <Link to={`/building/${building && building._id}/general`}>General</Link>
                <p className="card-text">Some basic regulations, entry to the estate, Security policy, key cards and others.</p>
              </div>
            </div>
          </>
        </div>
        <div className="col-md-4">
          <>
            <div className="card regulation">
              <Link to={`/building/${building && building._id}/gym`}>
                <img className="card-img-top" src="https://images.pexels.com/photos/4498553/pexels-photo-4498553.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="gym-rules" />
              </Link>
              <div className="card-body">
                <Link to={`/building/${building && building._id}/gym`}>Gym</Link>
                <p className="card-text">It's also important for all to maintain and keep the fitness and sauna area clean.</p>
              </div>
            </div>
          </>
        </div>
        <div className="col-md-4">
          <>
            <div className="card regulation">
              <Link to={`/building/${building && building._id}/pool`}>
                <img className="card-img-top" src="https://images.pexels.com/photos/3775140/pexels-photo-3775140.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="pool-rules" />
              </Link>
              <div className="card-body">
                <Link to={`/building/${building && building._id}/pool`}>Pool</Link>
                <p className="card-text">There are several regulation for using the pool area for your safety and the safety of our community.</p>
              </div>
            </div>
          </>
        </div>
        <div className="col-md-4">
          <>
            <div className="card regulation">
              <Link to={`/building/${building && building._id}/animal`}>
                <img className="card-img-top" src="https://images.pexels.com/photos/1909802/pexels-photo-1909802.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="animal-rules" />
              </Link>
              <div className="card-body">
                <Link to={`/building/${building && building._id}/animal`}>Pets</Link>
                <p className="card-text">If you are the owner of pets, than you have extra responsibilty to guard them.</p>
              </div>
            </div>
          </>
        </div>
        <div className="col-md-4">
          <>
            <div className="card regulation">
              <Link to={`/building/${building && building._id}/exterior`}>
                <img className="card-img-top" src="https://images.pexels.com/photos/1901028/pexels-photo-1901028.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="exterior-rules" />
              </Link>
              <div className="card-body">
                <Link to={`/building/${building && building._id}/exterior`}>Exterior</Link>
                <p className="card-text">Information of what paint colors of we use to for the exterior the common area. Or contraction works time</p>
              </div>
            </div>
          </>
        </div>
        <div className="col-md-4">
          <>
            <div className="card regulation">
              <a href={`/building/${building && building._id}/committee`}>
                <img className="card-img-top" src="https://images.pexels.com/photos/3184430/pexels-photo-3184430.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="" />
              </a>
              <div className="card-body">
                <a href={`/building/${building && building._id}/committee`}>Committee & Operators</a>
                <p className="card-text">If you have questions and need other details about our maintaining, we'll give our best to inform you.
                </p>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  )
}

export default BuildingPage
