import { ADMIN_POST_DELETE_FAIL, ADMIN_POST_DELETE_REQUEST, ADMIN_POST_DELETE_SUCCESS } from '../constants/adminConstants'
import {
  POST_LIST_REQUEST,
  POST_LIST_SUCCESS,
  POST_LIST_FAIL,
  POST_LIST_RESET,

  POST_DETAILS_REQUEST,
  POST_DETAILS_SUCCESS,
  POST_DETAILS_FAIL,
  POST_DETAILS_RESET,

  POST_FILTER_REQUEST,
  POST_FILTER_SUCCESS,
  POST_FILTER_FAIL,
  POST_FILTER_RESET,

  POST_LIKE_UPDATE,
  POST_DETAILS_LIKE_UPDATE,
  POST_LIKE_FAIL,

  POST_CREATE_REQUEST,
  POST_CREATE_SUCCESS,
  POST_CREATE_FAIL,
  POST_CREATE_RESET,

  POST_UPDATE_REQUEST,
  POST_UPDATE_SUCCESS,
  POST_UPDATE_FAIL,
  POST_UPDATE_RESET,

  POST_REMOVE_REQUEST,
  POST_REMOVE_SUCCESS,
  POST_REMOVE_FAIL,
  POST_REMOVE_RESET,

  POST_VOTE_LIST_REQUEST,
  POST_VOTE_LIST_SUCCESS,
  POST_VOTE_LIST_FAIL,
  POST_VOTE_LIST_RESET,
  POST_VOTE_CREATE_REQUEST,
  POST_VOTE_CREATE_SUCCESS,
  POST_VOTE_CREATE_FAIL,

  UPDATE_COUNTDOWN,
  UPDATE_COUNTDOWN_RESET,

  POST_IMAGE_UPLOAD_REQUEST,
  POST_IMAGE_UPLOAD_SUCCESS,
  POST_IMAGE_UPLOAD_LOADING,
  POST_IMAGE_UPLOAD_FAIL,
  POST_IMAGE_UPLOAD_RESET,

  POST_LIST_REPORT_REQUEST,
  POST_LIST_REPORT_SUCCESS,
  POST_LIST_REPORT_FAIL,
  // POST_LIST_REPORT_RESET

} from '../constants/postConstants'


export const postListReportReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_LIST_REPORT_REQUEST:
      return {
        loading: true
      }
    case POST_LIST_REPORT_SUCCESS:
      return {
        posts: action.payload.posts,
        loading: false
      }
    case POST_LIST_REPORT_FAIL:
      return {
        error: action.payload,
        loading: false
      }
    default:
      return state
  }
}

export const postListReducer = (state = { posts: [] }, action) => {
  switch (action.type) {
    case POST_LIST_REQUEST:
      return { loading: true, posts: [] }
    case POST_LIST_SUCCESS:
      return {
        loading: false,
        posts: action.payload.posts, // paginate effect
        pages: action.payload.pages,
        page: action.payload.page
      }
    case POST_LIST_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case POST_LIST_RESET:
      return {}
    case POST_LIKE_UPDATE:
      return {
        ...state,
        loading: false,
        posts: state.posts.map(post => post._id === action.payload.id ? { ...post, likes: action.payload.likes } : post)
      }
    case POST_LIKE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case ADMIN_POST_DELETE_SUCCESS:
      return {
        loading: false,
        posts: state.posts.filter(post => post._id !== action.payload)
      }
    default:
      return state;
  }
}

export const postDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_DETAILS_REQUEST:
      return {
        loading: true
      }
    case POST_DETAILS_SUCCESS:
      return {
        loading: false,
        post: action.payload,
      }
    case POST_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case POST_DETAILS_RESET:
      return {}
    case POST_DETAILS_LIKE_UPDATE:
      return {
        // loading: false,
        // success: true,
        post: { ...state.post, likes: action.payload.likes }
      }
    case POST_IMAGE_UPLOAD_SUCCESS:
      return {
        loading: false,
        post: { ...state.post, images: action.payload.images, files: action.payload.files }
      }
    default:
      return state;
  }
}

export const postCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_CREATE_REQUEST:
      return { loading: true }
    case POST_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        post: action.payload,
      }
    case POST_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case POST_CREATE_RESET:
      return {}
    default:
      return state;
  }
}

export const postRemoveReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_REMOVE_REQUEST:
      return {
        loading: true
      }
    //removed to postsListFilterReducer
    // case POST_REMOVE_SUCCESS:
    //   return {
    //     loading: false,
    //     success: true
    //   }
    case POST_REMOVE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case POST_REMOVE_RESET:
      return {}
    default:
      return state;
  }
}

export const postDeleteAdminReducer = (state = {}, action) => {
  switch (action) {
    case ADMIN_POST_DELETE_REQUEST:
      return {
        loading: true
      }
    case ADMIN_POST_DELETE_FAIL:
      return {
        error: action.payload,
        loading: false
      }
    default:
      return state
  }
}


export const postUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_UPDATE_REQUEST:
      return { loading: true }
    case POST_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        post: action.payload
      }
    case POST_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case POST_UPDATE_RESET:
      return {}
    default:
      return state;
  }
}

export const postImageUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_IMAGE_UPLOAD_REQUEST:
      return {
        loading: true
      }
    // MOVED TO -> postDetailsReducer
    // case POST_IMAGE_UPLOAD_SUCCESS:
    //   return {
    //     loading: false,
    //     success: true
    //   }
    case POST_IMAGE_UPLOAD_LOADING:
      return {
        loading: false
      }
    case POST_IMAGE_UPLOAD_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case POST_IMAGE_UPLOAD_RESET:
      return {}
    default:
      return state;
  }
}

export const postVoteCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_VOTE_CREATE_REQUEST:
      return {
        loading: true
      }
    case POST_VOTE_CREATE_SUCCESS:
      return {
        loading: false,
        success: true
      }
    case POST_VOTE_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}

export const postVoteListReducer = (state = { votes: [] }, action) => {
  switch (action.type) {
    case POST_VOTE_LIST_REQUEST:
      return {
        loading: true,
        votes: []
      }
    case POST_VOTE_LIST_SUCCESS:
      return {
        loading: false,
        votes: action.payload.votes,
        haveVoted: action.payload.haveVoted
        // haveVoted: action.payload.voteExist,
      }
    case POST_VOTE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case POST_VOTE_LIST_RESET:
      return {}
    default:
      return state;
  }
}

export const postCountdownUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_COUNTDOWN:
      return {
        timeLeft: action.payload
      }
    case UPDATE_COUNTDOWN_RESET:
      return {}
    default:
      return state;
  }
}

export const postsListFilterReducer = (state = { posts: [] }, action) => {
  switch (action.type) {
    case POST_FILTER_REQUEST:
      return {
        loading: true,
        posts: []
      }
    case POST_FILTER_SUCCESS:
      return {
        loading: false,
        page: action.payload.page,
        pages: action.payload.pages,
        posts: action.payload.posts,
      }
    case POST_FILTER_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case POST_FILTER_RESET:
      return {}
    case POST_REMOVE_SUCCESS:
      return {
        loading: false,
        posts: state.posts.filter(post => post._id !== action.payload._id)
      }
    default:
      return state;
  }
}







