import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import { adminDeletePost } from '../../actions/adminAction'

const PostItem = ({ post }) => {
  const dispatch = useDispatch();

  return (
    <tr key={post._id} className={post.isRemoved && 'text-danger'}>
      <td><Moment format="DD/MM/YYYY">{post.createdAt}</Moment></td>
      <td style={{ minWidth: '200px' }}>{post.title}</td>
      <td>{post.category}</td>
      <td>{post.group}</td>
      <td><Moment format="DD-MM-YYYY">{post.createdAt}</Moment></td>
      <td>
        {post.images.length ? <i className="far fa-images mr-2"></i> : ''}
        {post.files.length ? <i class="fa-solid fa-file-pdf"></i> : ''}
      </td>
      <td>{post.ratingsAverage}</td>
      <td>
        {post.toVote && new Date(post.finalVote) > new Date() ? (
          <i className="fas fa-calendar-check text-success"></i>
        ) : !post.toVote && post.finalVote ? (
          <i className="fas fa-ban text-danger"></i>
        ) : post.toVote && new Date(post.finalVote) < new Date().getTime() ? (
          <i className="fas fa-calendar-times text-danger"></i>
        ) : (
          <span className="text-muted">No vote</span>
        )}
      </td>
      <td>
        <Link to={`/posts/${post.id}/comments`}>
          <i className="fas fa-eye text-primary"></i>
        </Link>
      </td>
      <td>
        <Link to={`/admin/posts/${post.id}`} onClick={() => dispatch(adminDeletePost(post._id))}>
          <i className="fas fa-trash-alt text-danger"></i>
        </Link>
      </td>
      <td></td>
    </tr>
  )
}

export default PostItem
