import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import PrivateRoute from './components/routing/PrivateRoute'
import HomePage from './pages/HomePage'
import PostPage from './pages/PostPage'
import PostForm from './components/posts/PostForm'
import PostDetailsPage from './pages/PostDetailsPage'
import ProfilePage from './pages/ProfilePage'
import LoginPage from './pages/LoginPage'
import RegisterPage from './pages/RegisterPage'
import ExpensePage from './pages/ExpensePage'
import ReportPage from './pages/ReportPage'
import DashboardPage from './pages/DashboardPage'
import BuildingPage from './pages/BuildingPage'
import GeneralPage from './pages/regulation/GeneralPage'
import PoolPage from './pages/regulation/PoolPage'
import FitnessPage from './pages/regulation/FitnessPage'
import AnimalPage from './pages/regulation/AnimalPage'
import ExteriorPage from './pages/regulation/ExteriorPage'
import CommitteePage from './pages/regulation/CommitteePage'
import AdminPage from './pages/admin/AdminPage';
import AdminLoginPage from './pages/admin/AdminLoginPage';
import UserEditForm from './components/admin/UserEditForm';
import VoteListPage from './pages/VoteListPage';
import NotFoundPage from './pages/NotFoundPage'
import DocumentPage from './pages/DocumentPage'
import NewGroupPage from './pages/NewGroupPage'
import VerifyNumberPage from './pages/VerifyNumberPage'
import ErrorPage from './pages/ErrorPage'
import BannedPage from './pages/BannedPage'
import WelcomePage from './pages/WelcomePage'
import ForgotPasswordPage from './pages/passwordReset/ForgotPasswordPage'
import SecurityCreatePage from './pages/SecurityCreatePage'
import SecurityCheckPage from './pages/passwordReset/SecurityCheckPage'
import ResetPasswordPage from './pages/passwordReset/ResetPasswordPage'
import GuidePage from './pages/GuidePage'
import TermAndConditionPage from './pages/TermAndConditionPage'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'




const App = () => {
  return (
    <Router>
      <Header />
      <main>
        {/* need this switch to solve the not found page problem */}
        <Switch>
          <PrivateRoute exact path="/error" component={ErrorPage} />
          <Route exact path="/reset-password/:token/NGQ4YTNkYWYwIiwiaWF0Ij" component={ResetPasswordPage} />
          <Route exact path="/security-check/:token/iOjE2MjQ5Njk0NDB9" component={SecurityCheckPage} />
          <PrivateRoute exact path="/security-password" component={SecurityCreatePage} />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
          <PrivateRoute exact path="/report" component={ReportPage} />
          <PrivateRoute exact path="/building/:buildingId/committee" component={CommitteePage} />
          <PrivateRoute exact path="/building/:buildingId/exterior" component={ExteriorPage} />
          <PrivateRoute exact path="/building/:buildingId/animal" component={AnimalPage} />
          <PrivateRoute exact path="/building/:buildingId/gym" component={FitnessPage} />
          <PrivateRoute exact path="/building/:buildingId/pool" component={PoolPage} />
          <PrivateRoute exact path="/building/:buildingId/general" component={GeneralPage} />
          <PrivateRoute exact path="/building/:buildingId" component={BuildingPage} />
          <PrivateRoute exact path="/dashboard/documents/:id" component={DocumentPage} />
          <PrivateRoute exact path="/dashboard/regulation" component={DashboardPage} />
          <PrivateRoute exact path="/dashboard/location" component={DashboardPage} />
          <PrivateRoute exact path="/dashboard/ticket/:ticket/emails/:id" component={DashboardPage} />
          <PrivateRoute exact path="/dashboard/settings" component={DashboardPage} />
          <PrivateRoute exact path="/dashboard/videos" component={DashboardPage} />
          <PrivateRoute exact path="/dashboard/emails" component={DashboardPage} />
          <PrivateRoute exact path="/dashboard/documents" component={DashboardPage} />
          <PrivateRoute exact path="/dashboard/contacts" component={DashboardPage} />
          <PrivateRoute exact path="/dashboard/posts/page/:pageNumber" component={DashboardPage} />
          <PrivateRoute exact path="/dashboard/posts" component={DashboardPage} />
          <PrivateRoute exact path="/dashboard/users/page/:pageNumber" component={DashboardPage} />
          <PrivateRoute exact path="/dashboard/users" component={DashboardPage} />
          <PrivateRoute exact path="/dashboard" component={DashboardPage} />
          <PrivateRoute exact path="/expenses" component={ExpensePage} />
          <PrivateRoute exact path="/expenses/:month/:year/page/:pageNumber" component={ExpensePage} />
          <PrivateRoute exact path="/expenses/:month/:year" component={ExpensePage} />
          <PrivateRoute exact path='/posts/search/:keyword/page/:pageNumber' component={PostPage} />
          <PrivateRoute exact path="/posts/search/:keyword" component={PostPage} />
          <PrivateRoute exact path="/posts/page/:pageNumber" component={PostPage} />
          <PrivateRoute exact path="/posts/:id/votes" component={VoteListPage} />
          <PrivateRoute exact path="/posts/:id/comments/page/:pageNumber" component={PostDetailsPage} />
          <PrivateRoute exact path="/posts/:id/comments" component={PostDetailsPage} />
          <PrivateRoute exact path="/posts/:id/edit" component={PostForm} />
          <PrivateRoute exact path="/posts/new" component={PostForm} />
          <PrivateRoute exact path="/posts" component={PostPage} />
          <PrivateRoute exact path="/profile/me" component={ProfilePage} />
          <PrivateRoute exact path="/admin/users/:id/:firstName/edit" component={UserEditForm} />
          <PrivateRoute exact path="/admin/emails/:id/ticket/:ticket/:token" component={AdminPage} />
          <PrivateRoute exact path="/admin/ticket/:ticket/email/:id/:token" component={AdminPage} />
          <PrivateRoute exact path="/admin/emails/:token" component={AdminPage} />
          <PrivateRoute exact path="/admin/posts/:token" component={AdminPage} />
          <PrivateRoute exact path="/admin/users/:token" component={AdminPage} />
          <PrivateRoute exact path="/admin/users/page/:pageNumber" component={AdminPage} />
          <PrivateRoute exact path="/admin/authentication/:token/IiwiaWF0IjoxNjI0MTY5NjAzLC" component={AdminLoginPage} />
          <PrivateRoute exact path="/welcome/building/:id/activation" component={WelcomePage} />
          <PrivateRoute exact path="/welcome/building" component={WelcomePage} />
          <PrivateRoute exact path="/new-community" component={NewGroupPage} />
          <PrivateRoute exact path="/verify-number" component={VerifyNumberPage} />
          <PrivateRoute exact path="/privacy-policy" component={PrivacyPolicyPage} />
          <Route exact path="/video-guide" component={GuidePage} />
          <Route exact path="/term-and-conditions" component={TermAndConditionPage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/" component={HomePage} />
          <Route exact path="/access-denied" component={BannedPage} />
          <Route exact component={NotFoundPage} />
        </Switch>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
