import React from 'react'

const ErrorPage = () => {
  return (
    <div className="row justify-content-center align-items-center h-100">
      <div className="col content">
        <p className="text-light bg-danger text-center m-3 p-3" style={{ borderRadius: '5px' }}>
          Something is wrong with your account. Please contact your admin !
      </p>
      </div>
    </div>
  )
}

export default ErrorPage
