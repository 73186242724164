import React, { Fragment } from 'react'

const Paginate = ({
  page,
  pages,
  isExpensePage = false,  // expense page
  month,  // expense page
  year,    // expense page
  isCommentPage = false,
  postId,
  isDashUserList = false,
  isDashPostList = false,
  isAdminPage = false
}) => {
  return Number(pages) > 1 &&
    <nav aria-label="Page navigation example">
      <ul className="pagination">
        <li className="page-item">
          <a className="page-link"
            href={`${isExpensePage
              ? `/expenses/${month}/${year}/page/${page - 1}`
              : isCommentPage
                ? `/posts/${postId}/comments/page/${page - 1}`
                : isDashUserList
                  ? `/dashboard/users/page/${page - 1}`
                  : isDashPostList
                    ? `/dashboard/posts/page/${page - 1}`
                    : isAdminPage
                      ? `/admin/users/page/${page - 1}`
                      : `/posts/page/${(Number(page) - 1) > 0 ? page - 1 : page}`}`
            } // end of href 
            aria-label="Previous"
          >
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </a>
        </li>

        {[...Array(pages).keys()].map(x => (
          <Fragment key={x + 1} >
            <li className={`page-item ${x + 1 === Number(page) && 'active'}`}>
              <a className="page-link active"
                href={`
                  ${isExpensePage
                    ? `/expenses/${month}/${year}/page/${x + 1}`
                    : isCommentPage
                      ? `/posts/${postId}/comments/page/${x + 1}`
                      : isDashUserList
                        ? `/dashboard/users/page/${x + 1}`
                        : isDashPostList
                          ? `/dashboard/posts/page/${x + 1}`
                          : isAdminPage
                            ? `/admin/users/page/${x + 1}`
                            : `/posts/page/${x + 1}`
                  }`
                }>
                {x + 1}
              </a>
            </li>
          </Fragment>
        ))}

        <li className="page-item">
          <a className="page-link"
            href={`${isExpensePage
              ? `/expenses/${month}/${year}/page/${page + 1}`
              : isCommentPage
                ? `/posts/${postId}/comments/page/${page + 1}`
                : isDashUserList
                  ? `/dashboard/users/page/${page + 1}`
                  : isDashPostList
                    ? `/dashboard/posts/page/${page + 1}`
                    : isAdminPage
                      ? `/admin/users/page/${page + 1}`
                      : `/posts/page/${(Number(page) + 1) < Number(pages) ? Number(page) + 1 : pages}`
              }`}
            aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>

}

export default Paginate
