import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { useParams } from 'react-router-dom'
// import Spinner from '../components/Spinner'
import ExpenseItem from '../components/expenses/ExpenseItem'
import ExpenseForm from '../components/expenses/ExpenseForm'
import FilterForm from '../components/FilterForm'
import Paginate from '../components/Paginate'
import { listExpenses, clearExpenseDetails } from '../actions/expenseActions'
import { deniedBannedUser } from '../actions/buildingActions'
import { isOperator } from '../actions/authActions'
import { amountRegex } from '../components/helper/utils'
import ExpenseInfo from '../components/expenses/ExpenseInfo'
import Spinner from '../components/Spinner'


const ExpensePage = ({ match, history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo, isActive } = userLogin;

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building } = buildingDetails;

  const expenseList = useSelector(state => state.expenseList);
  const { expenses, loading, page, pages, month: expenseMonth, year: expenseYear } = expenseList;

  const expenseDetails = useSelector(state => state.expenseDetails);
  const { expense, loading: loadingExpDetails } = expenseDetails;


  const month = match.params.month || new Date().getMonth() + 1;
  const year = match.params.year || new Date().getFullYear()
  // const { month, year } = useParams()
  const pageNumber = match.params.pageNumber;

  useEffect(() => {
    dispatch(deniedBannedUser(building, userInfo, history));

    if (userInfo.community === 'no-group') {
      return history.push('/new-community');
    }

    // if (userInfo.role !== 'admin' && month > new Date().getMonth()) {
    //   window.location.href = '/expenses'
    // }


    // if setting of expensePage false, redirect user except admin
    if (!building.expensePage && userInfo.role !== 'admin') {
      history.push('/')
    }

    dispatch(listExpenses(month, year, pageNumber));
  }, [dispatch, history, userInfo, month, year, pageNumber, building, isActive])

  // CALCULATE EXPENSE
  const calculateExpense = (subject) => {
    let total = 0;
    if (expenses) {
      expenses.map(exp => {
        if (subject === 'income') {
          if (exp.category === 'Income') {
            total += exp.price;
          }
        } else if (subject === 'expense') {
          if (exp.category === 'Expense') {
            total += exp.price
          }
        }
      })
    }

    const result = amountRegex(total, true);

    // true is withouth currency
    return result;
  }


  return (
    loading ? <Spinner /> :
      <>
        <div className="content">
          <div className="row">
            {/* <!-- EXPENSE FILTER FORM --> */}
            <div className="col-md-6">
              <FilterForm month={month} year={year} userInfo={userInfo} />
            </div>
            {/* <!-- total expense --> */}
            <div className="col-md-2">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text text-danger">฿</span>
                </div>
                {/* calculateExpense('expense') */}
                <input type="text" className="form-control text-danger" value={calculateExpense('expense')} aria-label="Amount (to the nearest bath)" disabled />
              </div>
            </div>
            {/* <!-- total income --> */}
            <div className="col-md-2">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">฿</span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={calculateExpense('income')}
                  aria-label="Amount (to the nearest bath)"
                  disabled
                />
              </div>
            </div>
            {/* <!-- add expense button --> */}
            {/* {userInfo.role !== 'member' && ( */}
            {dispatch(isOperator(building, userInfo)) && (
              <div className="col-md-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#expenseForm"
                  onClick={() => dispatch(clearExpenseDetails())}>
                  <i className="fas fa-plus"></i> Add Expense
                </button>
              </div>
            )}
          </div>

          {/* Expense item */}
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="table-responsive">
                {month && year && (
                  <small>&nbsp; {month} / {year}</small>
                )}
                <table id="expense-table" className="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th className='expense-subject'>Subject</th>
                      {/* <th>Title</th> */}
                      {/* <th>Company</th> */}
                      <th>Note</th>
                      <th>Amount</th>
                      <th>File</th>
                      {/* <th><i className="fa fa-user text-muted"></i></th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {expenses && expenses.map(expense => (
                      <ExpenseItem
                        key={expense._id}
                        expense={expense}
                        building={building}
                        isMarked={expense.isMarked}
                      />
                    ))}
                  </tbody>
                </table>

                {expenses && expenses.length === 0 && (
                  <p className="text-center lead text-muted mt-5">-----There are still no expenses.-----</p>
                )}

                <div className="mt-4">
                  <Paginate
                    page={page}
                    pages={pages}
                    isExpensePage={true}
                    month={expenseMonth}
                    year={expenseYear}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Modal add expense window action--> */}
        <ExpenseForm expense={expense} loadingExpDetails={loadingExpDetails} />

        {/* Modal Expense Info  */}
        <ExpenseInfo />
      </>
  )
}

export default ExpensePage
