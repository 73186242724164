import {
  SET_ALERT,
  REMOVE_ALERT
} from '../constants/alertConstants'

export const setAlert = (msg, type) => dispatch => {
  // dispatch({ type: REMOVE_ALERT })
  dispatch({
    type: SET_ALERT,
    payload: { msg, type }
  });

  window.setTimeout(() => {
    dispatch({ type: REMOVE_ALERT })
  }, 5000)
}