export const POST_CREATE_REQUEST = 'POST_CREATE_REQUEST';
export const POST_CREATE_SUCCESS = 'POST_CREATE_SUCCESS';
export const POST_CREATE_FAIL = 'POST_CREATE_FAIL';
export const POST_CREATE_RESET = 'POST_CREATE_RESET';

export const POST_UPDATE_REQUEST = 'POST_UPDATE_REQUEST';
export const POST_UPDATE_SUCCESS = 'POST_UPDATE_SUCCESS';
export const POST_UPDATE_FAIL = 'POST_UPDATE_FAIL';
export const POST_UPDATE_RESET = 'POST_UPDATE_RESET';

export const POST_REMOVE_REQUEST = 'POST_REMOVE_REQUEST';
export const POST_REMOVE_SUCCESS = 'POST_REMOVE_SUCCESS';
export const POST_REMOVE_FAIL = 'POST_REMOVE_FAIL';
export const POST_REMOVE_RESET = 'POST_REMOVE_RESET';

export const POST_LIST_REQUEST = 'POST_LIST_REQUEST';
export const POST_LIST_SUCCESS = 'POST_LIST_SUCCESS';
export const POST_LIST_FAIL = 'POST_LIST_FAIL';
export const POST_LIST_RESET = 'POST_LIST_RESET';

export const POST_LIST_REPORT_REQUEST = 'POST_LIST_REPORT_REQUEST';
export const POST_LIST_REPORT_SUCCESS = 'POST_LIST_REPORT_SUCCESS';
export const POST_LIST_REPORT_FAIL = 'POST_LIST_REPORT_FAIL';
export const POST_LIST_REPORT_RESET = 'POST_LIST_REPORT_RESET';

export const POST_DETAILS_REQUEST = 'POST_DETAILS_REQUEST';
export const POST_DETAILS_SUCCESS = 'POST_DETAILS_SUCCESS';
export const POST_DETAILS_FAIL = 'POST_DETAILS_FAIL';
export const POST_DETAILS_RESET = 'POST_DETAILS_RESET';

export const POST_FILTER_REQUEST = 'POST_FILTER_REQUEST';
export const POST_FILTER_SUCCESS = 'POST_FILTER_SUCCESS';
export const POST_FILTER_FAIL = 'POST_FILTER_FAIL';
export const POST_FILTER_RESET = 'POST_FILTER_RESET';

export const POST_LIKE_UPDATE = 'POST_LIKE_UPDATE';
export const POST_DETAILS_LIKE_UPDATE = 'POST_DETAILS_LIKE_UPDATE';
export const POST_LIKE_FAIL = 'POST_LIKE_FAIL';

export const POST_VOTE_CREATE_REQUEST = 'POST_VOTE_CREATE_REQUEST';
export const POST_VOTE_CREATE_SUCCESS = 'POST_VOTE_CREATE_SUCCESS';
export const POST_VOTE_CREATE_FAIL = 'POST_VOTE_CREATE_FAIL';

export const POST_VOTE_LIST_REQUEST = 'POST_VOTE_LIST_REQUEST';
export const POST_VOTE_LIST_SUCCESS = 'POST_VOTE_LIST_SUCCESS';
export const POST_VOTE_LIST_FAIL = 'POST_VOTE_LIST_FAIL';
export const POST_VOTE_LIST_RESET = 'POST_VOTE_LIST_RESET';

export const POST_IMAGE_UPLOAD_REQUEST = 'POST_IMAGE_UPLOAD_REQUEST';
export const POST_IMAGE_UPLOAD_SUCCESS = 'POST_IMAGE_UPLOAD_SUCCESS';
export const POST_IMAGE_UPLOAD_LOADING = 'POST_IMAGE_UPLOAD_LOADING';
export const POST_IMAGE_UPLOAD_FAIL = 'POST_IMAGE_UPLOAD_FAIL';
export const POST_IMAGE_UPLOAD_RESET = 'POST_IMAGE_UPLOAD_RESET';

export const UPDATE_COUNTDOWN = 'UPDATE_COUNTDOWN';
export const UPDATE_COUNTDOWN_RESET = 'UPDATE_COUNTDOWN_RESET';






