import React from 'react'

const Alert = ({ msg, type = 'danger' }) => {
  return msg !== null &&
    <div className={`alert alert-${type}`}>
      {msg}
    </div>
}

export default Alert
