import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { votingPost } from '../../actions/postActions'
import { capitalizeFirst } from '../helper/utils';

const VoteForm = ({ postId, post }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    isAgree: '',
    option: '',
    note: ''
  });

  const { isAgree, option, note } = formData;


  const onSubmitHandler = e => {
    e.preventDefault();
    dispatch(votingPost(postId, formData));
  }

  const [agreeToggle, setAgreeToggle] = useState(false);

  return (
    <form id="vote-form" onSubmit={onSubmitHandler}>
      <div className="form-check mb-1">
        {/* is agree */}
        <input
          className="form-check-input vote-word"
          type="radio"
          name="isAgree"
          value={isAgree}
          onChange={() => {
            setFormData({ ...formData, isAgree: true })
            setAgreeToggle(true)
          }} />
        <label className="form-check-label" htmlFor="agree-radio">I agree.</label>
      </div>
      {/* disagree */}
      <div className="form-check mb-3">
        <input
          className="form-check-input vote-word"
          type="radio"
          name="isAgree"
          value={isAgree}
          onChange={() => {
            setFormData({ ...formData, isAgree: false })
            setAgreeToggle(false)
          }} />
        <label className="form-check-label" htmlFor="disagree-radio">I disagree.</label>
      </div>
      {/* choose vote option */}
      {/* {post.optionVote && agree === 1 && ( */}
      <div className="form-group mt-3">
        {post.optionVote && agreeToggle && (
          <>
            <label id="label-vote-option" className="text-muted">I choose option :</label>
            <input
              type="number"
              name="option"
              value={option}
              className="form-control w-50"
              min="1"
              max="4"
              step="1"
              placeholder="Number"
              // required
              onChange={e => {
                setFormData({ ...formData, option: e.target.value })
              }} />
          </>
        )}
      </div>
      {/* )} */}
      <div className="form-group">
        <textarea
          name="note"
          value={capitalizeFirst(note)}
          cols="50"
          rows="5"
          placeholder="You may type your opinion here. (Optional)"
          className="form-control"
          onChange={e => {
            setFormData({ ...formData, note: e.target.value })
          }}></textarea>
      </div>
      <input type="submit" value="Send My Vote" className="btn btn-primary float-right mb-4" />
      <div className="clearfix"></div>
    </form>
  )
}

export default VoteForm
