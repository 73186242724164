import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../components/Spinner'
import UserItem from '../../components/users/UserItem'
import { adminListUsers } from '../../actions/adminAction'
import Paginate from '../../components/Paginate'

const AdminUserList = ({ isAdminPage, building, pageNumber }) => {
  const dispatch = useDispatch();

  const userList = useSelector(state => state.userList);
  const { users, page, pages, loading } = userList;

  useEffect(() => {
    dispatch(adminListUsers(pageNumber));
  }, [dispatch, pageNumber])

  return (
    loading ? <Spinner /> :
      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th></th>
              <th>Firstname</th>
              <th>Lastname</th>
              <th>Haus</th>
              <th>Email</th>
              <th>Username</th>
              <th>Community</th>
              <th>Created</th>
              <th>Last Login</th>
              <th>
                <i className="fab fa-facebook"></i>
                <i className="fab fa-twitter"></i>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users && users.map((user, index) => (
              <UserItem
                key={user._id}
                user={user}
                index={index}
                building={building}
                isAdminPage={isAdminPage}
              />
            ))}
          </tbody>
        </table>

        {/* Pagination */}
        <Paginate page={page} pages={pages} isAdminPage={isAdminPage} />

      </div>
  )
}

export default AdminUserList
