import React from 'react'



const SupportInfo = ({ userInfo }) => {
  return (
    <div className="modal fade" id="supportForm">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              <i className="fas fa-user-astronaut fa-lg text-primary"></i>{' '}
              <span className="text-secondary">Technical Support</span>
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className='text-center'>
              <img src="https://res.cloudinary.com/imgtocloud/image/upload/v1649485586/Baanbalina3%27s%20Village/my-line_hlgoz0.jpg" alt="my line id" style={{ width: '200px' }} />
              <p className='lead mt-3'>Scan this barcode</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupportInfo
