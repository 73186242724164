import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { listExpenses } from '../actions/expenseActions';


const FilterForm = ({ month, year, userInfo }) => {
  const [search, setSearch] = useState({
    selectedMonth: '',
    selectedYear: ''
  });

  let { selectedMonth, selectedYear } = search;

  const dispatch = useDispatch();

  // const { month, year } = search;

  // const thisMonth = new Date().getMonth() + 1;
  // const thisYear = new Date().getFullYear();

  // const chosenMonth = window.location.pathname.split('/')[2] * 1

  // FOR MONTH SELECT OPTION
  const expenseMonth = () => {
    const months = 'January,February,March,April,May,June,July,August,September,October,November,December';
    const monthsArr = months.split(',');
    // const monthIndex = new Date().getMonth();

    // const items = [];

    // for (const [index, value] of monthsArr.entries()) {
    //   items.push(<option key={index} value={value}>{value}</option>);
    // }

    return monthsArr;

  }

  // FOR YEAR SELECT OPTION 
  const expenseYear = () => {
    let elements = [];
    let thisYear = 2021;
    const nextYear = new Date().getFullYear();

    for (let i = thisYear; i <= nextYear; i++) {
      elements.push(i);
    }

    // for (let i = 0; i < 5; i++) {
    //   elements.push(thisYear + i);
    // }

    // const items = [];

    // for (const [index, value] of elements.entries()) {
    //   var selected = (value === thisYear) ? ' selected' : '';
    //   items.push(<option key={index} value={value}>{value}</option>);
    // }
    // console.log(elements)
    return elements;
  }

  useEffect(() => {
    setSearch({
      selectedMonth: month ? month : new Date().getMonth() + 1,
      selectedYear: year ? year : new Date().getFullYear()
    })
  }, [month, year])

  const onSubmitHandler = e => {
    e.preventDefault();
    dispatch(listExpenses(selectedMonth, selectedYear));
  }


  return (
    <>
      <form className="form-group form-inline mb-5" onSubmit={onSubmitHandler}>
        {/* getMonth is for the start month */}
        <select className="form-control mr-2 mb-2" value={selectedMonth} onChange={e => {
          setSearch({ ...search, selectedMonth: e.target.value })
        }}>

          {/* <option>-- Select Month --</option> */}
          {/* <option>Select month</option> */}
          {expenseMonth().map((m, i) => (
            <option
              key={i + 1}
              value={i + 1}
              className={i + 1 === new Date().getMonth() + 1 ? 'bg-warning' : ''}
            >
              {m}
            </option>
          ))}
        </select>

        {/* getFullYear is for start year */}
        {/* <select className="form-control mr-2 mb-2" value={selectedYear || year || new Date().getFullYear()} onChange={e => { */}
        <select className="form-control mr-2 mb-2" value={selectedYear || year} onChange={e => {
          setSearch({ ...search, selectedYear: e.target.value })
        }}>
          {/* <option>--Select Year--</option> */}
          {expenseYear().map(exp => (
            <option key={exp} value={exp && exp}>{exp}</option>
          ))}
        </select>
        <Link type="button"
          className="btn btn-success mb-2"
          to={`/expenses/${selectedMonth}/${selectedYear}`}
        >
          <i className="fas fa-search"></i>
        </Link>

      </form>
    </>
  )
}


export default FilterForm
