export const VIDEO_CREATE_REQUEST = 'VIDEO_CREATE_REQUEST';
export const VIDEO_CREATE_SUCCESS = 'VIDEO_CREATE_SUCCESS';
export const VIDEO_CREATE_FAIL = 'VIDEO_CREATE_FAIL';

export const VIDEO_DETAILS_REQUEST = 'VIDEO_DETAILS_REQUEST'
export const VIDEO_DETAILS_SUCCESS = 'VIDEO_DETAILS_SUCCESS'
export const VIDEO_DETAILS_FAIL = 'VIDEO_DETAILS_FAIL'
export const VIDEO_DETAILS_RESET = 'VIDEO_DETAILS_RESET'

export const VIDEO_SELECTED_REQUEST = 'VIDEO_SELECTED_REQUEST'
export const VIDEO_SELECTED_SUCCESS = 'VIDEO_SELECTED_SUCCESS'

export const VIDEO_LIST_REQUEST = 'VIDEO_LIST_REQUEST'
export const VIDEO_LIST_SUCCESS = 'VIDEO_LIST_SUCCESS'
export const VIDEO_LIST_FAIL = 'VIDEO_LIST_FAIL'

export const VIDEO_UPDATE_REQUEST = 'VIDEO_UPDATE_REQUEST';
export const VIDEO_UPDATE_SUCCESS = 'VIDEO_UPDATE_SUCCESS';
export const VIDEO_UPDATE_FAIL = 'VIDEO_UPDATE_FAIL';

export const VIDEO_REMOVE_REQUEST = 'VIDEO_REMOVE_REQUEST';
export const VIDEO_REMOVE_SUCCESS = 'VIDEO_REMOVE_SUCCESS';
export const VIDEO_REMOVE_FAIL = 'VIDEO_REMOVE_FAIL';

export const VIDEO_DELETE_REQUEST = 'VIDEO_DELETE_REQUEST';
export const VIDEO_DELETE_SUCCESS = 'VIDEO_DELETE_SUCCESS';
export const VIDEO_DELETE_FAIL = 'VIDEO_DELETE_FAIL';