import {
  COMMENT_LIST_REQUEST,
  COMMENT_LIST_SUCCESS,
  COMMENT_LIST_FAIL,
  COMMENT_LIST_RESET,
  COMMENT_DETAILS_REQUEST,
  COMMENT_DETAILS_SUCCESS,
  COMMENT_DETAILS_FAIL,
  COMMENT_DETAILS_RESET,
  COMMENT_RECENT_LIST_REQUEST,
  COMMENT_RECENT_LIST_SUCCESS,
  COMMENT_RECENT_LIST_FAIL,
  COMMENT_RECENT_LIST_RESET,
  COMMENT_CREATE_SUCCESS,
  COMMENT_CREATE_FAIL,
  COMMENT_UPDATE_SUCCESS,
  COMMENT_UPDATE_FAIL,
  COMMENT_DELETE_SUCCESS,
  COMMENT_DELETE_FAIL,
  COMMENT_MARK_READ_SUCCESS,
  COMMENT_MARK_READ_FAIL,
  COMMENT_MARK_READ_RESET,
} from '../constants/commentConstants'


export const commentListReducer = (state = { comments: [] }, action) => {
  switch (action.type) {
    case COMMENT_LIST_REQUEST:
      return {
        loading: true,
        comments: []
      }
    case COMMENT_LIST_SUCCESS:
      return {
        loading: false,
        page: action.payload.page,
        pages: action.payload.pages,
        numComments: action.payload.numComments,
        comments: action.payload.comments  // paginate effect
      }
    case COMMENT_LIST_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case COMMENT_CREATE_SUCCESS:
      return {
        loading: false,
        plusOne: state.comments.length + 1, // helps as counter 
        comments: [action.payload, ...state.comments]
      }
    case COMMENT_UPDATE_SUCCESS:
      return {
        loading: false,
        comments: state.comments.map(comment => comment._id === action.payload.commentId ? action.payload.data : comment)
      }
    case COMMENT_DELETE_SUCCESS:
      return {
        loading: false,
        minusOne: state.comments.length - 1,
        comments: state.comments.filter(comment => comment._id !== action.payload)
      }
    case COMMENT_LIST_RESET:
      return {}
    default:
      return state;
  }
}

// postRoutes 
export const commentRecentListReducer = (state = { comments: [] }, action) => {
  switch (action.type) {
    case COMMENT_RECENT_LIST_REQUEST:
      return {
        loading: true,
        comments: []
      }
    case COMMENT_RECENT_LIST_SUCCESS:
      return {
        loading: false,
        length: action.payload.length,
        comments: action.payload.recentComments,
      }
    case COMMENT_RECENT_LIST_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case COMMENT_RECENT_LIST_RESET:
      return {}
    default:
      return state;
  }
}

export const commentCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMENT_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}

export const commentDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMENT_DETAILS_REQUEST:
      return {
        loading: true
      }
    case COMMENT_DETAILS_SUCCESS:
      return {
        loading: false,
        comment: action.payload
      }
    case COMMENT_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case COMMENT_DETAILS_RESET:
      return {}
    default:
      return state;
  }
}

export const commentUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMENT_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}

export const commentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMENT_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}

// postRoutes 
export const commentRecentReadReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMENT_MARK_READ_SUCCESS:
      return {
        loading: false,
        success: true
      }
    case COMMENT_MARK_READ_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case COMMENT_MARK_READ_RESET:
      return {}
    default:
      return state;
  }
}
