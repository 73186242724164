import React from 'react';

const NotFoundPage = () => {
  return (
    <div className="row text-center justify-content-center align-items-center" style={{ minHeight: '80vh', fontWeight: '600px' }}>
      <div className="col-md-6 col-sm-6 col-lg-3 col-xl-4">
        <h1 className='x-large text-danger'>
          <i className='fas fa-exclamation-triangle' /> Page Not Found
        </h1>
        <div className="row">
          <div className="col-md">
            <p className='lead'>The URL address you are trying to access doesn't exist.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
