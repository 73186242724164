import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Alert from '../../components/Alert'
import Spinner from '../../components/Spinner'
import { updateBuilding } from '../../actions/buildingActions'
import { setAlert } from '../../actions/alertActions'
import { BUILDING_UPDATE_RESET } from '../../constants/buildingConstants'
import { capitalizeFirst } from '../../components/helper/utils'

// updated



const AddressForm = ({ building, userInfo, loading, history }) => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    district: '',
    province: '',
    zip: '',
    email: '',
    telephone1: '',
    telephone2: '',
    mobilePhone1: '',
    mobilePhone2: '',
    dayFrom: '',
    dayEnd: '',
    openTime: '',
    closeTime: '',
    note: ''
  });

  const {
    name,
    address,
    district,
    province,
    zip,
    email,
    telephone1,
    telephone2,
    mobilePhone1,
    mobilePhone2,
    dayFrom,
    dayEnd,
    openTime,
    closeTime,
    note
  } = formData;

  const dispatch = useDispatch();


  const buildingUpdate = useSelector(state => state.buildingUpdate);
  const { success, error } = buildingUpdate;

  const alertAction = useSelector(state => state.alertAction);
  const { alert } = alertAction;


  useEffect(() => {

    if (success) {
      dispatch(setAlert('Information updated !', 'success'));
      setTimeout(() => {
        dispatch({ type: BUILDING_UPDATE_RESET })
      }, 3000)

    } else if (error) {
      dispatch(setAlert(error, 'danger'));
      dispatch({ type: BUILDING_UPDATE_RESET })
    }

    if (building && building.position && building.contact) {
      setFormData({
        name: loading || !building.position.name ? '' : building.position.name,
        address: loading || !building.position.address ? '' : building.position.address,
        district: loading || !building.position.district ? '' : building.position.district,
        province: loading || !building.position.province ? '' : building.position.province,
        zip: loading || !building.position.zip ? '' : building.position.zip,
        email: loading || !building.contact.email ? '' : building.contact.email,
        telephone1: loading || !building.contact.telephone1 ? '' : building.contact.telephone1,
        telephone2: loading || !building.contact.telephone2 ? '' : building.contact.telephone2,
        mobilePhone1: loading || !building.contact.mobilePhone1 ? '' : building.contact.mobilePhone1,
        mobilePhone2: loading || !building.contact.mobilePhone2 ? '' : building.contact.mobilePhone2,
        dayFrom: loading || !building.contact.dayFrom ? '' : building.contact.dayFrom,
        dayEnd: loading || !building.contact.dayEnd ? '' : building.contact.dayEnd,
        openTime: loading || !building.contact.openTime ? '' : building.contact.openTime,
        closeTime: loading || !building.contact.closeTime ? '' : building.contact.closeTime,
        note: loading || !building.contact.note ? '' : building.contact.note
      })

    }
  }, [dispatch, history, building, loading, success, error])


  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const [toggleContact, setToggleContact] = useState(false);

  const onSubmitHandler = e => {
    e.preventDefault();

    dispatch(updateBuilding(building._id, formData))
  }

  return (
    loading ? <Spinner /> :
      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-10 col-xl-10">
          <div className='text-center mt-3'>
            {alert && <Alert msg={alert.msg} type={alert.type} />}
          </div>

          <form className="mt-3" onSubmit={onSubmitHandler}>
            <div className="form-group">
              <small>Name</small>
              <input type="text" className="form-control" value={capitalizeFirst(name)} name="name" placeholder="The Condominium" onChange={onChange} />
            </div>
            <div className="form-group">
              <small>Address</small>
              <input type="text" className="form-control" value={capitalizeFirst(address)} name="address" placeholder="000/00 moo Soi Pattaya 7, Sukhumvit street" onChange={onChange} />

            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <small>District / city</small>
                <input type="text" className="form-control" value={capitalizeFirst(district)} name="district" placeholder="Banglamung" onChange={onChange} />
              </div>
              <div className="form-group col-md-4">
                <small>Province</small>
                <input type="text" className="form-control" value={capitalizeFirst(province)} name="province" placeholder="Chonburi" onChange={onChange} />
              </div>
              <div className="form-group col-md-4">
                <small>Zip</small>
                <input type="number" className="form-control" value={zip} name="zip" placeholder="20150" onChange={onChange} />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-8">
                <div className="form-group">
                  <small>From - End (day)</small>
                  <input type="text" className="form-control" value={capitalizeFirst(dayFrom)} name="dayFrom" placeholder="Monday" onChange={onChange} />
                </div>
                <div className="form-group">
                  {/* <small>To</small> */}
                  <input type="text" className="form-control" value={capitalizeFirst(dayEnd)} name="dayEnd" placeholder="Satturday" onChange={onChange} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <small>Open - Close (time)</small>
                  <input type="number" className="form-control" value={openTime} name="openTime" min='0' max='12' placeholder="00 AM" onChange={onChange} />
                </div>
                <div className="form-group">
                  {/* <small>Close time</small> */}
                  <input type="number" className="form-control" value={closeTime} name="closeTime" min='0' max='12' placeholder="00 PM" onChange={onChange} />
                </div>
              </div>
            </div>
            <a href="#!" type="button" className="float-right" onClick={() => setToggleContact(!toggleContact)}><i className="fa fa-plus"></i> Add Telephone</a>
            {toggleContact && (
              <div className="form-row">
                <div className="col-md-6">
                  <div className="form-group">
                    <small>Telephone1 (optional)</small>
                    <input type="number" className="form-control" value={telephone1} name="telephone1" placeholder="(66)12345..." onChange={onChange} />
                  </div>
                  <div className="form-group">
                    <small>Telephone2 (optional)</small>
                    <input type="number" className="form-control" value={telephone2} name="telephone2" onChange={onChange} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <small>Mobile phone1 (optional)</small>
                    <input type="number" className="form-control" value={mobilePhone1} name="mobilePhone1" placeholder="0173...." onChange={onChange} />
                  </div>
                  <div className="form-group">
                    <small>Mobile phone2 (optional)</small>
                    <input type="number" className="form-control" value={mobilePhone2} name="mobilePhone2" onChange={onChange} />
                  </div>
                </div>
              </div>
            )}
            <div className="form-group mt-4">
              <small>Email (optional)</small>
              <input type="email" className="form-control" value={email} name="email" placeholder="office@youremail.com" onChange={onChange} />
            </div>

            <div className="form-group">
              <small>Note (optional)</small>
              <textarea type="text" className="form-control" value={capitalizeFirst(note)} name="note" placeholder="If any, add some note here to your open time " onChange={onChange} />
            </div>
            <input type="submit" value="Update Location" className="btn btn-primary mb-4 float-right" />
          </form>
        </div>
      </div>
  )
}

export default AddressForm
