import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addComment, clearCommentDetails, updateComment } from '../../actions/commentActions'
import { capitalizeFirst } from '../helper/utils';


// props from postDetailsPage
const CommentForm = ({ post, comment, loadingCommentDetails }) => {
  const dispatch = useDispatch();

  const [commentForm, setCommentForm] = useState({
    ratings: '',
    title: '',
    text: ''
  })

  const { ratings, title, text } = commentForm;

  useEffect(() => {
    if (comment) {
      setCommentForm({
        ratings: loadingCommentDetails || !comment.ratings ? '' : comment.ratings,
        title: loadingCommentDetails || !comment.title ? '' : comment.title,
        text: loadingCommentDetails || !comment.text ? '' : comment.text
      })
    }
  }, [comment, loadingCommentDetails])


  const onChange = e => {
    setCommentForm({ ...commentForm, [e.target.name]: e.target.value });
  }

  const onSubmit = e => {
    e.preventDefault();
    document.querySelector('#close1').click()

    if (comment) {
      dispatch(updateComment(post._id, comment._id, commentForm));
      dispatch(clearCommentDetails());
    } else {
      if (title === '' || text === '') {
        // return dispatch(setAlert('Make sure to write title and your comment !', 'danger'));
      } else {
        dispatch(addComment(post._id, commentForm))
        setCommentForm({
          ratings: '',
          title: '',
          comment: ''
        })
      }
    }

    setCommentForm({
      ratings: '',
      title: '',
      text: ''
    })
  }

  return (
    <div className="modal fade" id="commentForm">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {comment ? 'Edit Comment' : 'New Comment'}
            </h5>
            <button type="button" className="close" id="close1" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={onSubmit} className="form-group mb-5">
              <div className="mb-2">
                <small>Ratings: </small>
                <select name="ratings" value={ratings} className="form-control" onChange={onChange}>
                  <option value="1">1 bad</option>
                  <option value="2">2 good</option>
                  <option value="3">3 satisfy</option>
                  <option value="4">4 very good</option>
                  <option value="5">5 excelent</option>
                </select>
              </div>
              <div className="mb-2">
                <small>Title:</small>
                <input type="text" value={capitalizeFirst(title)} name="title" maxLength="100" className="form-control" onChange={onChange} />
              </div>
              <div className="mb-2">
                <small>Comment:</small>
                <textarea name="text" value={capitalizeFirst(text)} className="form-control" cols="30" rows="5" onChange={onChange}></textarea>
              </div>
              {/* <input type="button" value="Cancel" className="btn btn-secondary my-3 float-right" /> */}
              <input type="submit" className="btn btn-primary my-3 float-right" />
              <button className="btn btn-secondary my-3 float-right mr-2" data-dismiss="modal" onClick={() => dispatch(clearCommentDetails())}>Cancel</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommentForm
