export const DOCUMENT_UPLOAD_REQUEST = 'DOCUMENT_UPLOAD_REQUEST';
export const DOCUMENT_UPLOAD_SUCCESS = 'DOCUMENT_UPLOAD_SUCCESS';
export const DOCUMENT_UPLOAD_FAIL = 'DOCUMENT_UPLOAD_FAIL';

export const DOCUMENT_LIST_REQUEST = 'DOCUMENT_LIST_REQUEST';
export const DOCUMENT_LIST_SUCCESS = 'DOCUMENT_LIST_SUCCESS';
export const DOCUMENT_LIST_FAIL = 'DOCUMENT_LIST_FAIL';

export const DOCUMENT_DETAILS_REQUEST = 'DOCUMENT_DETAILS_REQUEST';
export const DOCUMENT_DETAILS_SUCCESS = 'DOCUMENT_DETAILS_SUCCESS';
export const DOCUMENT_DETAILS_FAIL = 'DOCUMENT_DETAILS_FAIL';

export const DOCUMENT_DOWNLOAD_REQUEST = 'DOCUMENT_DOWNLOAD_REQUEST';
export const DOCUMENT_DOWNLOAD_SUCCESS = 'DOCUMENT_DOWNLOAD_SUCCESS';
export const DOCUMENT_DOWNLOAD_FAIL = 'DOCUMENT_DOWNLOAD_FAIL';

