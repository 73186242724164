import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { adminListUsers, getEmailDetails, adminLogout } from '../../actions/adminAction'
// import Moment from 'react-moment'
import UserList from './AdminUserList'
import PostList from './AdminPostList'
import AdminEmailList from './AdminEmailList'
import AdminTicketPage from './AdminTicketPage'

const AdminPage = ({ match, history }) => {

  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building } = buildingDetails;

  const buildingList = useSelector(state => state.buildingList);
  const { buildings } = buildingList;

  const adminFullGranted = useSelector(state => state.adminFullGranted);
  const { token } = adminFullGranted;

  const adminEmailDetails = useSelector(state => state.adminEmailDetails);
  const { email } = adminEmailDetails;

  const pageNumber = match.params.pageNumber;
  const urlPath = window.location.pathname.split('/')[2];

  useEffect(() => {
    if (!token) {
      history.push('/')
    }

    // get email bei clicking envelope or text content table
    if (match.params.id !== undefined) {
      dispatch(getEmailDetails(match.params.id))
    }

  }, [dispatch, history, match, token])


  const adminListPosts = (option) => {
    history.push(`/admin/posts/${token}`)
  }

  const adminListUserHandler = (building) => {

    if (urlPath !== 'users') {
      history.push(`/admin/users/${token}`);
    }

    dispatch(adminListUsers(building, pageNumber));

  }


  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(adminLogout());
  }


  return (
    <div className="row">
      <div className="col-md">
        <div className="content">
          {/* LOGOUT FORM */}
          <form onSubmit={submitHandler} className="float-right">
            <div className="form-row align-items-center">
              <div className="col-auto my-1">
                <input type="submit" value="Logout" className="btn btn-primary float-right mb-5" />
              </div>
            </div>
          </form>

          {/* SELECT FORM GROUP */}
          <form className="float-right">
            <div className="form-row align-items-center">
              <div className="col-auto my-1">
                <select className="custom-select mr-sm-2" onChange={e => adminListUserHandler(e.target.value)}>
                  <option value="undefined" selected>Choose...</option>
                  {buildings.map(item => (
                    <option value={item.group}>{item.group}</option>
                  ))}
                </select>
              </div>
            </div>
          </form>

          <form className="float-right">
            <div className="form-row align-items-center">
              <div className="col-auto my-1">
                <select className="custom-select mr-sm-2" onChange={(e) => adminListPosts(e.target.value)}>
                  <option selected>Choose...</option>
                  <option value="all-posts">All posts</option>
                  <option value="deleted-posts">Deleted posts</option>
                </select>
              </div>
            </div>
          </form>

          {/* EMAIL */}
          <div className="float-right">
            <div className="col-auto my-1">
              <Link to={`/admin/emails/${token}`}>
                <i className="fas fa-envelope fa-lg"></i>
              </Link>
            </div>
          </div>
        </div>

        <div className="clearfix"></div>

        {urlPath === 'users' && (
          <UserList
            isAdminPage={true}
            building={building}
            pageNumber={pageNumber} />
        )}

        {urlPath === 'posts' && (
          <PostList
            isAdminPage={true}
            building={building}
            pageNumber={pageNumber} />
        )}

        {urlPath === 'emails' && (
          <AdminEmailList
            userInfo={userInfo}
            isAdminPage={true}
            email={email}
            pageNumber={pageNumber} />
        )}

        {urlPath === 'ticket' && (
          <AdminTicketPage />
        )}
      </div>
    </div>

  )
}

export default AdminPage
