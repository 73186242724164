import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setAlert } from '../../actions/alertActions';
import Alert from '../../components/Alert'
// import axios from 'axios';
import { uploadImage } from '../../actions/postActions'


const UploadImageForm = () => {
  const [images, setImages] = useState([]);
  const [continueUpload, setContinueUpload] = useState(false);

  const numPictures = 40;

  const dispatch = useDispatch();


  const postDetails = useSelector(state => state.postDetails);
  const { post } = postDetails;

  // this helps the the form interaction
  const postImageUpload = useSelector(state => state.postImageUpload);
  const { loading } = postImageUpload;
  // 
  const alertAction = useSelector(state => state.alertAction);
  const { alert } = alertAction;



  useEffect(() => {
    const uploadPostImages = () => {
      document.getElementById('imageUploadForm').addEventListener('submit', e => {
        const uploadImages = document.getElementById('uploadImages').files.length;
        const existImages = document.querySelectorAll('.imageDeleteCheckbox').length;
        const deleteImages = document.querySelectorAll('.imageDeleteCheckbox:checked').length;

        const newTotal = existImages - deleteImages + uploadImages;
        const toRemoveItem = newTotal - 9;

        if (newTotal > numPictures) {
          e.preventDefault();
          dispatch(setAlert(`Pelese delete at least ${toRemoveItem} image${toRemoveItem === 1 ? '' : 's'} !`, 'danger'));
          setContinueUpload(false);
        } else {
          setContinueUpload(true);
        }
      })
    }

    uploadPostImages();
  }, [dispatch])



  const uploadLoadingTime = (uploadBytes) => {
    let time = 0;

    if (uploadBytes > 10000) {
      time = 9000;
    } else if (uploadBytes > 5000) {
      time = 6000;
    } else {
      time = 3000;
    }

    return time;
  }


  const convertBytes = (bytes) => {
    const result = (bytes / 1024).toFixed(2);
    return result + ` ${result < 1000 ? 'KB' : 'MB'}`
  }

  const listImages = () => {
    let result = ``;
    for (const item of images) {
      result += `<li>${item.name} (${convertBytes(item.size)})</li>`

    }

    return result;
  }

  // From Brad
  // const uploadImageHandler = async e => {
  //   let formData = new FormData();
  //   const files = e.target.files;

  //   for (let i = 0; i < files.length; i++) {
  //     formData.append('images', files[i]);
  //   }

  //   dispatch(uploadImage(post._id, formData));
  // }


  const submitHandler = e => {
    e.preventDefault();
    let formData = new FormData();
    let uploadSize = 0;

    // To upload images
    const files = images;

    for (let i = 0; i < files.length; i++) {
      formData.append('images', files[i]);
      uploadSize += files[i].size;
    }

    // Send public_id To delete Images
    const deleteImages = document.querySelectorAll('.imageDeleteCheckbox:checked');
    for (const file of deleteImages) {
      formData.append('deleteImages[]', file.value);  // value is public_id through map
    }

    // Send public_id To delete Images
    const deleteFiles = document.querySelectorAll('.fileDeleteCheckbox:checked');
    for (const file of deleteFiles) {
      formData.append('deleteFiles[]', file.value);  // value is public_id through map
    }

    // set the maximum rule
    if (continueUpload) {
      dispatch(uploadImage(post._id, formData));

      document.getElementById('uploadImages').value = null;

      setTimeout(() => {
        document.querySelector('#uploadBtn').click();
      }, uploadLoadingTime(uploadSize));

    } else {
      // help to set the value to empty withouth after finished uploading ( withouth  have to refresh the page )
      document.getElementById('uploadImages').value = null
    }

  }


  return (
    <div className="modal fade" id="uploadImageForm">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              Upload Image
            </h5>
            <button type="button" className="close" id="uploadBtn" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">

            {alert && <Alert msg={alert.msg} type={alert.type} />}

            {/* UPLOAD FORM */}
            <form className="fmb-5" onSubmit={submitHandler} id="imageUploadForm">
              <div className="form-group">
                <div className="w-50">
                  <input
                    type="file"
                    id="uploadImages"
                    name="images"
                    accept="image/*"
                    // className="custom-file-input" (if use this, name of to upload files is not listed)
                    onChange={e => setImages(e.target.files)}
                    multiple
                  />
                  {/* <label className="custom-file-label" htmlFor="iamge-profile">Select file..</label> */}
                </div>
                <input
                  type="submit"
                  value={loading ? 'Uploading....' : 'Submit'}
                  className="btn btn-primary mb-3 float-right"
                  disabled={loading && 'disabled'}
                />
              </div>
              <div>

                <div
                  className="mb-4"
                  dangerouslySetInnerHTML={{
                    __html: listImages()

                  }}></div>

                <p>
                  <small className="text-danger">Maximum upload {numPictures} items / files. Select them to delete.</small> <br />
                </p>
                {/* IMAGES */}
                {post.images.map((photo, i) => (
                  <span key={photo._id}>
                    <img
                      src={photo.url} alt={i}
                      className="mr-1 my-2"
                      style={{ height: '60px', width: '60px' }}
                    />
                    <input
                      type="checkbox"
                      name="deleteImages[]"
                      id={i}
                      value={photo.public_id}
                      className="imageDeleteCheckbox mr-4"
                    />
                  </span>
                ))}

                {/* FILES */}
                <div className="mt-4">
                  {post.files.map((file, i) => (
                    <li key={i} style={{ listStyleType: 'none' }}>
                      <input
                        type="checkbox"
                        name="deleteFiles[]"
                        id={i}
                        value={file.public_id}
                        className="fileDeleteCheckbox mr-4"
                      />
                      {file.originalname}
                    </li>
                  ))}
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadImageForm
