import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Moment from 'react-moment'
import Alert from '../../components/Alert'
import { getEmailDetails, sendEmail } from '../../actions/userActions';
import { setAlert } from '../../actions/alertActions';



const InboxPageForm = ({ emailId, history }) => {
  const [text, setText] = useState('');

  const dispatch = useDispatch();

  // cannot props from DashboardPage because cannot 
  const userEmailDetails = useSelector(state => state.userEmailDetails);
  const { email } = userEmailDetails;

  const userContactSupport = useSelector(state => state.userContactSupport);
  const { success: successUserContactSupport } = userContactSupport;

  const alertAction = useSelector(state => state.alertAction);
  const { alert } = alertAction;

  useEffect(() => {
    if (!email || email._id !== emailId) {
      dispatch(getEmailDetails(emailId));
    }

    if (successUserContactSupport) {
      dispatch(setAlert('Email sent !. Thank you.', 'success'));
    }

  }, [dispatch, emailId, history, email, successUserContactSupport])


  const submitHandler = e => {
    e.preventDefault();
    dispatch(sendEmail({ text }, email.ticket));  // send the ticket number in case user antwort existed ticket
    setText('');
  }

  return (
    <div className="row">
      <div className="col-md">
        <div className="table-responssive mb-4">
          <table>
            <thead>
              <tr>
                <th style={{ width: '200px' }}>Date:</th>
                <th style={{ width: '200px' }}>Ticket:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Moment format="DD/MM/YYYY">{email && email.createdAt}</Moment>
                </td>
                <td>
                  {email && email.ticket}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <form onSubmit={submitHandler}>
          <label>Response:</label>
          <p>{email && email.text}</p>

          {alert && <Alert msg={alert.msg} type={alert.type} />}

          <textarea
            name="text"
            className="form-control"
            cols="30"
            rows="10"
            value={text}
            onChange={e => setText(e.target.value)}
          ></textarea>
          <input type="submit" className="btn btn-primary float-right my-3" style={{ width: '100px' }} />
        </form>
      </div>
    </div>
  )
}

export default InboxPageForm
