import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Alert from '../components/Alert'
import Spinner from '../components/Spinner'
import { createBuilding, checkPendingBuilding, deniedBannedUser } from '../actions/buildingActions'
import { capitalizeFirst } from '../helper/utils.js'



const NewGroupPage = ({ history }) => {
  const dispatch = useDispatch();

  const [community, setCommunity] = useState('');

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo, loading } = userLogin;

  const buildingCreate = useSelector(state => state.buildingCreate);
  const { building: buildingCreated, success: successCreateBuilding, error } = buildingCreate;

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building, loading: loadingBuildingDetails } = buildingDetails;


  const buildingCheckPending = useSelector(state => state.buildingCheckPending);
  const { loading: loadingIsBuildingPending } = buildingCheckPending;



  useEffect(() => {
    dispatch(deniedBannedUser(building, userInfo, history))

    if (successCreateBuilding) {
      history.push(`/welcome/building/${buildingCreated._id}/activation`);

    } else {
      dispatch(checkPendingBuilding(history));
    }


  }, [dispatch, building, userInfo, history, buildingCreated, successCreateBuilding])

  const submitHandler = e => {
    e.preventDefault();
    dispatch(createBuilding({ community }));
  }

  return (
    loading || loadingBuildingDetails || loadingIsBuildingPending ? < Spinner /> :
      <>
        <div className="container-fluid h-100">
          <div className="row justify-content-center align-items-center h-100" style={{ minHeight: '80vh' }}>
            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4" >
              <>
                {error && <Alert msg={error} />}
                <form onSubmit={submitHandler}>
                  <div className="form-group mb-3">
                    <small>Community's name</small>
                    <input
                      type="text"
                      name="community"
                      value={capitalizeFirst(community)}
                      maxLength="30"
                      className="form-control form-control-lg"
                      placeholder="ex. The Building's name"
                      onChange={e => setCommunity(e.target.value)}
                      disabled={true}
                    />
                    <small className="text-danger">Make sure you choose a correct name. This name will be connected with all your members and cannot be changed.</small>

                  </div>
                  <input type="submit" value="Submit Community" disabled={true} className="btn btn-block btn-lg btn-primary" />
                </form>
                <div className="mt-5 float-right">
                  <p className="lead">To join community, please  &nbsp;
                    <Link to="/verify-number" className=" btn btn-sm btn-info">{`Next >>`}</Link>
                  </p>
                </div>
              </>
            </div>
          </div>
        </div>
      </>
  )
}

export default NewGroupPage
