import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isOperator } from '../../actions/authActions'
import { getServiceDetails, deleteService } from '../../actions/buildingActions'

const ServiceItem = ({ item, building, dashboardPage = false }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;


  return (
    <tr>
      <th scope="row"><i className={item.icon}></i> {item.title}</th>
      <td>
        {item.call1 && (<i className="fa-solid fa-square-phone-flip text-info" ></i>)} {' '}
        {item.call1 && item.call1} <br />

        {item.call2 && (<i className="fa-solid fa-square-phone-flip text-info" ></i>)} {' '}
        {item.call2 && item.call2}

        {item.lineId && (<i className="fa-brands fa-line text-success" ></i>)} {' '}
        {item.lineId && item.lineId} <br />
      </td>
      {dashboardPage && isOperator(building._id, userInfo) && (
        <td>
          {/* edit-delete toggle */}
          <a href="#!"
            type="button" className="dropdown-toggle"
            data-toggle="dropdown">
            ...
          </a>

          {/* edit-delete options */}
          <div className="dropdown-menu">
            <Link to="/dashboard/location"
              className="delete-item dropdown-item"
              onClick={() => dispatch(deleteService(building._id, item._id))}
            >
              <i className="fas fa-trash-alt text-danger mr-2"></i>delete
            </Link>
            <a href="#!"
              className="edit-item dropdown-item"
              data-toggle="modal" data-target="#expenseForm"
              onClick={() => dispatch(getServiceDetails(building._id, item._id))}
            >
              <i className="far fa-edit text-success mr-2"></i>edit
            </a>
          </div>
        </td>
      )}
    </tr >
  )
}

export default ServiceItem