import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { capitalizeFirst, formatDate } from '../helper/utils'
import { createPost, getPostDetails, updatePost } from '../../actions/postActions'
import Alert from '../Alert'
import { setAlert } from '../../actions/alertActions'
import { isOperator } from '../../actions/authActions'



const PostForm = ({ history, match }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    createdAt: new Date(),
    title: '',
    category: '',
    optionVote: false,
    finalVote: '',
    description: '',
    reportMonth: '',
    toVote: false, // value must exist otherwise empty valuse while creating post
    fixExpense: false,
    savingBook: false
  });

  const { title, category, optionVote, finalVote, description, toVote, reportMonth, fixExpense, savingBook, createdAt } = formData;

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const postDetails = useSelector(state => state.postDetails);
  const { post, loading } = postDetails;

  const postCreate = useSelector(state => state.postCreate);
  const { success: successCreate, post: postCreated, error: errorPostCreate } = postCreate;

  const postUpdate = useSelector(state => state.postUpdate);
  const { success: successUpdate, post: postUpdated, error: errorPostUpdate } = postUpdate;

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building } = buildingDetails;

  const alertAction = useSelector(state => state.alertAction);
  const { alert } = alertAction;

  useEffect(() => {
    if (successCreate) {
      history.push(`/posts/${postCreated._id}/comments`);
    } else if (successUpdate) {
      history.push(`/posts/${postUpdated._id}/comments`);
    }

    if (errorPostCreate) {
      dispatch(setAlert(errorPostCreate, 'danger'));
    } else if (errorPostUpdate) {
      dispatch(setAlert(errorPostUpdate, 'danger'));
    }

    // for edit form
    if (match.params.id && !post) {
      dispatch(getPostDetails(match.params.id))
    }

    // for edit form
    if (post) {
      setFormData({
        createdAt: loading || !post.createdAt ? '' : formatDate(post.createdAt, 'toISOString'),
        title: loading || !post.title ? '' : post.title,
        category: loading || !post.category ? '' : post.category,
        optionVote: loading || !post.optionVote ? '' : post.optionVote,
        finalVote: loading || !post.finalVote ? '' : formatDate(post.finalVote, 'toISOString'),
        description: loading || !post.description ? '' : post.description,
        toVote: loading || !post.toVote ? false : post.toVote,
        reportMonth: loading || !post.reportMonth ? '' : post.reportMonth,
        fixExpense: loading || !post.fixExpense ? '' : post.fixExpense,
        savingBook: loading || !post.savingBook ? '' : post.savingBook
      })
    }

  }, [
    errorPostCreate,
    errorPostUpdate,
    history,
    dispatch,
    match,
    loading,
    post,
    postCreated,
    postUpdated,
    successCreate,
    successUpdate
  ])


  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });


  const onSubmit = e => {
    e.preventDefault();
    if (post) {
      dispatch(updatePost(post._id, formData))
    } else {
      if (!title || !category || !description) {
        dispatch(setAlert('Please fill the form !'));
      } else {
        dispatch(createPost(formData, building._id));
      }

    }
  }



  const [checkVoting, toggleCheckVoting] = useState(post && post.toVote ? true : false);  // need to fix problem with edit form if nothing has been changed and try to update
  const [withOption, toggleWithOption] = useState();
  const [noOption, toggleNoOption] = useState();


  return (
    <>
      <div className="container" style={{ position: "relative", margin: 'auto', marginTop: '120px' }}>
        <div className="card">
          <div className="card-header">
            <h3>{post ? 'Edit Post' : 'New Post'}</h3>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md">

                {alert && <Alert msg={alert.msg} type={alert.type} />}

                <form onSubmit={onSubmit} className="form-group">
                  {userInfo.role === 'admin' && (
                    <div className='mb-3'>
                      <input type="date" name="createdAt" value={createdAt} className='form-control' style={{ width: '30%' }} onChange={onChange} />
                      <small>Created at</small>
                    </div>
                  )}

                  <div style={{ clear: 'both' }}></div>
                  {/* Title */}
                  <div className="mb-3">
                    <small>Title:</small>
                    <input type="text" name="title" value={capitalizeFirst(title)} className="form-control" onChange={onChange} />
                  </div>
                  <div className="mb-3">
                    <small>Category</small>
                    <select id="category-post" name="category" value={category} className="form-control" onChange={onChange}>
                      <option className="category">Choose Category</option>
                      <option value="Pool" className="category">Pool</option>
                      <option value="Garden" className="category">Garden</option>
                      <option value="Internet" className="category">Internet</option>
                      <option value="Fitness" className="category">Fitness</option>
                      <option disabled className="category">------------------------------</option>
                      <option value="Legal" className="category">Legal</option>
                      <option value="Others" className="category">Others</option>
                      <option disabled className="category">------------------------------</option>
                      <option value="Bank" className="category">Bank / Petty Cash</option>
                      <option value="Meeting" className="category">Minute meeting</option>
                      <option value="Expenditure" className="category">Income & Expenditures</option>
                      <option value="Letter" className="category">Letters / Anouncement</option>
                    </select>
                  </div>

                  {category === 'Expenditure' && (
                    <div className='mb-4'>
                      <small>Report's month</small>
                      <select name="reportMonth" value={reportMonth} className='form-control mb-3' onChange={onChange} style={{ width: '30%' }}>
                        <option>Choose Month</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>

                      <input
                        type="checkbox"
                        name="fixExpense"
                        value={fixExpense || ''}
                        checked={fixExpense}
                        className='mr-2'
                        onChange={(e) => {
                          setFormData({ ...formData, fixExpense: e.target.checked })
                        }} />
                      <small className='mr-5'>Monthly expenses</small>
                    </div>
                  )}

                  {category === 'Bank' && (
                    <div className='mb-4'>
                      <small>Report's month</small>
                      <select name="reportMonth" value={reportMonth} className='form-control mb-3' onChange={onChange} style={{ width: '30%' }}>
                        <option>Choose Month</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>

                      <input
                        type="checkbox"
                        name="savingBook"
                        value={savingBook || ''}
                        checked={savingBook}
                        className="mr-2"
                        onChange={e => {
                          setFormData({ ...formData, savingBook: e.target.checked })
                        }} />
                      <small className='mr-5'>Saving book</small>
                    </div>
                  )}

                  <div className="mb-2">
                    {/* {userInfo.role !== 'member' && ( */}
                    {dispatch(isOperator(building, userInfo)) && (
                      <>
                        <input
                          type="checkbox"
                          name="toVote"
                          value={toVote || ''}
                          checked={toVote}
                          className="status mr-2"
                          onChange={e => {
                            setFormData({ ...formData, toVote: e.target.checked })
                            toggleCheckVoting(!checkVoting);
                          }}
                        />

                        {post && post.toVote ? (
                          <small className="text-danger">(Uncheck this to cancel voting)</small>
                        ) : (
                          <small>Voting</small>
                        )}
                      </>
                    )}
                  </div>

                  {/* {(userInfo.role !== 'member') && ( */}
                  {dispatch(isOperator(building, userInfo)) && (
                    (post && post.toVote && !checkVoting) || checkVoting && (
                      <>
                        <div className="ml-2 mt-2">
                          <div className="my-3">
                            <div className="mb-3">
                              <input
                                type="radio"
                                name="optionVote"
                                className="mr-2"
                                value={optionVote}
                                checked={post && !post.optionVote ? true : noOption}
                                onChange={e => {
                                  setFormData({ ...formData, optionVote: noOption })
                                  toggleNoOption(true)
                                  toggleWithOption(false)
                                }} />
                              <small>No option</small>
                              <input
                                type="radio"
                                name="optionVote"
                                className="ml-3 mr-2"
                                value={optionVote}
                                checked={post && post.optionVote ? true : withOption}
                                onChange={e => {
                                  setFormData({ ...formData, optionVote: true })
                                  toggleNoOption(false)
                                  toggleWithOption(true)
                                }} />
                              <small>With option (max. 4)</small>
                            </div>
                            <div>
                              <small>Final vote</small>
                              <input type="date" name="finalVote" value={finalVote} className="form-control" style={{ width: '50%' }} onChange={onChange} />
                              <small className="text-danger">Once you start voting post, it can't be undo, edited or removed. Make sure the informations are all correct.</small>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  )}
                  <div>
                    <small>Description:</small>
                    <textarea
                      name="description"
                      className="form-control"
                      cols="20"
                      rows="10"
                      value={capitalizeFirst(description)}
                      onChange={onChange}
                    ></textarea>
                  </div>
                  <div className="float-right mt-4">
                    <input type="submit" value={post ? 'Update Post' : 'Submit'} className="btn btn-primary" />{' '}
                    <Link to={post ? `/posts/${post._id}/comments` : '/posts'} type="button" className="btn btn-secondary">Cancel</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default PostForm
