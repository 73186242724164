import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Moment from 'react-moment'
import { amountRegex, htmlHandleChars, truncate } from '../helper/utils'
import { getExpenseDetails, markExpense, deleteExpense } from '../../actions/expenseActions'
import { capitalizeFirst } from '../helper/utils.js'

// updated


const ExpenseItem = ({ expense, building, isMarked }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;


  const dummyText = () => {
    return <span className="text-muted">No details provided</span>
  }

  // Solve the problem of user _id after create and reload the page
  const showEditDeleteDropdown = (
    <>
      <a href="#!"
        onClick={() => dispatch(getExpenseDetails(expense._id))}
        type="button" className="dropdown-toggle"
        data-toggle="dropdown">
        ...
      </a>

      <div className="dropdown-menu">
        <Link to="/expenses"
          onClick={() => dispatch(deleteExpense(building._id, expense._id))}
          className="delete-item dropdown-item">
          <i className="fas fa-trash-alt text-danger mr-2"></i>delete
        </Link>
        <a href="#!"
          className="edit-item dropdown-item"
          data-toggle="modal" data-target="#expenseForm"
          onClick={() => dispatch(getExpenseDetails(expense._id))}>
          <i className="far fa-edit text-success mr-2"></i>edit
        </a>
      </div>
    </>
  )



  return (
    <tr className={isMarked && userInfo.role === 'admin' ? 'bg-warning' : ''}>
      <td>
        <small>
          <Moment format="DD/MM/YYYY">{expense.createdAt}
          </Moment>
        </small>
      </td>
      <td>{truncate(expense.subject, 10)}</td>
      {/* <td>{truncate(capitalizeFirst(expense.title), 30)}</td> */}
      {/* <td>{truncate(capitalizeFirst(expense.company), 50)}</td> */}
      <td className="list-group" style={{ minWidth: '160px' }} onClick={() => dispatch(getExpenseDetails(expense._id))}>
        {!expense.description.length ? (
          dummyText()
        ) : expense.description.length > 50 ? (
          <>
            <div dangerouslySetInnerHTML={{
              __html: (
                htmlHandleChars(truncate(capitalizeFirst(expense.description), 50)) + `<a href="#!" data-toggle="modal" data-target="#showInfoExpense" class="ml-2"><i class="fas fa-info-circle text-info"></i></a>`
              )
            }}>
            </div>
          </>
        ) : (
          expense.description
        )}
      </td>
      <td className={expense.category === 'Expense' ? 'text-danger' : ''} style={{ minWidth: '100px' }}>
        {amountRegex(expense.price)}
      </td>
      <td style={{ minWidth: '70px' }}>
        {expense && expense.images.map(image => (
          image.originalname.split('.')[1] === 'pdf' ? (
            // pdf
            <a href={image.secure_url} key={image._id} target="_blank" rel='noreferrer'>
              <i className="fa-solid fa-file-pdf fa-lg mr-2"></i>
            </a>
          ) : (
            // jpg
            <a href={image.secure_url} key={image._id} data-lightbox="cases" data-gallery="img-gallery">
              <i className="fa-solid fa-images fa-lg mr-2"></i>
            </a>
          )
        ))}
      </td>
      {/* <td className="text-muted">{userInfo.firstName.substring(0, 3)}</td> */}
      <td style={{ minWidth: "40px" }}>
        {/* EDIT & DELETE */}
        {userInfo._id !== expense.user._id ? (
          userInfo._id === expense.user && (  // this happend because after delete, the id field change
            showEditDeleteDropdown
          )
        ) : (
          showEditDeleteDropdown
        )}
      </td>
      <td>
        {userInfo.role === 'admin' && (
          <Link to="#!" onClick={() => dispatch(markExpense(expense._id, building._id))}>
            <i className={`fa-solid fa-eye ${isMarked ? 'text-muted' : 'text-warning'}`}></i>
          </Link>
        )}
      </td>

    </tr>
  )
}

export default ExpenseItem
