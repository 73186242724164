import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// import Moment from 'react-moment'
import Alert from '../components/Alert'
import Spinner from '../components/Spinner'
import Moment from 'react-moment'
import PostItem from '../components/posts/PostItem'
import CommentForm from '../components/comments/CommentForm'
import CommentItem from '../components/comments/CommentItem'
import Paginate from '../components/Paginate'
import VoteForm from '../components/votes/VoteForm'
import UploadImageForm from '../components/posts/UploadImageForm'
import { getPostDetails, listVotesPost } from '../actions/postActions'
import { listComments } from '../actions/commentActions'
import { setAlert } from '../actions/alertActions'
import { updateCountdown } from '../actions/countdownActions'
import { deniedBannedUser } from '../actions/buildingActions'
import { POST_CREATE_RESET, POST_UPDATE_RESET } from '../constants/postConstants'
import { truncate } from '../components/helper/utils'
import { logout } from '../actions/userActions'


const PostDetailsPage = ({ match, history }) => {

  const dispatch = useDispatch();

  const fileInput = useRef();

  const postId = match.params.id;
  const pageNumber = match.params.pageNumber;

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const postDetails = useSelector(state => state.postDetails);
  const { post, error: errorPostDetails } = postDetails;

  const postCreate = useSelector(state => state.postCreate);
  const { success: successCreated } = postCreate;

  const postUpdate = useSelector(state => state.postUpdate);
  const { success: successPostUpdate } = postUpdate;

  const commentList = useSelector(state => state.commentList);
  const { comments, loading: loadingCommentList, page, pages, numComments, plusOne, minusOne } = commentList; // plusOne helps to immediate number change added comment

  const commentDetails = useSelector(state => state.commentDetails);
  const { comment, loading: loadingCommentDetails } = commentDetails;

  const postVoteList = useSelector(state => state.postVoteList);
  const { loading: loadingVoteList, haveVoted } = postVoteList;

  const postVoteCreate = useSelector(state => state.postVoteCreate);
  const { error: errorVote } = postVoteCreate;

  const postCountdownUpdate = useSelector(state => state.postCountdownUpdate);
  const { timeLeft } = postCountdownUpdate;

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building, error: errorBuildingDetails } = buildingDetails;

  const alertAction = useSelector(state => state.alertAction);
  const { alert } = alertAction;



  useEffect(() => {
    if (userInfo) {
      dispatch(deniedBannedUser(building, userInfo, history));

      if (userInfo.community === 'no-group') {
        return history.push('/new-community');
      }
    }

    if (!userInfo || errorPostDetails === 'Token is no longer valid' || errorBuildingDetails === 'Token is no longer valid') {
      dispatch(logout())
    }

    if (post) {
      // const time = updateCountdown(post.finalVote);
      dispatch(updateCountdown(post.finalVote));
      // setCountDownTime(timeLeft);
    }

    if (errorVote) {
      dispatch(setAlert(errorVote, 'danger'));
    }

    // success helps to immediate change the post's content because i don't put the SUCCESS constant in postDetailsProducer instead i want to use REQUEST
    if ((!post || post._id !== postId || successCreated || successPostUpdate) && building) {
      // history need to push user, who trying to access post from different channel
      dispatch(getPostDetails(postId, history));
      dispatch({ type: POST_CREATE_RESET });  // prevent endless loop
      dispatch({ type: POST_UPDATE_RESET });  // prevent endless loop
      dispatch(listComments(postId, pageNumber)); // must be inside here otherwise bad flash loading
    }

    dispatch(listVotesPost(postId));

  }, [dispatch, history, building, userInfo, postId, post, successPostUpdate, successCreated, pageNumber, errorVote, errorPostDetails, errorBuildingDetails])

  // const openUpload = () => {
  //   fileInput.current.click();
  // }

  const clearImgFiles = () => {
    document.getElementById('uploadImages').value = null
  }

  const [voteToggle, setVoteToggle] = useState(false);

  return (
    // !post is import otherwise post.category is undefined
    // loadingCommentList || loadingPostDetails || loadingVoteList || !post ? <Spinner /> : (
    !post ? <Spinner /> : (
      <div className="content">
        <div className="row" >
          {/* FIRST COLUMN ######################################################### */}
          {/* POST ITEM  ##############*/}
          <div className="col-md-9">
            <div className="card mb-3">
              <div className="card-body">
                <PostItem
                  post={post}
                  showAction={true}
                  userInfo={userInfo} />
              </div>
            </div>

            {/* UPLOAD IMAGE/FILE BUTTON ##############*/}
            {/* --hidden upload input-- */}
            <div className="mb-5 float-right">
              {userInfo._id === post.user._id && (
                <>
                  <form className="float-left">
                    <input type="file" ref={fileInput} name="images" multiple hidden />
                  </form>
                  <button data-toggle="modal" data-target="#uploadImageForm" className="btn btn-sm btn-info" onClick={clearImgFiles}>
                    Upload <i className="fas fa-image"></i>
                  </button>
                </>
              )}
              {/* COMMENT FORM */}
              <button
                data-toggle="modal"
                data-target="#commentForm"
                className="btn btn-sm btn-primary mx-2"
                disabled={userInfo.isGuest}
              >
                <i className="fa fa-plus"></i> Add Comment
              </button>
            </div>
            <div className="clearfix"></div>

            {/* MODALS FORM ##############*/}
            <CommentForm post={post} comment={comment} loadingCommentDetails={loadingCommentDetails} />
            {/* Modal Upload Image form */}
            <UploadImageForm />

            {/* LIST OF COMMENTS ##############*/}
            <div>

              {loadingCommentList ? <Spinner /> : (
                comments && comments.length ? (
                  comments.map(comment => (
                    <CommentItem
                      key={comment._id}
                      comment={comment}
                      userInfo={userInfo}
                      postId={postId}
                    />
                  ))
                ) : (
                  <p className="lead text-muted text-center">-------Still no comments -------</p>
                )
              )}
              <Paginate
                page={page}
                pages={pages}
                postId={postId}  // because the page need post id
                isCommentPage={true} />
            </div>
          </div>

          {/* SECOND COLUMN ######################################################### */}
          <div className="col-md-3">
            <div className="mb-3" >
              <ul className="list-group">
                <li className="list-group-item">
                  {/* Star Ratings average */}
                  <span className="avgRatings">
                    {post.ratingsAverage}{' '}
                    <i className="fas fa-star" style={{ color: 'goldenrod' }}></i>
                  </span>

                  {/* LINK MORE INFO VOTE ##############*/}
                  <div style={{ float: "right" }}>
                    <Link to={`/posts/${post._id}/votes`} className="ml-3" style={{ textDecoration: 'none' }}>
                      More <i className="fas fa-info-circle"></i>
                    </Link>
                  </div>
                </li>

                {/* TOTAL COMMENTS ##############*/}
                <li className="list-group-item">
                  <i className="far fa-comments text-success"></i> &nbsp;
                  {numComments || plusOne || minusOne || ' 0'} comment{`${numComments > 1 ? 's' : ''}`}
                </li>

                {/* COUNTDOWN RESULTS ##############*/}
                <li className="list-group-item">Category: {post.category}</li>
                <li className="list-group-item text-danger countdownBox">
                  {timeLeft && (
                    <>
                      <span id="days">{!timeLeft.days ? '00 : ' : timeLeft.days}</span>
                      <span id="hours">{!timeLeft.hours ? '00 : ' : timeLeft.hours}</span>
                      <span id="minutes">{!timeLeft.minutes ? '00 : ' : timeLeft.minutes}</span>
                      <span id="seconds">{!timeLeft.seconds ? '00' : timeLeft.seconds}</span>
                    </>
                  )}
                </li>
              </ul>
            </div>


            {/* VOTING RESULTS ##############*/}
            <div className="my-4">
              {loadingVoteList ? <Spinner /> : (
                post.toVote && new Date(post.finalVote).getTime() > new Date().getTime() ? (
                  // user haven't voted yet
                  !haveVoted ? (
                    <div>
                      <p className="lead mt-2">Please help us making decision.</p>
                      <button className="btn btn-info mb-5" disabled={voteToggle} onClick={() => setVoteToggle(true)}>I want to vote</button>

                      {alert && <Alert msg={alert.msg} />}
                      {voteToggle && (
                        <VoteForm postId={postId} post={post} />
                      )}
                    </div>
                  ) : (
                    // user have voted already
                    <>
                      <p className="text-center">Thanks for Voting !</p>
                      <div className="text-center">
                        <i className="fas fa-check-circle fa-4x"></i>
                      </div>
                    </>
                  )
                ) : post.finalVote !== null && new Date(post.finalVote).getTime() < new Date().getTime() ? (
                  <>
                    <p className="text-center">You missed this vote !</p>
                    <div className="text-center">
                      <i className="far fa-times-circle fa-4x"></i>
                    </div>
                  </>
                ) : (
                  // Post is not to vote
                  <span></span>
                )
              )}

            </div>



            {/* UPLOADED IMAGES ##############*/}
            {/* <div className={`${post.images.length > 3 && 'd-flex justify-content-center'}`}> */}
            {/* {loadingPostImageUpload ? <Spinner /> : ( */}
            <div className="row">
              <div className="col-md">
                <div>
                  {post.images.map(image => (
                    <div key={image._id} className="card float-left mr-3 mb-2 p-2">
                      <a href={image.url} data-lightbox="cases" data-gallery="img-gallery">
                        <img src={image.url} alt="cloudinary" style={{ width: '65px', height: '65px' }} className="image-fluid" />
                      </a>
                    </div>
                  ))}
                </div>
                {/* )} */}
                {/* </div> */}
              </div>
            </div>

            {/* UPLOADED PDF FILES */}
            <div className="row">
              <div className="col-md mt-3">
                {post.files.map(file => (
                  <li className='mb-2' key={file._id}>
                    <a href={`${file.url}`} target="_blank" rel="noreferrer" data-heigth="560"
                      data-width="560" style={{ textDecoration: 'none' }}>
                      <i className="fa-solid fa-file-pdf fa-2x" style={{ color: 'red' }}></i> &nbsp;
                      <span>
                        {truncate(file.originalname, 39)}
                      </span>
                    </a>
                  </li>
                ))}
              </div>
            </div>

            {/* In case pose canceled */}
            {post.isCancelVote && (
              <div className="bg-danger text-white p-4">
                <p>This vote was canceled on <Moment format="DD/MM/YYYY">{post.isCancelVote}</Moment> Pevious details:</p>
                <span>Start date: &nbsp;<Moment format="DD/MM/YYYY">{post.startVote}</Moment></span> <br />
                <span>Final date: &nbsp;<Moment format="DD/MM/YYYY">{post.finalVote}</Moment></span>
              </div>
            )}
          </div>
        </div>
      </div >
    )
  )
}

export default PostDetailsPage
