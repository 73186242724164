import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { clearExpenseDetails, updateExpense, createExpense } from '../../actions/expenseActions'
import { capitalizeFirst, formatDate } from '../helper/utils'


// props from ExpensePage
const ExpenseForm = ({ expense, loadingExpDetails, history }) => {
  const [images, setImages] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    price: '',
    company: '',
    category: '',
    subject: '',
    description: '',
    createdAt: ''
  })

  const dispatch = useDispatch();
  const { title, price, company, category, subject, description, createdAt } = formData;

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building } = buildingDetails;

  useEffect(() => {
    if (expense) {
      setFormData({
        title: loadingExpDetails || !expense.title ? '' : expense.title,
        price: loadingExpDetails || !expense.price ? '' : expense.price,
        company: loadingExpDetails || !expense.company ? '' : expense.company,
        category: loadingExpDetails || !expense.category ? '' : expense.category,
        subject: loadingExpDetails || !expense.subject ? '' : expense.subject,
        description: loadingExpDetails || !expense.description ? '' : expense.description,
        createdAt: loadingExpDetails || !expense.createdAt ? '' : formatDate(expense.createdAt, 'toISOString')
      })
    } else {
      // clear the data from form first to create new expense
      setFormData({
        title: '',
        price: '',
        company: '',
        category: '',
        subject: '',
        description: '',
        createdAt
      })
    }
  }, [expense, loadingExpDetails])

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    let formData = new FormData();
    const files = images;

    // get delete images if any checked
    const deleteImages = document.querySelectorAll('.imageDeleteCheckbox:checked');

    for (const file of deleteImages) {
      formData.append('deleteImages[]', file.value);
    }


    if (files && files.length) {
      for (const file of images) {
        formData.append('images', file)
      }
    }

    formData.append('title', title);
    formData.append('price', price);
    formData.append('company', company);
    formData.append('category', category);
    formData.append('subject', subject);
    formData.append('description', description);
    formData.append('createdAt', createdAt);

    if (expense) {
      dispatch(updateExpense(building._id, expense._id, formData));
      dispatch(clearExpenseDetails());
    } else {
      dispatch(createExpense(building._id, formData));
      setFormData({
        title: '',
        price: '',
        company: '',
        category: '',
        subject: '',
        description: '',
        createdAt: ''
      })
    }

    document.getElementById('uploadImages').value = null;
    setImages([])
    document.querySelector('#close').click();
  }

  return (
    <div className="modal fade p-4" id="expenseForm">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header bg-primary text-white">
            <h5 className="modal-title">{expense ? 'Edit Expense' : 'New Expense'}</h5>
            <button className="close" id="close" data-dismiss="modal">
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {/* ADD EXP FORM */}
            <form className="mb-5" onSubmit={onSubmit}>
              <div className="form-group mb-3">
                <small>Title:</small>
                <input type="text" className="form-control" name="title" value={capitalizeFirst(title)} onChange={onChange} />
              </div>
              <div className='form-group mb-3'>
                <small>Created at:</small>
                <input type="date" className='form-control' name="createdAt" value={createdAt} onChange={onChange} />
              </div>
              <div className="form-group mb-3">
                <small>Amount:</small>
                <input type="number" name="price" step="0.1" value={price} className="form-control" placeholder="฿ 0.0" onChange={onChange} />
              </div>
              <div className="form-group mb-3">
                <small>Pay to / Income from :</small>
                <input type="text" name="company" value={capitalizeFirst(company)} className="form-control" onChange={onChange} />
              </div>
              <div className="form-group mb-3">
                <small>Expense / Income ?</small>
                <select name="category" value={category} className="form-control" onChange={onChange}>
                  <option value="none" className="category">.... .... ....</option>
                  <option value="Expense" className="category">Expense</option>
                  <option value="Income" className="category">Income</option>
                </select>
              </div>
              <div className="form-group mb-3">
                <div className="btn-group">
                  <div className="btn-group dropright">
                    <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Choose Option</button>
                    {/* Chosen subject */}
                    <p className="ml-3 text-muted">{subject}</p>

                    <div className="dropdown-menu" id="xx" name="subject" value={subject} onClick={e => {
                      setFormData({ ...formData, subject: e.target.innerText });
                    }}>
                      {category && category === 'Income' && (
                        <div>
                          <small className="p-2 text-muted display-5">Annual:</small>
                          <a href="#!" value="Interest" className="dropdown-item">Interest</a>
                          <a href="#!" className="dropdown-item">Common fee</a>
                          <a href="#!" className="dropdown-item">Other income</a>
                        </div>
                      )}

                      {category && category === 'Expense' && (
                        <div>
                          <small className="p-2 text-muted display-5">Common Area:</small>
                          <a href="#!" className="dropdown-item">Pool</a>
                          <a href="#!" className="dropdown-item">Garden</a>
                          <a href="#!" className="dropdown-item">Fitness</a>
                          <a href="#!" className="dropdown-item">Security</a>
                          <a href="#!" className="dropdown-item">Other area</a>
                          <div className="dropdown-divider"></div>
                          <small className="p-2 text-muted display-5">Consumes:</small>
                          <a href="#!" className="dropdown-item">Internet</a>
                          <a href="#!" className="dropdown-item">Electric</a>
                          <a href="#!" className="dropdown-item">Water</a>
                          <a href="#!" className="dropdown-item">Product</a>
                          <a href="#!" className="dropdown-item">Other consume</a>
                          <div className="dropdown-divider"></div>
                          <small className="p-2 text-muted display-5">Legal Fees:</small>
                          <a href="#!" className="dropdown-item">Tax</a>
                          <a href="#!" className="dropdown-item">Bank</a>
                          <a href="#!" className="dropdown-item">Lawyer</a>
                          <a href="#!" className="dropdown-item">Other legal</a>
                          <div className="dropdown-divider"></div>
                          <small className="p-2 text-muted display-5">Salary:</small>
                          <a href="#!" className="dropdown-item">Maid</a>
                          <a href="#!" className="dropdown-item">Guard</a>
                          <a href="#!" className="dropdown-item">Manager</a>
                          <a href="#!" className="dropdown-item">Other salary</a>
                        </div>
                      )}

                      {!category && (
                        <span href="#!" className="dropdown-item px-2" style={{ width: '250px' }}>Select category above first !</span>
                      )}
                    </div>
                  </div>
                </div>
                <p id="selected-add-subject" className="py-3 text-muted"></p>
              </div>
              <div className="mb-3">
                {/* Upload button */}
                <div className='form-group mb-2 w-50'>
                  <input
                    type="file"
                    id="uploadImages"
                    name="images"
                    accept="image/*"
                    multiple
                    onChange={e => setImages(e.target.files)}
                  />
                </div>

                {/* uploaded images */}
                <div>
                  {expense && expense.images.map((item, i) => (
                    <span key={item._id}>
                      <img
                        src={item.secure_url} alt={i}
                        className="mr-1 my-2"
                        style={{ height: '30px', width: '30px' }}
                      />
                      <input
                        type="checkbox"
                        name="deleteImages[]"
                        id={i}
                        value={item.public_id}
                        className="imageDeleteCheckbox mr-4"
                      />
                    </span>
                  ))}
                </div>
              </div>
              <div className="form-group mb-3">
                <small>Description:</small>
                <textarea name="description" value={capitalizeFirst(description)} className="form-control" cols="30" rows="3" onChange={onChange}></textarea>
              </div>
              <div className="float-right">
                <input type="submit"
                  value={expense ? 'Update Expense' : 'Add Expense'}
                  className="btn btn-primary"
                />
              </div>
              <div className="float-right mx-3">
                <button type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => dispatch(clearExpenseDetails())}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExpenseForm
