import axios from 'axios'
import {
  ADMIN_AUTH_REQUEST,
  ADMIN_AUTH_SUCCESS,
  ADMIN_AUTH_FAIL,
  ADMIN_FULL_GRANTED_REQUEST,
  ADMIN_FULL_GRANTED_SUCCESS,
  ADMIN_FULL_GRANTED_FAIL,
  ADMIN_AUTH_RESET,
  ADMIN_FULL_GRANTED_RESET,
  ADMIN_POST_DELETE_REQUEST,
  ADMIN_POST_DELETE_SUCCESS,
  ADMIN_POST_DELETE_FAIL,
  ADMIN_EMAIL_LIST_REQUEST,
  ADMIN_EMAIL_LIST_SUCCESS,
  ADMIN_EMAIL_LIST_FAIL,
  ADMIN_EMAIL_DETAILS_REQUEST,
  ADMIN_EMAIL_DETAILS_SUCCESS,
  ADMIN_EMAIL_DETAILS_FAIL,
  ADMIN_EMAIL_RESPONSE_REQUEST,
  ADMIN_EMAIL_RESPONSE_SUCCESS,
  ADMIN_EMAIL_RESPONSE_FAIL,
  ADMIN_EMAIL_READ_SUCCESS,
  ADMIN_EMAIL_READ_FAIL,
  ADMIN_EMAIL_TICKET_LIST_REQUEST,
  ADMIN_EMAIL_TICKET_LIST_SUCCESS,
  ADMIN_EMAIL_TICKET_LIST_FAIL,
  ADMIN_EMAIL_LIST_RESET,
} from '../constants/adminConstants'
import {
  POST_LIST_SUCCESS,
  POST_LIST_FAIL
} from '../constants/postConstants';
import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
} from '../constants/userConstants'




export const adminLogin = (formData, history) => async (dispatch, getState) => {
  dispatch({ type: ADMIN_FULL_GRANTED_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    // this data is token
    const { data } = await axios.post(`/api/admin/login`, formData, config);

    dispatch({
      type: ADMIN_FULL_GRANTED_SUCCESS,
      payload: data
    })

    // means have token
    if (data) {
      history.push(`/admin/users/${data}`)
    }

    // token is valid for 30 minutes
    localStorage.setItem('grantedToken', JSON.stringify(data));

  } catch (error) {
    // one time error than push directly to /
    if (error.response && error.response.data.message) {
      history.push('/');
    }

    dispatch({
      type: ADMIN_FULL_GRANTED_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })

    dispatch({ type: ADMIN_AUTH_RESET })
    dispatch({ type: ADMIN_FULL_GRANTED_RESET })
  }
}

export const adminLogout = () => dispatch => {
  dispatch({ type: ADMIN_FULL_GRANTED_RESET })
  dispatch({ type: ADMIN_AUTH_RESET });
  dispatch({ type: ADMIN_EMAIL_LIST_RESET })
}

// Enter the login form page
export const adminAuthenticate = (history) => async (dispatch, getState) => {
  dispatch({ type: ADMIN_AUTH_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    // data is token
    const { data } = await axios.get(`/api/admin/login`, config);

    dispatch({
      type: ADMIN_AUTH_SUCCESS,
      payload: data
    })

    window.location.assign(`/admin/authentication/${data}/IiwiaWF0IjoxNjI0MTY5NjAzLC`);


  } catch (error) {
    dispatch({
      type: ADMIN_AUTH_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const adminListUsers = (building, pageNumber) => async (dispatch, getState) => {
  dispatch({ type: USER_LIST_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    const { data } = await axios.get(`/api/admin/users?building=${building}&pageNumber=${pageNumber}`, config);
    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data
    })


  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

export const getAllPosts = (pageNumber) => async (dispatch, getState) => {

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    const { data } = await axios.get(`/api/admin/posts?pageNumber=${pageNumber}`, config);
    dispatch({
      type: POST_LIST_SUCCESS,
      payload: data
    })


  } catch (error) {
    dispatch({
      type: POST_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

export const adminlistEmails = () => async (dispatch, getState) => {
  dispatch({ type: ADMIN_EMAIL_LIST_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`/api/admin/emails`, config);
    dispatch({
      type: ADMIN_EMAIL_LIST_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: ADMIN_EMAIL_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const adminDeletePost = (id) => async (dispatch, getState) => {
  dispatch({ type: ADMIN_POST_DELETE_REQUEST })

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    await axios.delete(`/api/admin/posts/${id}`, config);

    dispatch({
      type: ADMIN_POST_DELETE_SUCCESS,
      payload: id
    })

  } catch (error) {
    dispatch({
      type: ADMIN_POST_DELETE_FAIL,
      payload: error.response && error.response.message ? error.response.data.message : error.message
    })
  }
}

export const getEmailDetails = (id) => async (dispatch, getState) => {
  dispatch({ type: ADMIN_EMAIL_DETAILS_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`/api/admin/emails/${id}`, config);
    dispatch({
      type: ADMIN_EMAIL_DETAILS_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: ADMIN_EMAIL_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const responseEmail = (id, ticket, formData) => async (dispatch, getState) => {
  dispatch({ type: ADMIN_EMAIL_RESPONSE_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.post(`/api/admin/emails/${id}/${ticket}`, formData, config);
    dispatch({
      type: ADMIN_EMAIL_RESPONSE_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: ADMIN_EMAIL_RESPONSE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const markEmailRead = (id) => async (dispatch, getState) => {
  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    await axios.put(`/api/admin/emails/${id}`, {}, config);
    dispatch({
      type: ADMIN_EMAIL_READ_SUCCESS
    })
  } catch (error) {
    dispatch({
      type: ADMIN_EMAIL_READ_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const getEmailsSameTicket = (id, ticket) => async (dispatch, getState) => {
  dispatch({ type: ADMIN_EMAIL_TICKET_LIST_REQUEST })

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`/api/emails/${id}/ticket/${ticket}`, config);
    dispatch({
      type: ADMIN_EMAIL_TICKET_LIST_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: ADMIN_EMAIL_TICKET_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}





