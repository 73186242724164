import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GeneralForm from '../../components/building/GeneralForm'
import GymForm from '../../components/building/GymForm'
import AnimalForm from '../../components/building/AnimalForm'
import PoolForm from '../../components/building/PoolForm'
import ExteriorForm from '../../components/building/ExteriorForm'
import { setAlert } from '../../actions/alertActions'
import { BUILDING_RULE_UPDATE_RESET } from '../../constants/buildingConstants'



const RegulationPage = () => {

  const buildingRuleUpdate = useSelector(state => state.buildingRuleUpdate);
  const { success, error } = buildingRuleUpdate;

  const dispatch = useDispatch()

  useEffect(() => {
    if (success) {
      dispatch(setAlert('Information updated !', 'success'));
      setTimeout(() => {
        dispatch({ type: BUILDING_RULE_UPDATE_RESET })
      }, 3000)

    } else if (error) {
      dispatch(setAlert(error, 'danger'));
      dispatch({ type: BUILDING_RULE_UPDATE_RESET })
    }
  }, [dispatch, success, error])

  return (
    <div className="row">
      <div className="col-md">
        {/* TAB MENU */}
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" id="general-tab" data-toggle="tab" href="#general" role="tab" aria-controls="general" aria-selected="true">General</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="gym-tab" data-toggle="tab" href="#gym" role="tab" aria-controls="gym" aria-selected="false">Fitness</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="pet-tab" data-toggle="tab" href="#pet" role="tab" aria-controls="pet" aria-selected="false">Pet</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="pool-tab" data-toggle="tab" href="#pool" role="tab" aria-controls="pool" aria-selected="false">Pool</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="exterior-tab" data-toggle="tab" href="#exterior" role="tab" aria-controls="exterior" aria-selected="false">Exterior</a>
          </li>
        </ul>

        {/* TAB CONTENT */}
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
            <GeneralForm />
          </div>
          <div className="tab-pane fade" id="gym" role="tabpanel" aria-labelledby="gym-tab">
            <GymForm />
          </div>
          <div className="tab-pane fade" id="pet" role="tabpanel" aria-labelledby="pet-tab">
            <AnimalForm />
          </div>
          <div className="tab-pane fade" id="pool" role="tabpanel" aria-labelledby="pool-tab">
            <PoolForm />
          </div>
          <div className="tab-pane fade" id="exterior" role="tabpanel" aria-labelledby="exterior-tab">
            <ExteriorForm />
          </div>
        </div>
      </div>
    </div>

  )
}

export default RegulationPage
