import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'


const PrivateRoute = ({
  component: Component,
  userLogin: { userInfo, loading },
  ...rest
}) => {

  return (
    <Route {...rest} render={props => (
      !userInfo && !loading
        ? <Redirect to="/login" />
        : <Component {...props} />
    )} />
  )
}

const mapStateToProps = state => ({
  userLogin: state.userLogin
})

export default connect(mapStateToProps)(PrivateRoute)
