import React from 'react'
// import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { findHttps, htmlHandleChars } from '../helper/utils';
import { truncate } from '../../helper/utils';


const ExpenseInfo = () => {
  const expenseDetails = useSelector(state => state.expenseDetails);
  const { expense } = expenseDetails;

  return (
    <div className="modal fade p-0" id="showInfoExpense">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            {/* <h3 className="modal-title" id="info-modal-title">
            
          </h3> */}
            <button className="close" data-dismiss="modal">
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body" id="info-modal-body">
            {/* <!-- expense 's description & company' --> */}

            {/* <p>{`Company: ${expense && expense.company}`}</p> */}

            {expense && (
              <div dangerouslySetInnerHTML={{
                __html: htmlHandleChars(truncate(
                  findHttps(expense.description) ? (
                    `${expense.description.split('https://www.baaninfo.com')[0]} ${`<a href=${expense.description.split('https://www.baaninfo.com')[1]} class="btn btn-info btn-sm">Go to post</a>`} 
                    `
                  ) : (
                    expense.description
                  )
                  , 8000))
              }}>
              </div>
              // findHttps(expense.description) ? (
              //   <>
              //     {expense.description.split('https://www.baaninfo.com')[0]} &nbsp;
              //     {<a className='btn btn-info btn-sm' href={expense.description.split('https://www.baaninfo.com')[1]}>
              //       Go to post
              //       {/* {expense.description.split('https://www.baaninfo.com')[1]} */}
              //     </a>}
              //     {/* <Link to="#!">{expense.description}</Link> */}
              //   </>
              // ) : (
              //   expense.description
              // )
            )}
          </div>
        </div>
      </div>
    </div >
  )
}

export default ExpenseInfo
