import axios from 'axios'
import {
  VIDEO_CREATE_REQUEST,
  VIDEO_CREATE_SUCCESS,
  VIDEO_CREATE_FAIL,
  VIDEO_UPDATE_REQUEST,
  VIDEO_UPDATE_SUCCESS,
  VIDEO_UPDATE_FAIL,
  VIDEO_LIST_REQUEST,
  VIDEO_LIST_SUCCESS,
  VIDEO_LIST_FAIL,
  VIDEO_DETAILS_REQUEST,
  VIDEO_DETAILS_SUCCESS,
  VIDEO_DETAILS_FAIL,
  VIDEO_DETAILS_RESET,
  VIDEO_SELECTED_SUCCESS,
  VIDEO_SELECTED_REQUEST,
  VIDEO_DELETE_FAIL,
  VIDEO_REMOVE_REQUEST,
  VIDEO_REMOVE_SUCCESS
} from '../constants/videoConstants'


export const getVideoDetails = id => async dispatch => {
  dispatch({ type: VIDEO_DETAILS_REQUEST })

  try {
    const { data } = await axios.get(`/api/videos/${id}`);

    dispatch({
      type: VIDEO_DETAILS_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: VIDEO_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const listVideos = () => async (dispatch, getState) => {
  dispatch({ type: VIDEO_LIST_REQUEST })

  // const { userInfo } = getState().userLogin;

  try {
    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${userInfo.token}`
    //   }
    // }

    const { data } = await axios.get(`/api/videos`)

    dispatch({
      type: VIDEO_LIST_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: VIDEO_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const createVideo = (formData) => async (dispatch, getState) => {
  dispatch({ type: VIDEO_CREATE_REQUEST })

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.post('/api/videos', formData, config)

    dispatch({
      type: VIDEO_CREATE_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: VIDEO_CREATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const updateVideo = (formData, id) => async (dispatch, getState) => {
  dispatch({ type: VIDEO_UPDATE_REQUEST })

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/videos/${id}`, formData, config)

    dispatch({
      type: VIDEO_UPDATE_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: VIDEO_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}


// this is just to hide video
export const removeVideo = id => async (dispatch, getState) => {
  dispatch({ type: VIDEO_REMOVE_REQUEST })

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/videos/${id}/hide`, {}, config)

    dispatch({
      type: VIDEO_REMOVE_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: VIDEO_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}


export const selectVideo = (video) => dispatch => {
  dispatch({ type: VIDEO_SELECTED_REQUEST })
  dispatch({
    type: VIDEO_SELECTED_SUCCESS,
    payload: video
  });
}

export const clearVideoDetails = () => dispatch => {
  dispatch({ type: VIDEO_DETAILS_RESET });
}


