import React, { useState } from 'react'

const SearchBox = ({ history }) => {
  const [keyword, setKeyword] = useState('');

  const onSubmitHandler = e => {
    e.preventDefault();
    if (keyword.trim()) {
      history.push(`/posts/search/${keyword}`);
    } else {
      history.push('/posts');
    }
  }

  return (
    <div className="row">
      <div className="col-md-6">
        <button type="submit" className="btn btn-primary" onClick={onSubmitHandler}>
          <i className="fas fa-search"></i>
        </button>
        <form
          className="form-group float-left mr-2"
          style={{ width: '250px' }}
          onSubmit={onSubmitHandler}>
          <input type="text"
            value={keyword}
            className="form-control"
            placeholder="keyword to search"
            onChange={e => setKeyword(e.target.value)} />
        </form>
      </div>
    </div>
  )
}

export default SearchBox
