import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listPostsForReport } from '../actions/postActions'
import { convertToMonthString } from '../helper/utils'
import Moment from 'react-moment'
import { deniedBannedUser } from '../actions/buildingActions'
import { logout } from '../actions/userActions'



const ReportPage = ({ history }) => {
  const [reportYear, setReportYear] = useState()

  const dispatch = useDispatch();


  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const postListReport = useSelector(state => state.postListReport);
  const { posts, error } = postListReport;

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building } = buildingDetails;


  useEffect(() => {
    dispatch(deniedBannedUser(building, userInfo, history));

    if (userInfo.community === 'no-group') {
      return history.push('/new-community');
    }

    if (error === 'Token is no longer valid') {
      dispatch(logout())
    }

    dispatch(listPostsForReport())

    setReportYear(new Date().getFullYear())


  }, [dispatch, history, building, error, userInfo])

  const selectYearHandler = (e) => {
    setReportYear(e.target.value)
    dispatch(listPostsForReport(e.target.value))
  }

  // FOR YEAR SELECT OPTION 
  const expenseYear = () => {
    let elements = [];
    const thisYear = new Date().getFullYear();

    for (let i = 2021; i <= thisYear; i++) {
      elements.push(i);
    }
    return elements;
  }


  return (
    <div className='row' style={{ height: '100vh' }}>
      <div className="col-md-3" style={{ background: '#bee9fa' }}>
        <div className="report content">
          <div className='content-info'>
            <h3 className='text-center mb-5'>Income & Expenditures</h3>
            {/* {userInfo.role === 'admin' && ( */}
            <select name="reportYear" value={reportYear} className='form-control mb-4' onChange={selectYearHandler}>
              {expenseYear().map((year, index) => (
                <Fragment key={index}>
                  <option value={year}>{year}</option>
                </Fragment>
              ))}
            </select>
            {/* )} */}
            <table className='table table-primary table-striped mb-5'>
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Files</th>
                </tr>
              </thead>
              <tbody>
                {posts && posts.map(post => (
                  post.category === 'Expenditure' && !post.fixExpense && !post.savingBook && (
                    <tr key={post._id}>
                      <td>
                        <Link to={`/posts/${post._id}/comments`}>
                          {/* {convertToMonthString(post.reportMonth)} */}
                          {post.title}
                        </Link> <br />
                        <small className='text-muted'><Moment format="DD-MM-YYYY">{post.createdAt}</Moment></small>
                      </td>
                      <td>
                        {post.files && post.files.map(file => (
                          <a href={file.url} key={file._id} target="_blank" rel="noreferrer">
                            <i className="fa-solid fa-file-pdf fa-2x mr-2 text-danger"></i>
                          </a>
                        ))}
                      </td>
                    </tr>
                  )
                ))}
              </tbody>
            </table>
            <hr />

            {/* Expenses */}
            <div>
              <h5 className='mb-4'>Expenses</h5>
              <ul>
                {posts && posts.map(post => (
                  post.category === 'Expenditure' && post.fixExpense && (
                    <li key={post._id}>
                      <Link to={`/posts/${post._id}/comments`}>
                        {convertToMonthString(post.reportMonth)} &nbsp; &nbsp;
                      </Link>
                      {post.files && post.files.map(file => (
                        <a href={file.url} key={file._id} target="_blank" rel="noreferrer">
                          <i className="fa-solid fa-file-pdf fa-lg mr-2 text-danger"></i>
                        </a>
                      ))}
                    </li>
                  )
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3" style={{ background: '#fff2c9' }}>
        <div className="report content">
          <div className="content-info mb-5">
            <h3 className="text-center mb-5">Bank /<br /> Petty Cash</h3>
            <ul>
              {posts && posts.map(post => (
                post.category === 'Bank' && !post.savingBook && (
                  <li>
                    <Link to={`/posts/${post._id}/comments`}>{post.title}</Link> &nbsp;
                    {post.files && post.files.map(file => (
                      <a href={file.url} key={file._id} target="_blank" rel="noreferrer">
                        <i className="fa-solid fa-file-pdf fa-lg mr-2 text-info"></i>
                      </a>
                    ))}
                    <br />
                    <small className='text-muted'><Moment format="DD-MM-YYYY">{post.createdAt}</Moment></small>
                  </li>
                )
              ))}
            </ul>
            <hr />

            {/* Bank accounts */}
            <div>
              <h6 className='mb-4'>Saving Books</h6>
              <ul>
                {posts && posts.map(post => (
                  post.category === 'Bank' && post.savingBook && (
                    <li key={post._id}>
                      <Link to={`/posts/${post._id}/comments`}>
                        {convertToMonthString(post.reportMonth)} &nbsp; &nbsp;
                      </Link>
                      {post.files && post.files.map(file => (
                        <a href={file.url} key={file._id} target="_blank" rel="noreferrer">
                          <i className="fa-solid fa-file-pdf fa-lg mr-2 text-info"></i>
                        </a>
                      ))}
                    </li>
                  )
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3" style={{ background: '#c8d5db' }}>
        <div className="report content">
          <div className="content-info">
            <h3 className='text-center mb-5'>Letters / <br /> Announcement</h3>
            {posts && posts.map(post => (
              <ul key={post._id}>
                {post.category === 'Letter' && (
                  <li>
                    <Link to={`/posts/${post._id}/comments`}>{post.title}</Link> &nbsp;
                    {post.files && post.files.map(file => (
                      <a href={file.url} key={file._id} target="_blank" rel="noreferrer">
                        <i className="fa-solid fa-file-pdf fa-lg mr-2 text-info"></i>
                      </a>
                    ))}
                    <br />
                    <small className='text-muted'><Moment format="DD-MM-YYYY">{post.createdAt}</Moment></small>
                  </li>
                )}
              </ul>
            ))}
          </div>
        </div>
      </div>
      <div className="col-md-3" style={{ background: '#a8f0bb' }}>
        <div className="report content">
          <div className="content-info">
            <h3 className='text-center mb-5'>Minutes Of Meeting</h3>
            {posts && posts.map(post => (
              <ul key={post._id}>
                {post.category === 'Meeting' && (
                  <li>
                    <Link to={`/posts/${post._id}/comments`}>{post.title}</Link> &nbsp;
                    {post.files && post.files.map((file, i) => (
                      i < 1 && (
                        <a href={i < 1 ? file.url : `/posts/${post._id}/comments`} key={file._id} target="_blank" rel="noreferrer">
                          <i className="fa-solid fa-file-pdf fa-lg mr-2 text-info"></i>
                        </a>
                      )
                    ))}
                    <br />
                    <small className='text-muted'><Moment format="DD-MM-YYYY">{post.createdAt}</Moment></small>
                  </li>
                )}
              </ul>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportPage