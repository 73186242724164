import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'



const BannedPage = ({ history }) => {

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push('/');
    }
  }, [history, userInfo])

  return (
    <div className="row text-center justify-content-center align-items-center" style={{ minHeight: '80vh', fontWeight: '600px' }}>
      <div className="col-md-6 col-sm-6 col-lg-3 col-xl-4">
        <h1 className='x-large text-danger'>
          <i className="fas fa-ban"></i> You are banned !
        </h1>
        <div className="row">
          <div className="col-md">
            <p className='lead'>You are in the banned list of this community. This could be missunderstood. If you think this decision is wrong, please kindly contact the office to get unbanned.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannedPage;
