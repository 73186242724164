import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Alert from '../components/Alert'
import { createSecurityQuestion } from '../actions/userActions'


const SecurityCreatePage = ({ history }) => {
  const [formData, setFormData] = useState({
    question: '',
    answer: '',
    secretResetNumber: ''
  });

  const dispatch = useDispatch();

  const userSecurityCreate = useSelector(state => state.userSecurityCreate);
  const { success, loading, error } = userSecurityCreate;

  const { question, answer, secretResetNumber } = formData;

  useEffect(() => {
    if (success) {
      return history.push('/new-community')
      // dispatch({ type: USER_SECURITY_CREATE_RESET });
    }

  }, [history, success])

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const submitHandler = e => {
    e.preventDefault();
    dispatch(createSecurityQuestion(formData));
  }

  return (
    // loading ? <Spinner /> :
    <div className="container">
      <div className="row justify-content-center align-items-center h-100" style={{ minHeight: '80vh' }}>
        <div className="col-md-6 col-sm-6 col-lg-4 col-xl-4">

          {!loading && error && <Alert msg={error} />}

          <form onSubmit={submitHandler}>
            <div className="form-group">
              <select name="question" id="question" value={question} className="form-control" onChange={onChange}>
                <option value="null">Choose Question</option>
                <option value="What is your birthname ?">What is your birthname ?</option>
                <option value="What was your first job ?">What was your first job ?</option>
                <option value="Which city you were born ?">Which city you were born ?</option>
              </select>
            </div>
            <div className="form-group">
              <small>Answer</small>
              <input
                type="answer"
                name="answer"
                value={answer}
                maxLength="50"
                className="form-control"
                onChange={onChange} />
            </div>
            <div>
              <small>Please provide maximum 8 digits secret number</small>
              <input type="password" name="secretResetNumber" value={secretResetNumber} minLength="3" maxLength="8" className="form-control" onChange={onChange} />
            </div>
            <input type="submit" value="Submit" className="btn btn-primary btn-block mt-4" />
          </form>
        </div>
      </div>
    </div>
  )
}

export default SecurityCreatePage
