import React from 'react'
import { useDispatch } from 'react-redux'
import { markEmailRead, removeEmail } from '../../actions/userActions';

const EmailButton = ({ emailSelected, sentEmail }) => {
  const dispatch = useDispatch();

  const markSelectedAsRead = (emailSelected) => {
    const key = Object.keys(emailSelected);
    const value = Object.values(emailSelected);

    for (let i = 0; i < key.length; i++) {
      if (value[i]) {
        dispatch(markEmailRead(key[i]));
      }
    }
  }

  const removeEmailHandler = (emailSelected) => {
    const key = Object.keys(emailSelected);
    const value = Object.values(emailSelected);

    for (let i = 0; i < key.length; i++) {
      if (value[i]) {
        dispatch(removeEmail(key[i]));
      }
    }
  }


  return (
    <div className="float-right">
      {!sentEmail && (
        <button
          className="btn btn-sm btn-primary my-3"
          onClick={() => markSelectedAsRead(emailSelected)}
        >
          Mark as read
        </button>
      )}
      <button
        className="btn btn-sm btn-danger mx-3 my-3"
        onClick={() => removeEmailHandler(emailSelected)}
      >
        <i className="fas fa-trash"></i> Delete
      </button>
    </div>
  )
}

export default EmailButton
