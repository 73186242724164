import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

// updated

const Recaptcha = ({ recaptchaHandler }) => {

  return (
    <ReCAPTCHA
      sitekey="6Le9PwwaAAAAAGEIbNl1dlPplzYhZ9JfshYJo8WG"
      onChange={recaptchaHandler}
    />
  )
}

export default Recaptcha