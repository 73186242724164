import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Moment from 'react-moment'

import { markEmailRead } from '../../actions/adminAction'

const EmailItem = ({ email, index, userInfo }) => {

  const dispatch = useDispatch();

  const adminFullGranted = useSelector(state => state.adminFullGranted);
  const { token } = adminFullGranted;

  // const adminEmailRead = useSelector(state => state.adminEmailRead);
  // const { success: successEmailRead } = adminEmailRead;


  const link = `/admin/emails/${email._id}/ticket/${email.ticket}/${token}`;

  const markEmailReadHandler = () => {
    const line = document.querySelector(`.email-${index}`);
    const i = document.querySelector(`.email-${index}-icon`);
    line.classList.add('text-muted');
    i.classList.add('text-muted');
    i.classList.add('fas');
    i.classList.add('fa-envelope-open');

    dispatch(markEmailRead(email._id));
  }

  return (
    email.user && email.user.role !== 'admin' &&
    <tr>
      <td>
        <img src={email.user && email.user.avatar && email.user.avatar.url} alt={email.firstName} className="rounded-circle" style={{ width: '40px', height: '40px' }} />
      </td>
      <td style={{ width: '100px' }}><Moment format="DD-MM-YYYY">{email.createdAt}</Moment></td>
      <td>
        <Link
          to={link}
          onClick={markEmailReadHandler}
          className={`
            email-${index}
            ${email && email.isRead.map(item => (   // to muted & cahnge icon after refreshed
            item.user === userInfo._id && 'text-muted'
          ))}
          `}
        >
          {email.text}
        </Link>
      </td>
      <td>
        <Link to={`/admin/ticket/${email.ticket}/email/${email._id}/${token}`}>
          {email.ticket}
        </Link>
      </td>
      <td>{email.user.community}</td>
      <td>{email.user.email}</td>
      <td>
        <Link
          to={link}
          data-target='#supportEmailForm'
          data-toggle="modal"
          onClick={markEmailReadHandler}
        >
          <i className={`
          email-${index}-icon 
          fas fa-envelope text-primary
          ${email && email.isRead.map(item => (   // to muted & cahnge icon after refreshed
            item.user === userInfo._id ? 'fas fa-envelope-open text-muted' : 'fas fa-envelope text-primary'
          ))}
          `}>
          </i>
        </Link>
      </td>
    </tr>
  )
}

export default EmailItem
