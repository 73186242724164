import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'


const CommitteePage = () => {

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building } = buildingDetails;

  return (
    <div className="container">
      <div className="content">
        <div className="row">
          <div className="col-md">
            {/* Back button */}
            <Link to={`/building/${building && building._id}`} className="btn btn-primary mb-4">
              <i className="fas fa-chevron-circle-left"></i> Back
            </Link>
            <h2 className="text-center mb-5 text-secondary">Committees & Operators</h2>
          </div>
        </div>
        <div className="row justify-content-center mt-4">
          {building.operators.map((operator, i) => (
            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3" style={{ marginBottom: '40px' }}>
              <div className="card container" style={{ width: "14rem", border: 'none' }}>
                <div className="btnPopover" data-toggle="popover" data-trigger="hover" data-content={`
                I am ${operator.user.firstName}, 
                House: ${operator.user.houseNumber ? `${operator.user.houseNumber}` : 'unknown'}
                `}>
                  <img src={`${operator.user.avatar.url}`} className="card-img-top" alt={operator.firstName} />
                </div>
                {/* <button type="button" class="btn btn-secondary" data-container="body" data-toggle="popover" data-placement="bottom" data-content="Vivamus
sagittis lacus vel augue laoreet rutrum faucibus.">
                  Popover on bottom
                </button> */}
                <div className="card-body">
                  <h5 className="card-title text-center text-muted">
                    {operator.user.lastName}
                  </h5>
                  {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div >
  )
}

export default CommitteePage
