import {
  BUILDING_CREATE_REQUEST,
  BUILDING_CREATE_SUCCESS,
  BUILDING_CREATE_FAIL,
  BUILDING_CREATE_RESET,
  BUILDING_LIST_REQUEST,
  BUILDING_LIST_SUCCESS,
  BUILDING_LIST_FAIL,
  BUILDING_LIST_RESET,
  BUILDING_UPDATE_REQUEST,
  BUILDING_UPDATE_SUCCESS,
  BUILDING_UPDATE_FAIL,
  BUILDING_UPDATE_RESET,
  BUILDING_DETAILS_REQUEST,
  BUILDING_DETAILS_SUCCESS,
  BUILDING_DETAILS_FAIL,
  BUILDING_DETAILS_RESET,
  BUILDING_VERIFY_REQUEST,
  BUILDING_VERIFY_SUCCESS,
  BUILDING_VERIFY_FAIL,
  BUILDING_VERIFY_RESET,
  BUILDING_CHECK_PENDING_REQUEST,
  BUILDING_CHECK_PENDING_SUCCESS,
  BUILDING_CHECK_PENDING_FAIL,
  BUILDING_CHECK_PENDING_RESET,
  BUILDING_USERS_LIMIT_REQUEST,
  BUILDING_USERS_LIMIT_SUCCESS,
  BUILDING_USERS_LIMIT_FAIL,
  BUILDING_USERS_LIMIT_RESET,
  BUILDING_RULE_UPDATE_REQUEST,
  BUILDING_RULE_UPDATE_SUCCESS,
  BUILDING_RULE_UPDATE_FAIL,
  BUILDING_RULE_UPDATE_RESET,
  BUILDING_LOCATION_REQUEST,
  BUILDING_LOCATION_SUCCESS,
  BUILDING_LOCATION_FAIL,
  BUILDING_LOCATION_RESET,
  BUILDING_LOCATION_SERVICE_DETAILS_REQUEST,
  BUILDING_LOCATION_SERVICE_DETAILS_SUCCESS,
  BUILDING_LOCATION_SERVICE_DETAILS_FAIL,
  BUILDING_LOCATION_SERVICE_DETAILS_RESET,
} from '../constants/buildingConstants'


export const buildingCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case BUILDING_CREATE_REQUEST:
      return {
        loading: true
      }
    case BUILDING_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        building: action.payload
      }
    case BUILDING_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case BUILDING_CREATE_RESET:
      return {}
    default:
      return state;
  }
}

export const buildingListReducer = (state = { buildings: [] }, action) => {
  switch (action.type) {
    case BUILDING_LIST_REQUEST:
      return {
        loading: true,
        buildings: []
      }
    case BUILDING_LIST_SUCCESS:
      return {
        loading: false,
        buildings: action.payload
      }
    case BUILDING_LIST_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case BUILDING_LIST_RESET:
      return {}
    default:
      return state;
  }
}

export const buildingUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case BUILDING_UPDATE_REQUEST:
      return {
        loading: true
      }
    case BUILDING_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        building: action.payload
      }
    case BUILDING_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case BUILDING_UPDATE_RESET:
      return {}
    default:
      return state;
  }
}

export const buildingDetailsReducer = (state = { building: {} }, action) => {
  switch (action.type) {
    case BUILDING_DETAILS_REQUEST:
      return {
        loading: true
      }
    case BUILDING_DETAILS_SUCCESS:
      return {
        loading: false,
        building: action.payload
      }
    case BUILDING_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case BUILDING_DETAILS_RESET:
      return {}
    default:
      return state;
  }
}

export const buildingVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case BUILDING_VERIFY_REQUEST:
      return {
        loading: true
      }
    case BUILDING_VERIFY_SUCCESS:
      return {
        loading: false,
        success: true
      }
    case BUILDING_VERIFY_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case BUILDING_VERIFY_RESET:
      return {}
    default:
      return state;
  }
}

export const buildingCheckPendingReducer = (state = {}, action) => {
  switch (action.type) {
    case BUILDING_CHECK_PENDING_REQUEST:
      return {
        loading: true
      }
    case BUILDING_CHECK_PENDING_SUCCESS:
      return {
        loading: false,
        success: true,
        building: action.payload
      }
    case BUILDING_CHECK_PENDING_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case BUILDING_CHECK_PENDING_RESET:
      return {}
    default:
      return state;
  }
}


export const buildingUsersLimitReducer = (state = {}, action) => {
  switch (action.type) {
    case BUILDING_USERS_LIMIT_REQUEST:
      return {
        loading: true
      }
    case BUILDING_USERS_LIMIT_SUCCESS:
      return {
        loading: false,
        building: action.payload
      }
    case BUILDING_USERS_LIMIT_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case BUILDING_USERS_LIMIT_RESET:
      return {}
    default:
      return state;
  }
}

export const buildingRuleUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case BUILDING_RULE_UPDATE_REQUEST:
      return {
        loading: true
      }
    case BUILDING_RULE_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        building: action.payload
      }
    case BUILDING_RULE_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case BUILDING_RULE_UPDATE_RESET:
      return {}
    default:
      return state;
  }
}

export const buildingLocationReducer = (state = {}, action) => {
  switch (action.type) {
    case BUILDING_LOCATION_REQUEST:
      return {
        loading: true
      }
    case BUILDING_LOCATION_SUCCESS:
      return {
        loading: false,
        buildings: action.payload
      }
    case BUILDING_LOCATION_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case BUILDING_LOCATION_RESET:
      return {}
    default:
      return state;
  }
}

export const buildingLocationServiceReducer = (state = {}, action) => {
  switch (action.type) {
    case BUILDING_LOCATION_SERVICE_DETAILS_REQUEST:
      return {
        loading: true
      }
    case BUILDING_LOCATION_SERVICE_DETAILS_SUCCESS:
      return {
        loading: false,
        service: action.payload
      }
    case BUILDING_LOCATION_SERVICE_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case BUILDING_LOCATION_SERVICE_DETAILS_RESET:
      return {}
    default:
      return state
  }
}