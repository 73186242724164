import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'



const AnimalPage = () => {

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building } = buildingDetails;


  return (
    <div className="row content">
      <div className="col-md-8 container">
        <div>
          {/* Back button */}
          <Link to={`/building/${building && building._id}`} className="btn btn-primary mb-4">
            <i className="fas fa-chevron-circle-left"></i> Back
          </Link>
          {/* content */}
          {building && building.general && building.general.animal !== null ? (
            <div dangerouslySetInnerHTML={{ __html: building.general.animal }}></div>
          ) : (
            <>
              <p className="lead bg-secondary p-4 text-light">There are still no regulations. The community hasn't fill the regulation form yet</p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default AnimalPage
