import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { updateGeneralRule } from '../../actions/buildingActions'
import Alert from '../Alert'
import { setAlert } from '../../actions/alertActions'





const ExteriorForm = () => {
  const [exterior, setExterior] = useState('')


  const dispatch = useDispatch();

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building } = buildingDetails; // building cannot come from RegulationPage

  const buildingRuleUpdate = useSelector(state => state.buildingRuleUpdate);
  const { success } = buildingRuleUpdate;

  const alertAction = useSelector(state => state.alertAction);
  const { alert } = alertAction;



  useEffect(() => {
    if (building && building.general && building.general.exterior) {
      setExterior(building.general.exterior);
    }

    if (success) {
      dispatch(setAlert('Regulation updated !', 'success'));
    }

  }, [dispatch, building, success])

  const onChange = (e, editor) => {
    setExterior(editor.getData());
  }

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateGeneralRule(building._id, { exterior }));

  }

  return (
    <div>
      <h4 className="mt-4 mb-5"> Exterior Regulation</h4>

      <div className='text-center mt-3'>
        {alert && <Alert msg={alert.msg} type={alert.type} />}
      </div>

      <form onSubmit={submitHandler}>
        <div className="form-group">
          <CKEditor
            name="exterior"
            data={exterior}
            editor={ClassicEditor}
            onChange={onChange}
            config={{
              toolbar: ['heading', '|', 'bold', 'italic', 'insertTable', 'bulletedList', 'numberedList', 'blockQuote']
            }}
          />

          <input type="submit" value="submit" className="btn btn-primary float-right my-4" />
        </div>
      </form >
    </div >

  )
}

export default ExteriorForm
