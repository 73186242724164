import axios from 'axios'
import {
  POST_LIST_SUCCESS,
  POST_LIST_FAIL,

  POST_DETAILS_REQUEST,
  POST_DETAILS_SUCCESS,
  POST_DETAILS_FAIL,

  POST_FILTER_REQUEST,
  POST_FILTER_SUCCESS,
  POST_FILTER_FAIL,

  POST_LIKE_UPDATE,
  POST_DETAILS_LIKE_UPDATE,

  POST_CREATE_REQUEST,
  POST_CREATE_SUCCESS,
  POST_CREATE_FAIL,

  POST_UPDATE_REQUEST,
  POST_UPDATE_SUCCESS,
  POST_UPDATE_FAIL,

  POST_REMOVE_REQUEST,
  POST_REMOVE_SUCCESS,
  POST_REMOVE_FAIL,

  POST_VOTE_CREATE_REQUEST,
  POST_VOTE_CREATE_SUCCESS,
  POST_VOTE_CREATE_FAIL,
  POST_VOTE_LIST_REQUEST,
  POST_VOTE_LIST_SUCCESS,
  POST_VOTE_LIST_FAIL,

  POST_IMAGE_UPLOAD_REQUEST,
  POST_IMAGE_UPLOAD_SUCCESS,
  POST_IMAGE_UPLOAD_LOADING,
  POST_IMAGE_UPLOAD_FAIL,

  POST_LIST_REPORT_REQUEST,
  POST_LIST_REPORT_SUCCESS,
  POST_LIST_REPORT_FAIL,


} from '../constants/postConstants'

export const listPostsForReport = (year = new Date().getFullYear()) => async (dispatch, getState) => {
  dispatch({ type: POST_LIST_REPORT_REQUEST })

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`/api/posts/report?year=${year}`, config)

    dispatch({
      type: POST_LIST_REPORT_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: POST_LIST_REPORT_FAIL,
      paload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }

}

// it's important to give default value for keyword and pageNumber. Otherwise posts result []
export const listPosts = (pageNumber = '', keyword = '') => async (dispatch, getState) => {
  // dispatch({ type: POST_LIST_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    // const { data } = await axios.get(`/api/posts?pageNumber=${pageNumber}&keywords=${keywords}`, config);
    const { data } = await axios.get(`/api/posts?keyword=${keyword}&pageNumber=${pageNumber}`, config);

    dispatch({
      type: POST_LIST_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: POST_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const getPostDetails = (id, history) => async (dispatch, getState) => {
  dispatch({ type: POST_DETAILS_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`/api/posts/${id}`, config);

    // user try to access post details from different channel
    if (data.message === 'Not same channel') {
      return history.push('/')
    }

    dispatch({
      type: POST_DETAILS_SUCCESS,
      payload: data
    })

  } catch (error) {

    dispatch({
      type: POST_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const addLike = id => async (dispatch, getState) => {
  // dispatch({ type: POST_LIKE_REQUEST });
  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/posts/${id}/likes`, {}, config);
    dispatch({
      type: POST_LIKE_UPDATE,
      payload: { id, likes: data }
    })

    // to avoid error cna't read _id  after like than click the more btn to post details
    dispatch(getPostDetails(id));

    // for postDetails page. TO immediately update the length
    dispatch({
      type: POST_DETAILS_LIKE_UPDATE,
      payload: { id, likes: data }
    })

  } catch (error) {
    console.log(error);
  }
}

export const removeLike = id => async (dispatch, getState) => {
  // dispatch({ type: POST_DISLIKE_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/posts/${id}/unlikes`, {}, config);
    dispatch({
      type: POST_LIKE_UPDATE,
      payload: { id, likes: data }
    })

    // for postDetails page. TO immediately update the length
    dispatch({
      type: POST_DETAILS_LIKE_UPDATE,
      payload: { id, likes: data }
    })

  } catch (error) {
    console.log(error)
  }
}

export const createPost = (formData, buildingId) => async (dispatch, getState) => {
  dispatch({ type: POST_CREATE_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.post(`/api/posts/${buildingId}`, formData, config);
    dispatch({
      type: POST_CREATE_SUCCESS,
      payload: data
    })
  } catch (error) {
    console.log(error.response)
    dispatch({
      type: POST_CREATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const updatePost = (id, formData) => async (dispatch, getState) => {
  dispatch({ type: POST_UPDATE_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/posts/${id}`, formData, config);
    dispatch({
      type: POST_UPDATE_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: POST_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const removePost = (buildingId, id) => async (dispatch, getState) => {
  dispatch({ type: POST_REMOVE_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/posts/${id}/building/${buildingId}`, {}, config);
    dispatch({
      type: POST_REMOVE_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: POST_REMOVE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const votingPost = (id, formData) => async (dispatch, getState) => {
  dispatch({ type: POST_VOTE_CREATE_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.post(`/api/posts/${id}/votes`, formData, config);
    dispatch({
      type: POST_VOTE_CREATE_SUCCESS,
      payload: data
    })

    // help to immediate get rid the vote form to the cheack mark
    dispatch(listVotesPost(id));

  } catch (error) {
    dispatch({
      type: POST_VOTE_CREATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const listVotesPost = id => async (dispatch, getState) => {
  dispatch({ type: POST_VOTE_LIST_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`/api/posts/${id}/votes`, config);
    dispatch({
      type: POST_VOTE_LIST_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: POST_VOTE_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const uploadImage = (id, formData) => async (dispatch, getState) => {
  dispatch({ type: POST_IMAGE_UPLOAD_REQUEST })

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/upload/${id}/posts`, formData, config);

    dispatch({
      type: POST_IMAGE_UPLOAD_SUCCESS,
      payload: data
    })

    dispatch({ type: POST_IMAGE_UPLOAD_LOADING }) // set loading to false, helps upload button interaction


  } catch (error) {
    dispatch({
      type: POST_IMAGE_UPLOAD_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

// dashboard posts
export const filterPosts = (userId, pageNumber) => async (dispatch, getState) => {
  dispatch({ type: POST_FILTER_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }


    const url = userId === 'allPosts' ? `/api/posts?pageNumber=${pageNumber}` : `/api/posts/users/${userId}?pageNumber=${pageNumber}`

    const { data } = await axios.get(url, config);

    dispatch({
      type: POST_FILTER_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: POST_FILTER_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

