import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import { adminLogin } from '../../actions/adminAction'
import Alert from '../../components/Alert'


const AdminLoginPage = ({ history }) => {
  const [recaptcha, setRecaptcha] = useState(false)
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  })

  const { email, password } = formData;

  const dispatch = useDispatch();

  const adminFullGranted = useSelector(state => state.adminFullGranted);
  const { error } = adminFullGranted;

  useEffect(() => {

  }, [history])

  const recaptchaHandler = () => {
    setRecaptcha(true)
  }


  const submitHandler = e => {
    e.preventDefault();
    // history to push to / if error occure
    dispatch(adminLogin(formData, history));
  }



  return (
    <div className='content'>
      {/* <div className="row justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4"> */}

      {error && <Alert msg={error} />}

      <h2 className='text-primary text-center mb-5'>Admin Login</h2>
      <form onSubmit={submitHandler} style={{ width: "320px", margin: 'auto', display: 'block' }}>
        <div className="form-outline mb-4">
          <input
            type="email"
            className="form-control form-control-lg"
            value={email} name="email"
            onChange={e => setFormData({ ...formData, email: e.target.value })}
          />
          <small className="form-label" for="loginName">Email</small>
        </div>


        <div className="form-outline mb-4">
          <input
            type="password"
            className="form-control form-control-lg"
            value={password} name="password"
            onChange={e => setFormData({ ...formData, password: e.target.value })}
          />
          <small className="form-label" for="loginPassword">Password</small>
        </div>

        <button type="submit" className="btn btn-primary btn-block mb-4" disabled={!recaptcha} >Sign in</button>

        <ReCAPTCHA
          sitekey="6Le9PwwaAAAAAGEIbNl1dlPplzYhZ9JfshYJo8WG"
          onChange={recaptchaHandler}
        />
      </form>

      {/* <form className="form-group" onSubmit={submitHandler}>
          <div className="form-group">
            <small>Email</small>
            <input type="email" className="form-control form-control-lg" value={email} name="email" onChange={e => setFormData({ ...formData, email: e.target.value })} />
          </div>
          <div className="form-group">
            <small>Password</small>
            <input type="password" className="form-control form-control-lg" value={password} name="password" onChange={e => setFormData({ ...formData, password: e.target.value })} />
          </div>
          <input type="submit" value="Login" className="btn btn-primary" />
        </form> */}
      {/* </div>
    </div> */}
    </div >
  )
}

export default AdminLoginPage
