import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Alert from '../Alert'
import Spinner from '../Spinner'
import { setAlert } from '../../actions/alertActions'
import { getUserDetails, updateUser } from '../../actions/userActions'
import { USER_UPDATE_RESET } from '../../constants/userConstants'


const UserEditForm = ({ match }) => {
  const dispatch = useDispatch();

  const userId = match.params.id;

  const [formData, setFormData] = useState({
    role: '',
    firstName: '',
    lastName: '',
    houseNumber: '',
    community: '',
    isMute: false,
    resetPasswordToken: ''
  });

  const { role, firstName, lastName, community, houseNumber, isMute } = formData;

  // const userDetails = useSelector(state => state.userDetails);
  // const { user, loading } = userDetails;

  const userEmailDetails = useSelector(state => state.userEmailDetails);
  const { email, loading } = userEmailDetails;

  const userUpdate = useSelector(state => state.userUpdate);
  const { error: errorUserUpdate } = userUpdate;

  const alertAction = useSelector(state => state.alertAction);
  const { alert } = alertAction;

  const adminFullGranted = useSelector(state => state.adminFullGranted);
  const { token } = adminFullGranted;

  useEffect(() => {

    if (errorUserUpdate) {
      dispatch(setAlert(errorUserUpdate, 'danger'));
      dispatch({ type: USER_UPDATE_RESET })
    }

    if (!email || email._id !== userId) {
      dispatch(getUserDetails(userId));
      // dispatch({ type: USER_DETAILS_RESET })
    }

    if (email) {
      setFormData({
        role: loading || !email.role ? '' : email.role,
        firstName: loading || !email.firstName ? '' : email.firstName,
        lastName: loading || !email.lastName ? '' : email.lastName,
        houseNumber: loading || !email.houseNumber ? '' : email.houseNumber,
        community: loading || !email.community ? '' : email.community,
        mute: loading || !email.mute ? false : email.mute,
        isMute: loading || !email.isMute ? false : email.isMute,
        resetPasswordToken: loading || !email.resetPasswordToken ? '' : email.resetPasswordToken,
      })
    }

  }, [dispatch, userId, email, errorUserUpdate, loading])


  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }


  const onSubmitHandler = e => {
    e.preventDefault();
    dispatch(updateUser(userId, formData));
  }


  return (
    loading ? <Spinner /> :

      <div className="row">
        <div className="col-md">
          <div className="content container">
            <h2>User Settings</h2>
            <form className="form-group mt-5" onSubmit={onSubmitHandler}>
              {alert && <Alert msg={alert.msg} type={alert.type} />}

              <div className="mb-2">
                <small>Firstname</small>
                <input type="text" name="firstName" value={firstName} className="form-control" onChange={onChange} />
              </div>
              <div className="mb-2">
                <small>lastName</small>
                <input type="text" name="lastName" value={lastName} className="form-control" onChange={onChange} />
              </div>
              <div className="mb-2">
                <small>Role</small>
                <select name="role" value={role} onChange={onChange} className="form-control">
                  <option value="member">Member</option>
                  <option value="author">Author</option>
                  <option value="admin">Admin</option>
                </select>
              </div>
              <div className="mb-2">
                <small>House number</small>
                <input type="number" name="houseNumber" value={houseNumber} className="form-control" onChange={onChange} />
              </div>
              <div className="mb-2">
                <small>Community</small>
                <input type="text" name="community" value={community} className="form-control" onChange={onChange} />
              </div>
              <div>
                <input type="checkbox" name="isMute" id="isMute" className="my-3 mr-2" checked={isMute} onChange={e => setFormData({
                  ...formData, isMute: e.target.checked
                })} />
                <label htmlFor="isMute" className="mr-3">Mute user</label>
              </div>
              <div className="mt-3">
                <input type="submit" value="Update User" className="btn btn-primary btn-block" />
                <Link to={`/admin/users/${token}`} type="button" className="btn btn-secondary btn-block">Cancel</Link>
              </div>
            </form>
          </div>
        </div>


      </div>
  )
}

export default UserEditForm
