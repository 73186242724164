import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateMe, updatePassword, uploadProfileImage } from '../actions/userActions'
import Alert from '../components/Alert'
import Spinner from '../components/Spinner'
import { setAlert } from '../actions/alertActions'
import { USER_PASSWORD_UPDATE_RESET } from '../constants/userConstants'
import { deniedBannedUser } from '../actions/buildingActions'



const ProfilePage = ({ history }) => {
  const dispatch = useDispatch();

  // profile useState
  const [profileData, setProfileData] = useState({
    username: '',
    firstName: '',
    lastName: '',
    houseNumber: '',
    twitter: '',
    facebook: '',
    avatar: ''
  });

  // user useState
  const [credential, setCredential] = useState({
    email: '',
    newPassword: '',
    currentPassword: '',
    confirmPassword: ''
  });

  const { username, firstName, lastName, houseNumber, twitter, facebook } = profileData;
  const { email, newPassword, currentPassword, confirmPassword } = credential;

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo, loading, isActive } = userLogin;

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building } = buildingDetails;

  const userImageUpload = useSelector(state => state.userImageUpload);
  const { loading: loadingImageUpload } = userImageUpload;

  const userPasswordUpdate = useSelector(state => state.userPasswordUpdate);
  const { success: successUpdatePassword, error: errorPasswordUpdate } = userPasswordUpdate;

  const alertAction = useSelector(state => state.alertAction);
  const { alert } = alertAction;



  useEffect(() => {
    dispatch(deniedBannedUser(building, userInfo, history));

    if (userInfo.community === 'no-group') {
      history.push('/welcome')
    }

    if (userInfo.community === 'no-group') {
      history.push('/new-community');
    }

    if (errorPasswordUpdate || successUpdatePassword) {
      window.setTimeout(() => {
        dispatch({ type: USER_PASSWORD_UPDATE_RESET })  // this to remove the error which come from reducer
      }, 5000)
    }

    if (userInfo) {
      setProfileData({
        username: loading || !userInfo.username ? '' : userInfo.username,
        firstName: loading || !userInfo.firstName ? '' : userInfo.firstName,
        lastName: loading || !userInfo.lastName ? '' : userInfo.lastName,
        houseNumber: loading || !userInfo.houseNumber ? '' : userInfo.houseNumber,
        twitter: loading || userInfo.social && !userInfo.social.twitter ? '' : userInfo.social && userInfo.social.twitter,
        facebook: loading || userInfo.social && !userInfo.social.facebook ? '' : userInfo.social && userInfo.social.facebook,
      })
    }

    setCredential({
      email: loading || !userInfo.email ? '' : userInfo.email
    })
  }, [
    loading,
    userInfo,
    dispatch,
    history,
    successUpdatePassword,
    userPasswordUpdate,
    errorPasswordUpdate,
    building,
    isActive
  ]) // userPasswordUpdate helps to prevent undefined email after error


  const onChangeProfile = e => setProfileData({ ...profileData, [e.target.name]: e.target.value });
  const onChangeCredential = e => setCredential({ ...credential, [e.target.name]: e.target.value });


  const uploadFileHandler = async e => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);

    dispatch(uploadProfileImage(formData));

  }


  // Update Profile 
  const submitProfileUpdateHandler = e => {
    e.preventDefault();
    if (firstName === '' || lastName === '' || username === '') {
      dispatch(setAlert('Please complete the form !', 'danger'));
    } else {
      dispatch(updateMe(profileData));
    }
  }

  // Update Password/email
  const submitUpdatePasswordHandler = e => {
    e.preventDefault();
    dispatch(updatePassword(credential));

    setCredential({
      newPassword: '',
      confirmPassword: '',
      currentPassword: ''
    })
  }

  // Toggle for social links
  const [socialLinks, toggleSocialLinks] = useState(false);

  return (

    <div id="profile">
      <div className="row pt-5" style={{ margin: '30px', padding: '10px' }}>
        {/* <div className="col-md"> */}
        <h1 className="text-secondary">
          <i className="fa fa-user mr-2 text-primary"></i>
          Profile Settings
        </h1>
        {/* </div> */}
      </div>
      <div className="row" style={{ margin: '20px' }}>
        <div className="col-sm-12 col-md-8 col-lg-4 col-xl-4">
          {/* PROFILE'S FORM */}
          <form className="form-group mb-5" onSubmit={submitProfileUpdateHandler}>
            {/* ALERT PROFILE UPDATE */}
            {alert && <Alert msg={alert.msg} type={alert.type} />}
            <div className="mb-3">
              <span className="small">Username</span>
              <input type="text" name="username" className="form-control" maxLength="20" value={username} onChange={onChangeProfile} />
            </div>
            <div className="mb-3">
              <span className="small">Firstname</span>
              <input type="text" name="firstName" className="form-control" maxLength="20" value={firstName} onChange={onChangeProfile} />
            </div>
            <div className="mb-3">
              <span className="small">Lastname</span>
              <input type="text" name="lastName" className="form-control" maxLength="20" value={lastName} onChange={onChangeProfile} />
            </div>
            <div className="mb-3">
              <span className="small">House number</span>
              <input type="number" name="houseNumber" className="form-control" value={houseNumber} min="1" max="99" onChange={onChangeProfile} />
            </div>
            <div className="mb-3">
              <button type="button" className="btn-sm btn-light" onClick={() => toggleSocialLinks(!socialLinks)}>Add social network links</button>
            </div>
            {/* SOCIAL LINKS INPUT */}
            {socialLinks && <Fragment>
              <div className="mb-3">
                <div className="form-group form-inline">
                  <i className="fab fa-facebook fa-2x"></i>
                  <input type="text" name="facebook" className="form-control" value={facebook} placeholder="https://" onChange={onChangeProfile} />
                </div>
                <div className="form-group form-inline">
                  <i className="fab fa-twitter fa-2x"></i>
                  <input type="text" name="twitter" className="form-control" value={twitter} placeholder="https://" onChange={onChangeProfile} />
                </div>
              </div>
            </Fragment>}

            {/* GO BACK BTN */}
            <input type="submit" value="Save Account" className="btn btn-primary mt-3 mr-2" />
            <Link to="/dashboard" className="btn btn-secondary mt-3">Cancel</Link>
          </form>



          {/* FORM TO CHANGE EMAIL OR PASSWORD */}
          <form id="password-form" className="form-group" onSubmit={submitUpdatePasswordHandler}>
            {/* ALERT PASSWORD UPDATE OR ERROR */}
            {errorPasswordUpdate && <Alert msg={errorPasswordUpdate} />}
            {successUpdatePassword && <Alert msg="Password updated !" type="success" />}
            <div className="mb-3">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email"
                value={email}
                onChange={onChangeCredential} />
            </div>
            <div className="mb-3">
              <input
                type="password"
                name="newPassword"
                value={newPassword || ''}
                className="form-control"
                placeholder="New password"
                onChange={onChangeCredential} />
            </div>
            <div className="mb-3">
              <input
                type="password"
                name="confirmPassword"
                value={confirmPassword || ''}
                className="form-control"
                placeholder="Confirm password"
                onChange={onChangeCredential} />
            </div>
            <small className="form-text" name="confirm-alert"></small>
            <div className="mb-3">
              <input type="password"
                name="currentPassword"
                value={currentPassword || ''}
                className="form-control"
                placeholder="Current password"
                onChange={onChangeCredential} />
            </div>
            <input type="submit" value="Change Password" id="update-password-btn" className="btn btn-primary mt-3" />
          </form>
        </div>

        {/* UPLOAD IMAGE */}
        <div className="col-md-4">
          <div>
            {/* <small>Copy your image link address here and paste it without the protocol "https:" </small> */}
            <form className="form-group mb-4">
              {/* <div className="mb-2"> */}
              {/* <input
                  type="text"
                  value={avatar}
                  placeholder="example : //myUriLinkAddress.com"
                  className="form-control"
                  onChange={e => setProfileData({
                    ...profileData, avatar: e.target.value
                  })}
                /> */}
              {/* </div> */}
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="image-profile"
                  onChange={uploadFileHandler}
                />
                <label className="custom-file-label" htmlFor="image-profile">or select file..</label>
              </div>
            </form>
            {/* Profile's image */}
            <div>
              {loadingImageUpload ? <Spinner /> : (
                <img src={`${userInfo.avatar.url}`} alt="avatar" className="border rounded p-1 bg-light" style={{ width: "180px", height: "200px" }} />
              )}
            </div>
          </div>
          <div>
            {/* <small>Upload your Building's image</small>
          <form className="mb-4 form-froup">
            <div className="mb-2">
              <input
                type="text"
                placeholder="Image Url"
                className="form-control"
              />
            </div>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="image-building"
              />
              <label className="custom-file-label" htmlFor="image-building">or select file..</label>
            </div>
          </form> */}
            {/* Profile's image */}

          </div>

        </div>
        <div className="col-md-3"></div>
      </div>
    </div>
  )
}

export default ProfilePage
