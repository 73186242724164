import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
// import path from 'path'
// import { downloadDocument, getDocumentDetails } from '../actions/documentActions'

const DocumentPage = ({ match }) => {
  const docId = match.params.id;

  const dispatch = useDispatch();


  // const documentDetails = useSelector(state => state.documentDetails);
  // const { doc } = documentDetails;

  useEffect(() => {




  }, [dispatch, docId]);

  return (
    <div className="content">

    </div>
  )
}

export default DocumentPage
