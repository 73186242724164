import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Spinner from '../components/Spinner'


const HomePage = ({ history }) => {

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo, loading } = userLogin;

  useEffect(() => {
    if (userInfo) {
      history.push('/posts')
    }

  }, [history, userInfo])

  return (
    loading || userInfo ? <Spinner /> :
      <div id="home">
        <div>
          <div className="dark-overlay">
            <div className="home-inner container">
              <div className="row">
                <div className="col-lg-8 d-none d-lg-block mt-5">
                  <h1 className="diplay-4">
                    Welcome to the Community !
                  </h1>
                  <div className="d-flex">
                    <div className="p-4 align-self-start">
                      <i className="fas fa-check fa-2x"></i>
                    </div>
                    <div className="p-4 align-self-end">
                      This application is attend to support the community together to manage and maintain your environment.
                      It should help by making decision and get deeply understanding of community's common needs on a fair basis.
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="p-4 align-self-start">
                      <i className="fas fa-check fa-2x"></i>
                    </div>
                    <div className="p-4 align-self-end">
                      Register and access the informations from the committee member. Interactive, making suggestion and just simple expressing your ideas.
                    </div>
                  </div>
                  {/* <div className="d-flex">
                  <div className="p-4 align-self-start">
                    <i className="fas fa-check fa-2x"></i>
                  </div>
                  <div className="p-4 align-self-end">
                    Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classNameical
                    Latin literature from 45 BC, making it over 2000 years old.
                  </div>
                </div> */}
                </div>
                <div className="col-lg-4 my-5">
                  <div className="jumbotron" style={{ opacity: '0.8', background: '#2e2e2e', color: '#fff' }}>
                    <p className="lead">Have a nice day !</p>
                    {/* APP GUIDE*/}
                    {/* <Link to="/video-guide" style={{ position: 'absolute', top: '10px', right: '40px', padding: '0' }}>
                      <i className="fab fa-youtube fa-2x mt-2 ml-4 text-danger"></i>
                    </Link> */}
                    {/* <p className="lead"></p> */}
                    <hr className="my-4 " />
                    <p>Go to Login page or Register.</p>
                    <Link className="btn btn-primary btn-block" to="/register" role="button">Sign Up</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Explore HEAD --> */}
        <div id="explore-head-section">
          <div className="container">
            <div className="row">
              <div className="col text-center py-5">
                {/* <h1 className="display-4">Explore</h1> */}
                <p className="lead">Mind and heart should be our best friend to accieve the harmony of together living. You can now use this media platform to inform and update yourself anytime and from anywhere.</p>
                {/* <a href="#" type="button" className="btn btn-outline-secondary">Read More</a> */}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Explore SECTION --> */}
        {/* <div id="explore-section" className="bg-light text-muted py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6 d-flex justify-content-center">
              <img src="/img/homepage/app.jpg" alt="App" className="img-fluid rounded-circle mb-3" />
            </div>
            <div className="col-md-6">
              <h3>Explore & Connect</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos sint cum, reiciendis nisi iste pariatur ullam
          aliquid corporis possimus facere.</p>
            </div>
          </div>
        </div>
      </div> */}
      </div>
  )
}

export default HomePage
