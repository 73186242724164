import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import UserListPage from './dashboard/UserListPage'
import PostListPage from './dashboard/PostListPage'
import VideoListPage from './dashboard/VideoListPage'
import SettingPage from './dashboard/SettingPage'
import Spinner from '../components/Spinner'
import MainDashboard from '../pages/dashboard/MainDashboard'
import EmailListPage from './dashboard/EmailListPage'
import AddressForm from '../pages/dashboard/AddressForm'
import RegulationPage from '../pages/dashboard/RegulationPage'
import InboxPageForm from './email/InboxPageForm'
import { isOperator } from '../actions/authActions'
import { listMyEmails } from '../actions/userActions'
import { deniedBannedUser } from '../actions/buildingActions'
import { adminlistEmails } from '../actions/adminAction'
import { USER_LOGOUT } from '../constants/userConstants'
import ServiceForm from './dashboard/ServiceForm'

// updated


const DashboardPage = ({ location, match, history }) => {

  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building, error: errorBuildingDetails } = buildingDetails;

  const userEmailList = useSelector(state => state.userEmailList);
  const { unreadEmails, email } = userEmailList;

  const adminEmailList = useSelector(state => state.adminEmailList);
  const { emails } = adminEmailList;

  const userSwitchBuilding = useSelector(state => state.userSwitchBuilding);
  const { loading: loadingSwitchBuilding } = userSwitchBuilding;

  const pathName = () => {
    return location.pathname.split('/')[2];
  }

  const pageNumber = match.params.pageNumber;


  useEffect(() => {

    // only admin can use /dashboard/users 
    if (pathName() === 'users' && userInfo.role !== 'admin') {
      history.push('/dashboard')
    }

    dispatch(deniedBannedUser(building, userInfo, history));

    if (!userInfo || errorBuildingDetails === 'Token is no longer valid') {
      dispatch({ type: USER_LOGOUT })
      history.push('/login')
    }

    // must be called soon because need the lenght unreadEmails
    dispatch(listMyEmails());
    dispatch(adminlistEmails());

  }, [dispatch, history, userInfo, building, errorBuildingDetails])


  return (
    loadingSwitchBuilding ? <Spinner /> :
      <>
        <div className="row content">
          <div className="col-md-3" style={{ marginBottom: '20px' }}>
            <ul className="list-group list-group-flush border p-3 bg-light text-center">
              <li className={`list-group-item ${location.pathname === '/dashboard' && 'bg-dark'}`}>
                <Link to="/dashboard">
                  <h5>Main</h5>
                </Link>
              </li>
              <li className={`list-group-item ${(pathName() === 'emails' || pathName() === 'ticket') && 'bg-dark'}`}>
                <Link to="/dashboard/emails">
                  <h5>
                    <div>Emails</div>
                    <span className={`badge badge-light text-primary ${unreadEmails === 0 && 'text-muted'}`} style={{ position: 'absolute', top: '14px', right: '30px' }}>{unreadEmails}</span>
                  </h5>
                </Link>
              </li>
              <li className={`list-group-item ${pathName() === 'posts' && 'bg-dark'}`}>
                <Link to="/dashboard/posts">
                  <h5>Posts</h5>
                </Link>
              </li>

              {userInfo.role === 'admin' && (
                <>
                  <li className={`list-group-item ${pathName() === 'users' && 'bg-dark'}`}>
                    <Link to="/dashboard/users">
                      <h5>Users</h5>
                    </Link>
                  </li>
                  <li className={`list-group-item ${pathName() === 'videos' && 'bg-dark'}`}>
                    <Link to="/dashboard/videos">
                      <h5>Videos</h5>
                    </Link>
                  </li>
                  <li className={`list-group-item ${pathName() === 'settings' && 'bg-dark'}`}>
                    <Link to="/dashboard/settings">
                      <h5>Settings</h5>
                    </Link>
                  </li>
                </>
              )}


              {/* <li className={`list-group-item ${pathName() === 'documents' && 'bg-dark'}`}>
                <Link to="/dashboard/documents">
                  <h5>Documents</h5>
                </Link>
              </li> */}

              {/* only owner, admin and operator */}
              {dispatch(isOperator(building, userInfo)) && (
                <>
                  <li className={`list-group-item ${pathName() === 'location' && 'bg-dark'}`}>
                    <Link to="/dashboard/location">
                      <h5>Location</h5>
                    </Link>
                  </li>
                  <li className={`list-group-item ${pathName() === 'regulation' && 'bg-dark'}`}>
                    <Link to="/dashboard/regulation">
                      <h5>Regulation</h5>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="col-md-9">
            {location.pathname === '/dashboard' && (
              <MainDashboard />
            )}

            {pathName() === 'emails' && (
              <EmailListPage
                userInfo={userInfo}
                adminEmails={emails}
              />
            )}

            {pathName() === 'users' && (
              <UserListPage
                isAdminPage={false}
                pageNumber={pageNumber}
                // page={page}
                // pages={pages}
                building={building}
              />
            )}

            {pathName() === 'settings' && (
              <SettingPage
                userInfo={userInfo}
                building={building}
              />
            )}

            {pathName() === 'posts' && (
              <PostListPage
                userInfo={userInfo}
                pageNumber={pageNumber}
                building={building}
              />
            )}

            {pathName() === 'videos' && userInfo.role === 'admin' && (
              <VideoListPage
                userInfo={userInfo}
                building={building}
              />

            )}

            {pathName() === 'regulation' && (
              dispatch(isOperator(building, userInfo)) && (
                <RegulationPage
                  building={building}
                  userInfo={userInfo}
                  history={history}
                />
              )
            )}

            {/* {pathName() === 'documents' && (
              <DocumentListPage />
            )} */}

            {pathName() === 'location' && (
              // only owner, admin, operator
              dispatch(isOperator(building, userInfo)) && (
                <>
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active" id="address-tab" data-toggle="tab" href="#address" role="tab" aria-controls="address" aria-selected="true">Address</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" id="service-tab" data-toggle="tab" href="#service" role="tab" aria-controls="service" aria-selected="false">24 Hours service</a>
                    </li>
                  </ul>

                  {/* TAB CONTENT */}
                  <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="address" role="tabpanel" aria-labelledby="general-tab">
                      <AddressForm
                        building={building}
                        userInfo={userInfo}
                        history={history}
                      />
                    </div>
                    <div className="tab-pane fade" id="service" role="tabpanel" aria-labelledby="service-tab">
                      <ServiceForm
                        building={building}
                        userInfo={userInfo}
                        history={history}
                      />
                    </div>
                  </div>
                </>
              )
            )}

            {pathName() === 'ticket' && (
              <InboxPageForm emailId={match.params.id} email={email} />
            )}

          </div>
        </div>


      </>
  )
}

export default DashboardPage
