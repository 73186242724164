export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET';

export const USER_UPDATE_ME_REQUEST = 'USER_UPDATE_ME_REQUEST';
export const USER_UPDATE_ME_SUCCESS = 'USER_UPDATE_ME_SUCCESS';
export const USER_UPDATE_ME_FAIL = 'USER_UPDATE_ME_FAIL';
export const USER_UPDATE_ME_RESET = 'USER_UPDATE_ME_RESET';

export const USER_IMAGE_UPLOAD_REQUEST = 'USER_IMAGE_UPLOAD_REQUEST';
export const USER_IMAGE_UPLOAD_SUCCESS = 'USER_IMAGE_UPLOAD_SUCCESS';
export const USER_IMAGE_UPLOAD_FAIL = 'USER_IMAGE_UPLOAD_FAIL';
export const USER_IMAGE_UPLOAD_RESET = 'USER_IMAGE_UPLOAD_RESET';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';

export const USER_CONTACT_SUPPORT_REQUEST = 'USER_CONTACT_SUPPORT_REQUEST';
export const USER_CONTACT_SUPPORT_SUCCESS = 'USER_CONTACT_SUPPORT_SUCCESS';
export const USER_CONTACT_SUPPORT_FAIL = 'USER_CONTACT_SUPPORT_FAIL';
export const USER_CONTACT_SUPPORT_RESET = 'USER_CONTACT_SUPPORT_RESET';

export const USER_EMAIL_LIST_REQUEST = 'USER_EMAIL_LIST_REQUEST';
export const USER_EMAIL_LIST_SUCCESS = 'USER_EMAIL_LIST_SUCCESS';
export const USER_EMAIL_LIST_FAIL = 'USER_EMAIL_LIST_FAIL';
export const USER_EMAIL_LIST_RESET = 'USER_EMAIL_LIST_RESET';

export const USER_EMAIL_DETAILS_REQUEST = 'USER_EMAIL_DETAILS_REQUEST';
export const USER_EMAIL_DETAILS_SUCCESS = 'USER_EMAIL_DETAILS_SUCCESS';
export const USER_EMAIL_DETAILS_FAIL = 'USER_EMAIL_DETAILS_FAIL';
export const USER_EMAIL_DETAILS_RESET = 'USER_SENT_EMAIL_LIST_RESET';

export const USER_EMAIL_REMOVE_REQUEST = 'USER_EMAIL_REMOVE_REQUEST';
export const USER_EMAIL_REMOVE_SUCCESS = 'USER_EMAIL_REMOVE_SUCCESS';
export const USER_EMAIL_REMOVE_FAIL = 'USER_EMAIL_REMOVE_FAIL';
export const USER_EMAIL_REMOVE_RESET = 'USER_EMAIL_REMOVE_RESET';

export const USER_MARK_EMAIL_READ_SUCCESS = 'USER_MARK_EMAIL_READ_SUCCESS';
export const USER_MARK_EMAIL_READ_FAIL = 'USER_MARK_EMAIL_READ_FAIL';
export const USER_MARK_EMAIL_READ_RESET = 'USER_MARK_EMAIL_READ_RESET';

export const USER_BAN_REQUEST = 'USER_BAN_REQUEST';
export const USER_BAN_SUCCESS = 'USER_BAN_SUCCESS';
export const USER_BAN_FAIL = 'USER_BAN_FAIL';
export const USER_BAN_RESET = 'USER_BAN_RESET';

// profile
export const USER_PASSWORD_UPDATE_REQUEST = 'USER_PASSWORD_UPDATE_REQUEST';
export const USER_PASSWORD_UPDATE_SUCCESS = 'USER_PASSWORD_UPDATE_SUCCESS';
export const USER_PASSWORD_UPDATE_FAIL = 'USER_PASSWORD_UPDATE_FAIL';
export const USER_PASSWORD_UPDATE_RESET = 'USER_PASSWORD_UPDATE_RESET';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';
export const USER_LIST_RESET = 'USER_LIST_RESET';

export const USER_LIST_DETAILS_REQUEST = 'USER_LIST_DETAILS_REQUEST';
export const USER_LIST_DETAILS_SUCCESS = 'USER_LIST_DETAILS_SUCCESS';
export const USER_LIST_DETAILS_FAIL = 'USER_LIST_DETAILS_FAIL';

export const USER_ACTIVATE_REQUEST = 'USER_ACTIVATE_REQUEST';
export const USER_ACTIVATE_SUCCESS = 'USER_ACTIVATE_SUCCESS';
export const USER_ACTIVATE_FAIL = 'USER_ACTIVATE_FAIL';
export const USER_ACTIVATE_RESET = 'USER_ACTIVATE_RESET';

export const USER_SECURITY_CREATE_REQUEST = 'USER_SECURITY_CREATE_REQUEST';
export const USER_SECURITY_CREATE_SUCCESS = 'USER_SECURITY_CREATE_SUCCESS';
export const USER_SECURITY_CREATE_FAIL = 'USER_SECURITY_CREATE_FAIL';
export const USER_SECURITY_CREATE_RESET = 'USER_SECURITY_CREATE_RESET';

export const USER_INFO_UPDATE_REQUEST = 'USER_INFO_UPDATE_REQUEST';
export const USER_INFO_UPDATE_SUCCESS = 'USER_INFO_UPDATE_SUCCESS';
export const USER_INFO_UPDATE_FAIL = 'USER_INFO_UPDATE_FAIL';
export const USER_INFO_UPDATE_RESET = 'USER_INFO_UPDATE_RESET';

export const USER_CHECK_COMMUNITY_REQUEST = 'USER_CHECK_COMMUNITY_REQUEST';
export const USER_CHECK_COMMUNITY_SUCCESS = 'USER_CHECK_COMMUNITY_SUCCESS';
export const USER_CHECK_COMMUNITY_FAIL = 'USER_CHECK_COMMUNITY_FAIL';
export const USER_CHECK_COMMUNITY_RESET = 'USER_CHECK_COMMUNITY_RESET';

export const USER_NEW_AUTHOR_REQUEST = 'USER_NEW_AUTHOR_REQUEST';
export const USER_NEW_AUTHOR_SUCCESS = 'USER_NEW_AUTHOR_SUCCESS';
export const USER_NEW_AUTHOR_FAIL = 'USER_NEW_AUTHOR_FAIL';
export const USER_NEW_AUTHOR_RESET = 'USER_NEW_AUTHOR_RESET';

export const USER_SWITCH_BUILDING_REQUEST = 'USER_SWITCH_BUILDING_REQUEST';
export const USER_SWITCH_BUILDING_SUCCESS = 'USER_SWITCH_BUILDING_SUCCESS';
export const USER_SWITCH_BUILDING_FAIL = 'USER_SWITCH_BUILDING_FAIL';
export const USER_SWITCH_BUILDING_RESET = 'USER_SWITCH_BUILDING_RESET';

export const USER_NEW_BUILDING_LIST_REQUEST = 'USER_NEW_BUILDING_LIST_REQUEST';
export const USER_NEW_BUILDING_LIST_SUCCESS = 'USER_NEW_BUILDING_LIST_SUCCESS';
export const USER_NEW_BUILDING_LIST_FAIL = 'USER_NEW_BUILDING_LIST_FAIL';
export const USER_NEW_BUILDING_LIST_RESET = 'USER_NEW_BUILDING_LIST_RESET';



// FORGOT PASSWORD -----
export const USER_FORGOT_PASSWORD_REQUEST = 'USER_FORGOT_PASSWORD_REQUEST';
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS';
export const USER_FORGOT_PASSWORD_FAIL = 'USER_FORGOT_PASSWORD_FAIL';
export const USER_FORGOT_PASSWORD_RESET = 'USER_FORGOT_PASSWORD_RESET';

export const USER_SECURITY_CHECK_REQUEST = 'USER_SECURITY_CHECK_REQUEST';
export const USER_SECURITY_CHECK_SUCCESS = 'USER_SECURITY_CHECK_SUCCESS';
export const USER_SECURITY_CHECK_FAIL = 'USER_SECURITY_CHECK_FAIL';
export const USER_SECURITY_CHECK_RESET = 'USER_SECURITY_CHECK_RESET';

export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_RESET';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_FAIL = 'USER_RESET_PASSWORD_FAIL';
export const USER_RESET_PASSWORD_RESET = 'USER_RESET_PASSWORD_RESET';



