import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { selectVideo } from '../../actions/videoActions'

const VideoPageItem = ({ video }) => {
  const dispatch = useDispatch()

  return (
    <div className='item'>
      {!video.isHide && (
        <Link to="#!" onClick={() => dispatch(selectVideo(video))}>
          <img
            src={video.image_url}
            alt="pic"
            style={{ borderRadius: '10px', float: 'left', marginRight: '20px', width: '50%' }}
          />

          <h6 className="header" style={{ marginBottom: '10px' }}>{video.title}</h6>
          <p>{video.description}</p>
        </Link>
      )}
      <div style={{ clear: 'both' }}></div>
    </div>
  )
}

export default VideoPageItem