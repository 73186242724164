import React from 'react'


const VideoPageDetails = ({ videos, video }) => {

  return (
    <div className="eleven wide column">
      {videos.length ? (
        <>
          <div className="ui embed" style={{ borderRadius: '15px' }}>
            <iframe
              src={video ? video.video_url + '?controls=1&amp;showinfo=0&amp;rel=1&amp;' : videos[0].video_url}
              title={video && video.title} frameborder="0" allow="accelerometer; allowfullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            >
            </iframe>
          </div>
          <div className="ui segment" style={{ marginBottom: '40px' }}>
            <h4 className="ui header">{video ? video.title : videos && videos[0].title}</h4>
            <p>{video ? video.description : videos && videos[0].description}</p>
          </div>
        </>
      ) : ''}
    </div>
  )
}

export default VideoPageDetails