import {
  SET_ALERT,
  REMOVE_ALERT,
} from '../constants/alertConstants'


export const alertReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        loading: false,
        alert: action.payload
      }
    case REMOVE_ALERT:
      return {}
    default:
      return state;
  }
}