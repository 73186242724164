import {
  EXPENSE_CREATE_SUCCESS,
  EXPENSE_CREATE_FAIL,
  EXPENSE_DETAILS_REQUEST,
  EXPENSE_DETAILS_SUCCESS,
  EXPENSE_DETAILS_FAIL,
  EXPENSE_DETAILS_RESET,
  EXPENSE_LIST_REQUEST,
  EXPENSE_LIST_SUCCESS,
  EXPENSE_LIST_FAIL,
  EXPENSE_UPDATE_SUCCESS,
  EXPENSE_UPDATE_FAIL,
  EXPENSE_DELETE_SUCCESS,
  EXPENSE_DELETE_FAIL,
  EXPENSE_LIST_RESET,
  EXPENSE_MARK_SUCCESS,
  EXPENSE_MARK_FAIL,
} from '../constants/expenseConstants'


export const expenseListReducer = (state = { expenses: [] }, action) => {
  switch (action.type) {
    case EXPENSE_LIST_REQUEST:
      return {
        loading: true
      }
    case EXPENSE_LIST_SUCCESS:
      return {
        loading: false,
        month: action.payload.month,
        year: action.payload.year,
        page: action.payload.page,
        pages: action.payload.pages,
        expenses: action.payload.expenses // paginate effect
      }
    case EXPENSE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case EXPENSE_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        expenses: [action.payload, ...state.expenses]
      }
    case EXPENSE_UPDATE_SUCCESS:
      return {
        loading: false,
        expenses: state.expenses.map(expense => expense._id === action.payload._id ? action.payload : expense)
      }
    case EXPENSE_DELETE_SUCCESS:
      return {
        loading: false,
        expenses: state.expenses.filter(expense => expense._id !== action.payload)
      }
    case EXPENSE_MARK_SUCCESS:
      return {
        loading: false,
        expenses: state.expenses.map(item => item._id === action.payload._id ? action.payload : item)
      }
    case EXPENSE_LIST_RESET:
      return {}
    default:
      return state;
  }
}

export const expenseDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPENSE_DETAILS_REQUEST:
      return {
        loading: true
      }
    case EXPENSE_DETAILS_SUCCESS:
      return {
        loading: false,
        expense: action.payload
      }
    case EXPENSE_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case EXPENSE_DETAILS_RESET:
      return {}
    default:
      return state;
  }
}

export const expenseUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPENSE_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}

export const expenseCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPENSE_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}

export const expenseDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPENSE_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}

export const expenseMarkReducer = (state = {}, action) => {
  switch (action.payload) {
    case EXPENSE_MARK_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}