import {
  DOCUMENT_UPLOAD_REQUEST,
  DOCUMENT_UPLOAD_SUCCESS,
  DOCUMENT_UPLOAD_FAIL,
  DOCUMENT_LIST_REQUEST,
  DOCUMENT_LIST_SUCCESS,
  DOCUMENT_LIST_FAIL,
  DOCUMENT_DETAILS_REQUEST,
  DOCUMENT_DETAILS_SUCCESS,
  DOCUMENT_DETAILS_FAIL,
  DOCUMENT_DOWNLOAD_REQUEST,
  DOCUMENT_DOWNLOAD_SUCCESS,
  DOCUMENT_DOWNLOAD_FAIL
} from '../constants/documentConstants'



export const documentUploadReducer = (state = { docs: [] }, action) => {
  switch (action.type) {
    case DOCUMENT_UPLOAD_REQUEST:
      return {
        loading: true
      }
    case DOCUMENT_UPLOAD_SUCCESS:
      return {
        loading: false,
        success: true,
        docs: action.payload
      }
    case DOCUMENT_UPLOAD_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}

export const documentListReducer = (state = { docs: [] }, action) => {
  switch (action.type) {
    case DOCUMENT_LIST_REQUEST:
      return {
        loading: true
      }
    case DOCUMENT_LIST_SUCCESS:
      return {
        loading: false,
        docs: action.payload
      }
    case DOCUMENT_LIST_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}

export const documentDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case DOCUMENT_DETAILS_REQUEST:
      return {
        loading: true
      }
    case DOCUMENT_DETAILS_SUCCESS:
      return {
        loading: false,
        doc: action.payload
      }
    case DOCUMENT_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}

export const documentDownloadReducer = (state = {}, action) => {
  switch (action.type) {
    case DOCUMENT_DOWNLOAD_REQUEST:
      return {
        loading: true
      }
    case DOCUMENT_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        success: true,
        doc: action.payload
      }
    case DOCUMENT_DOWNLOAD_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}