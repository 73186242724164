import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listVideos } from '../../actions/videoActions'
import VideoForm from '../../components/videos/VideoForm'
import VideoItem from '../../components/videos/VideoItem'



const VideoListPage = () => {
  const dispatch = useDispatch()

  const videoList = useSelector(state => state.videoList)
  const { videos } = videoList;

  useEffect(() => {
    dispatch(listVideos());
  }, [dispatch])

  return (
    <div className='row'>
      <div className="col-md-8">
        <h3 className='mb-4'>List Of Videos</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              {/* <th scope="col">#</th> */}
              <th scope="col">Date</th>
              <th scope="col">Image Url</th>
              <th scope="col">Video Url</th>
            </tr>
          </thead>
          <tbody>
            {videos && videos.map(video => (
              <VideoItem video={video} key={video._id} />
            ))}
          </tbody>
        </table>

      </div>
      <div className="col-md-4">
        <h3 className='text-center mb-4'>Video Form</h3>
        <VideoForm />
      </div>
    </div>
  )
}

export default VideoListPage