import React, { useEffect, useState } from 'react'
import { Link, Route, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../components/Spinner'
import Alert from '../components/Alert'
import PostItem from '../components/posts/PostItem'
import { listPosts } from '../actions/postActions'
import Paginate from '../components/Paginate'
import SearchBox from '../components/SearchBox'
import { POST_DETAILS_RESET } from '../constants/postConstants' // when clicking add btn
import { getBuildingDetails, deniedBannedUser } from '../actions/buildingActions'
import { isOperator } from '../actions/authActions'
import { listRecentComments } from '../actions/commentActions'
import RecentCommentButton from '../components/buttons/RecentCommentButton'
import { logout } from '../actions/userActions'
import ServiceItem from '../components/services/ServiceItem'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
// import MapBox from '../components/map/MapBox'
// import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax




const PostPage = ({ history, match }) => {
  // let mapContainer = useRef('');
  // const [lng, setLng] = useState('');
  // const [lat, setLat] = useState('');
  // const [zoom, setZoom] = useState(10);

  const [location, setLocation] = useState({
    name: '',
    address: '',
    district: '',
    province: '',
    zip: '',
    email: '',
    telephone1: '',
    telephone2: '',
    mobilePhone1: '',
    mobilePhone2: '',
    dayFrom: '',
    dayEnd: '',
    openTime: '',
    closeTime: '',
    note: ''
  });

  const {
    name,
    address,
    district,
    province,
    zip,
    email,
    telephone1,
    telephone2,
    mobilePhone1,
    mobilePhone2,
    dayFrom,
    dayEnd,
    openTime,
    closeTime,
    note
  } = location;


  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo, loading } = userLogin;

  const postList = useSelector(state => state.postList);
  const { posts, loading: loadingPostList, error: errorPostList, page, pages } = postList;

  const buildingDetails = useSelector(state => state.buildingDetails);
  const { building, loading: loadingBuildingDetails, error: errorBuildingDetails } = buildingDetails;

  const commentRecentList = useSelector(state => state.commentRecentList);
  const { comments: recentComments } = commentRecentList;

  // as props to to MapBox.js to set marker
  // const buildingLocation = useSelector(state => state.buildingLocation);
  // const { buildings } = buildingLocation;

  const userSwitchBuilding = useSelector(state => state.userSwitchBuilding);
  const { loading: loadingSwitch } = userSwitchBuilding;

  const pageNumber = match.params.pageNumber;
  const keyword = match.params.keyword;

  const params = useParams()
  const loc = useLocation()
  const haveSearchString = loc.pathname.split('/')[3] === undefined

  useEffect(() => {
    // console.log(dispatch(isOperator(building, userInfo)))
    dispatch(deniedBannedUser(building, userInfo, history));

    if (errorPostList === 'Token is no longer valid' || errorBuildingDetails === 'Token is no longer valid') {
      dispatch(logout())
    }

    if (userInfo.community === 'no-group') {
      history.push('/new-community');
    }

    dispatch(listRecentComments());

    if (!building || building.group !== userInfo.community) {
      // no building id means find by user community's name
      dispatch(getBuildingDetails());
    }


    if (building && building.position && building.contact) {
      // const longitude = building.location.coordinates[0];
      // const latitude = building.location.coordinates[1];
      // dispatch(findMyLocation(longitude, latitude));

      // list the posts
      dispatch(listPosts(pageNumber, keyword));

      setLocation({
        name: loading || !building.position.name ? '' : building.position.name,
        address: loading || !building.position.address ? '' : building.position.address,
        district: loading || !building.position.district ? '' : building.position.district,
        province: loading || !building.position.province ? '' : building.position.province,
        zip: loading || !building.position.zip ? '' : building.position.zip,
        email: loading || !building.contact.email ? '' : building.contact.email,
        telephone1: loading || !building.contact.telephone1 ? '' : building.contact.telephone1,
        telephone2: loading || !building.contact.telephone2 ? '' : building.contact.telephone2,
        mobilePhone1: loading || !building.contact.mobilePhone1 ? '' : building.contact.mobilePhone1,
        mobilePhone2: loading || !building.contact.mobilePhone2 ? '' : building.contact.mobilePhone2,
        dayFrom: loading || !building.contact.dayFrom ? '' : building.contact.dayFrom,
        dayEnd: loading || !building.contact.dayEnd ? '' : building.contact.dayEnd,
        openTime: loading || !building.contact.openTime ? '' : building.contact.openTime,
        closeTime: loading || !building.contact.closeTime ? '' : building.contact.closeTime,
        note: loading || !building.contact.note ? '' : building.contact.note
      })
    }


  }, [
    dispatch,
    history,
    loading,
    userInfo,
    building,
    pageNumber,
    keyword,
    loadingSwitch,
    errorPostList,
    errorBuildingDetails
  ])


  return (
    loading || loadingPostList || loadingSwitch || loadingBuildingDetails || building === null ? <Spinner /> : (
      <>
        <div className="row content">
          <div className="col-md-3 mb-5 bg-light" style={{ minHeight: "100vh" }}>
            <div className="text-center my-5">
              <img src={`https://res.cloudinary.com/imgtocloud/image/upload/v1649685058/Baanbalina3%27s%20Village/baan-balina-pool2_actn9k.jpg`} alt="sample building" style={{ width: '95%' }} />
              {/* <img src={`https://res.cloudinary.com/imgtocloud/image/upload/w_350/v1649688222/Baanbalina3%27s%20Village/baan-balina-pool2_rga8x0.png`} alt="sample building" /> */}
              {/* <img src={`https://www.freepnglogos.com/uploads/building-png/building-png-office-timpuri-noi-square-17.png`} alt="sample building" style={{ width: '200px', height: '250px' }} /> */}
            </div>

            <div>
              <address className="p-4">
                <h5>
                  <div>{isOperator(building, userInfo)}</div>
                  {name}
                </h5>
                <span>
                  {/* Street */}
                  {address}<br />
                  {/* District */}
                  {district} <br />
                  {/* Province / Zip code */}
                  {province} - {zip || '0000'}<br />
                  {/* Telephone */}
                  <p className="mt-3">
                    {telephone1 && (
                      <>
                        <i className="fas fa-phone-alt"></i>{' '}&nbsp;
                        <a href="tel:+13115552368">{telephone1}</a><br />
                      </>
                    )}
                    {telephone2 && (
                      <>
                        <i className="fas fa-phone-alt"></i>{' '}&nbsp;
                        <a href="tel:+13115552368">{telephone2}</a><br />
                      </>
                    )}
                    {mobilePhone1 && (
                      <>
                        <i className="fas fa-mobile-alt"></i>{' '}&nbsp;
                        <a href="tel:+13115552368">{mobilePhone1}</a><br />
                      </>
                    )}
                    {mobilePhone2 && (
                      <>
                        <i className="fas fa-mobile-alt"></i>{' '}&nbsp;
                        <a href="tel:+13115552368">{mobilePhone2}</a><br />
                      </>
                    )}

                  </p>
                  {/* Email */}
                  {email && (
                    <>
                      <i className="fas fa-envelope-open"></i>{' '}&nbsp;
                      <a href="mailto:jim@rock.com">{email}</a><br />
                    </>
                  )}
                </span>
                {/* Open - Close Time */}
                <p className="mt-4">
                  <span className="font-weight-bold">Open hours:</span><br />
                  <span>{dayFrom} - {dayEnd}</span><br />
                  <span>
                    {`${Number(openTime) < 10 ? '0' : ''}` + openTime} AM -
                    {`${Number(closeTime) < 10 ? '0' : ''}` + closeTime} PM
                  </span><br />
                </p>
                <br />
                {/* <span className="font-weight-bold">Security:</span>
                <table className='table'>
                  <tr>
                    <th>รปภ.</th>
                    <th><i className="fas fa-phone-alt"></i></th>
                  </tr>
                  <tr>
                    <td>สาบือรี สุหลง / aburee Sulong</td>
                    <td>0981952432</td>
                  </tr>
                  <tr>
                    <td>อรชุนท์ นภาเพชร / Orachun Naphaphet</td>
                    <td>0952165298</td>
                  </tr>
                  <tr>
                    <td>ปานะ เมฆขลา / Pana</td>
                    <td>0832148110</td>
                  </tr>
                  <tr>
                    <td>พยุงศักดิ้ วงศ้คำจันทร้ / Pyungsak Wongkhamchan</td>
                    <td>0863950112</td>
                  </tr>
                </table> */}
              </address>
            </div>

            {note && (
              <p className="bg-warning text-danger p-3">{`*${note}`}</p>
            )}

            {/* <MapBox
              mapboxgl={mapboxgl}
              // lat={lat}
              // lng={lng}
              mapContainer={mapContainer}
              zoom={zoom}
              buildings={buildings}
            /> */}

            <div className='mb-5 mt-5'>
              {!loadingBuildingDetails && building !== undefined && building.position.services.length > 0 && (
                <>
                  <h3>Call 24 Hours : </h3>
                  <table className="table mt-4">
                    <tbody>
                      {building.position.services.map(item => (
                        <ServiceItem item={item} key={item._id} />
                      ))}
                      {/* <tr>
                    <th scope="row"><i className="fa-solid fa-handcuffs text-primary"></i> Police Station</th>
                    <td>
                      <i className="fa-solid fa-square-phone-flip text-success"></i> 038-938399 <br />
                      <i className="fa-solid fa-square-phone-flip text-success"></i> 038-110333 <br />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><i className="fa-solid fa-droplet text-secondary"></i> Water (PWA)</th>
                    <td>
                      <i className="fa-solid fa-square-phone-flip text-success"></i> 1662 <br />
                      <i className="fa-brands fa-line text-success"></i> @pwapattaya
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><i className="fa-solid fa-kit-medical text-info"></i> Rescue</th>
                    <td><i className="fa-solid fa-square-phone-flip text-success"></i> 038-222474</td>
                  </tr>
                  <tr>
                    <th scope="row"><i className="fa-solid fa-bolt text-warning"></i> Electricity</th>
                    <td><i className="fa-solid fa-square-phone-flip text-success"></i> 038-907896</td>
                  </tr> */}
                    </tbody>
                  </table>
                </>
              )}
            </div>
            <div>
              <img src="https://res.cloudinary.com/imgtocloud/image/upload/v1660274756/Baanbalina3%27s%20Village/guard2_hvypjr.jpg" alt="fire bridge" style={{ width: '100%', marginBottom: '40px' }} />
            </div>

            <div>
              <img src="https://res.cloudinary.com/imgtocloud/image/upload/v1660274597/Baanbalina3%27s%20Village/guard1_njid32.jpg" alt="fire bridge" style={{ width: '100%', marginBottom: '40px' }} />
            </div>

            <div>
              <img src="https://res.cloudinary.com/imgtocloud/image/upload/v1650531826/Baanbalina3%27s%20Village/buddha-mountain-min_i798kc.jpg" alt="fire bridge" style={{ width: '100%', marginBottom: '40px' }} />
            </div>

          </div>


          {/* 2) */}
          <div className="col-md-9">
            <div className="float-right">

              <div style={{ margin: '20px 0' }}>
                <>
                  {/* SHOW ALL MATTERS BTN */}
                  <Link
                    to={haveSearchString ? '/posts/search/control' : '/posts'} type="button"
                    className="btn btn-info text-white py-2 mx-3"
                  >
                    {haveSearchString ? 'Show Problems' : 'All Posts'}
                  </Link>
                </>
                <>
                  {/* ADD POST BTN */}
                  {/* {isOperator(building, userInfo) && ( */}
                  {dispatch(isOperator(building, userInfo)) && (
                    <Link
                      to="/posts/new" type="button"
                      className="btn btn-success text-white py-2"
                      onClick={() => dispatch({ type: POST_DETAILS_RESET })}  // reset details for new post form
                    >
                      <i className="fas fa-plus mr-2"></i>Post
                    </Link>
                  )}
                </>
              </div>
              {/* // )} */}
            </div>
            {/* <div className="float-right mx-3 postCommentBtn" style={{ margin: '20px  0' }}> */}
            {/* Recent comments button */}
            {/* <RecentCommentButton recentComments={recentComments} /> */}
            {/* </div> */}



            {/* ADD INFO BTN */}
            {/* <button className="btn btn-info mx-3" data-toggle="modal" data-target="#infoForm"><i
              className="fas fa-plus mr-2" ></i>Info</button> */}

            <div className="filterBoxPost">
              {/* Search box */}
              <Route render={({ history }) => <SearchBox history={history} />} />
            </div>

            <div style={{ clear: 'both' }}></div>

            {/* BANN ERROR ALERT */}
            {errorPostList && <Alert msg={errorPostList} />}

            <div style={{ margin: '0 20px' }}>
              <div className="mt-4">
                {posts && posts.length > 0 ? posts.map((post, index) => (
                  <PostItem key={post._id} post={post} index={index} />
                )) : (
                  <p className="lead text-center text-muted">-----There are still no posts.-----</p>
                )}
              </div>
            </div>

            <Paginate page={page} pages={pages} />
          </div>
        </div>
      </>
    )
  )
  // )
}

export default PostPage
