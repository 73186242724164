import axios from 'axios';
import {
  EXPENSE_CREATE_SUCCESS,
  EXPENSE_CREATE_FAIL,
  EXPENSE_DETAILS_REQUEST,
  EXPENSE_DETAILS_SUCCESS,
  EXPENSE_DETAILS_FAIL,
  EXPENSE_DETAILS_RESET,
  EXPENSE_LIST_REQUEST,
  EXPENSE_LIST_SUCCESS,
  EXPENSE_LIST_FAIL,
  EXPENSE_UPDATE_SUCCESS,
  EXPENSE_UPDATE_FAIL,
  EXPENSE_DELETE_SUCCESS,
  EXPENSE_DELETE_FAIL,
  EXPENSE_MARK_SUCCESS,
  EXPENSE_MARK_FAIL
} from '../constants/expenseConstants'


export const listExpenses = (month, year, pageNumber) => async (dispatch, getState) => {
  dispatch({ type: EXPENSE_LIST_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`/api/expenses?month=${month}&year=${year}&pageNumber=${pageNumber}`, config);

    dispatch({
      type: EXPENSE_LIST_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: EXPENSE_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const clearExpenseDetails = () => async (dispatch, getState) => {
  dispatch({ type: EXPENSE_DETAILS_RESET });
}

export const getExpenseDetails = id => async (dispatch, getState) => {
  dispatch({ type: EXPENSE_DETAILS_REQUEST });

  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`/api/expenses/${id}`, config);

    dispatch({
      type: EXPENSE_DETAILS_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: EXPENSE_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const updateExpense = (buildingId, id, formData) => async (dispatch, getState) => {
  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/expenses/${id}/building/${buildingId}`, formData, config);

    dispatch({
      type: EXPENSE_UPDATE_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: EXPENSE_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const createExpense = (buildingId, formData) => async (dispatch, getState) => {
  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.post(`/api/expenses/${buildingId}`, formData, config);

    dispatch({
      type: EXPENSE_CREATE_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: EXPENSE_CREATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const deleteExpense = (buildingId, id) => async (dispatch, getState) => {
  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    await axios.delete(`/api/expenses/${id}/building/${buildingId}/`, config);

    dispatch({
      type: EXPENSE_DELETE_SUCCESS,
      payload: id
    })
  } catch (error) {
    dispatch({
      type: EXPENSE_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const markExpense = (id, buildingId) => async (dispatch, getState) => {
  const { userInfo } = getState().userLogin;

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(`/api/expenses/${id}/building/${buildingId}/mark`, {}, config)

    dispatch({
      type: EXPENSE_MARK_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: EXPENSE_MARK_FAIL,
      error: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}


