import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { responseEmail } from '../../actions/adminAction'
import { ADMIN_EMAIL_DETAILS_RESET, ADMIN_EMAIL_RESPONSE_RESET } from '../../constants/adminConstants';

const EmailForm = ({ email }) => {
  const [text, setText] = useState('');

  const dispatch = useDispatch();


  useEffect(() => {
    if (email) {
      setText(email.text)
    }
  }, [email])

  const submitHandler = e => {
    e.preventDefault();
    dispatch(responseEmail(email._id, email.ticket, { text }));
    setText('');
    dispatch({ type: ADMIN_EMAIL_RESPONSE_RESET });
  }

  const clearAllHandler = () => {
    setText('');
    dispatch({ type: ADMIN_EMAIL_DETAILS_RESET });
  }

  return (
    <form onSubmit={submitHandler} className="form-group mb-5">
      <textarea
        name="text"
        cols="30"
        rows="10"
        className="form-control form-control-lg"
        value={text}
        onChange={e => setText(e.target.value)}
      ></textarea>
      <input type="submit" value="Send" className="btn btn-primary btn-block mt-3" />
      <input type="button" value="Clear all" className="btn btn-block btn-secondary" onClick={clearAllHandler} />
    </form>
  )
}

export default EmailForm
